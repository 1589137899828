import { jsx as _jsx } from "react/jsx-runtime";
import { Typography } from '@profesia/adamui/components/common/typography';
import { getConfiguration } from '@profesia/adamui/configuration';
const Translated = ({ children, typographyProps = {}, ...otherProps }) => {
    const configuration = getConfiguration();
    const Translate = configuration.i18n.translationComponent;
    const trans = (_jsx(Translate, { components: otherProps.components, ...otherProps, children: children }));
    return _jsx(Typography, { ...typographyProps, children: trans });
};
export default Translated;
