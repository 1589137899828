import { jsx as _jsx } from "react/jsx-runtime";
import { PositionPlaceTypeId } from '@profesia/mark-offer-form/components/content/form';
import { useGetFixedT } from '@profesia/mark-offer-form/i18n';
import GooglePlaceAutocomplete from '@profesia/mark-offer-form/components/content/PositionPlace/GooglePlaceAutocomplete';
import RegionPlaceAutocomplete from '@profesia/mark-offer-form/components/content/PositionPlace/RegionPlaceAutocomplete';
import { useAutocompleteService } from '@profesia/mark-offer-form/services/googleAutocompleteService/useAutocompleteService';
import { useCodebooksContext } from '@profesia/mark-offer-form/services/CodebooksContext';
const useGetOptions = () => {
    const t = useGetFixedT('positionPlace');
    const autocompleteService = useAutocompleteService();
    const { data } = useCodebooksContext();
    return [
        {
            type: PositionPlaceTypeId.Onsite,
            item: t('office.label'),
            description: t('office.description'),
            children: (_jsx(GooglePlaceAutocomplete, { autocompleteService: autocompleteService, placeholder: t('office.placeholder') })),
        },
        {
            type: PositionPlaceTypeId.Hybrid,
            item: t('hybrid.label'),
            description: t('hybrid.description'),
            children: (_jsx(GooglePlaceAutocomplete, { autocompleteService: autocompleteService, placeholder: t('hybrid.placeholder') })),
        },
        {
            type: PositionPlaceTypeId.Remote,
            item: t('ho.label'),
            description: t('ho.description'),
        },
        {
            type: PositionPlaceTypeId.Travel,
            item: t('travel.label'),
            description: t('travel.description'),
            children: (_jsx(RegionPlaceAutocomplete, { tPrefix: "positionPlace.travel", options: data?.regions ?? [] })),
        },
    ];
};
export default useGetOptions;
