import { jsx as _jsx } from "react/jsx-runtime";
import { useFormContext, useWatch } from 'react-hook-form';
import { Box } from '@mui/material';
import { NumberField } from '@profesia/adamui/components/common/input';
import Label from '@profesia/adamui/components/common/Label';
import { useGetFixedT } from '@profesia/mark-offer-form/i18n';
import { FORM_SALARY_VALUE } from '@profesia/mark-offer-form/components/content/form/valuePaths';
import { getInputError } from '@profesia/mark-offer-form/components/content/form/errorGetters';
const Fixed = () => {
    const { setValue, control, formState: { errors }, } = useFormContext();
    const t = useGetFixedT('salaryType.fixed');
    const value = useWatch({ name: FORM_SALARY_VALUE, control });
    const error = errors.salary?.value;
    return (_jsx(Box, { maxWidth: 200, flexGrow: 1, children: _jsx(NumberField, { value: value ?? undefined, onChange: (newValue) => {
                setValue(FORM_SALARY_VALUE, newValue);
            }, label: _jsx(Label, { error: !!error, required: true, children: t('basicGrossPay') }), placeholder: t('placeholder'), disableZeroValue: true, ...getInputError(error) }) }));
};
export default Fixed;
