import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Box, Grid, Stack } from '@mui/material';
import CardFooterContainer from '@profesia/adamui/components/common/card/CardFooter/CardFooterContainer';
import { TypographySpan } from '@profesia/adamui/components/common/typography';
import { generateKey } from '@profesia/adamui/utils/input';
import HorizontalDivider from '@profesia/adamui/components/common/HorizontalDivider';
import { useMobile, useFlexDirectionByBreakpoint } from '@profesia/adamui/utils/responsive';
import RevealedAt from './RevealedAt';
const mappedColors = {
    icon: {
        unavailableCandidate: 'secondaryTiger',
        availableCandidate: 'neutralDark3',
    },
    text: {
        unavailableCandidate: 'secondaryTiger',
        availableCandidate: 'neutralDark2',
    },
};
const Candidate = ({ id, content, actions, variant, revealedAt }) => {
    const typography = (content) => (_jsx(TypographySpan, { color: mappedColors.text[variant], children: content }));
    const icon = (Icon) => _jsx(Icon, { color: mappedColors.icon[variant] });
    const isMobile = useMobile();
    const direction = useFlexDirectionByBreakpoint();
    const gridItemCommonProps = {
        item: true,
        display: 'flex',
        direction: direction,
        gap: direction === 'row' ? 1 : 0.5,
    };
    return (_jsxs(Stack, { children: [_jsx(HorizontalDivider, { mt: 0, mb: 0 }), _jsx(CardFooterContainer, { id: id, content: _jsxs(Grid, { container: true, display: "flex", justifyContent: "space-between", direction: direction, my: 1.25, gap: 1.5, children: [_jsx(Grid, { ...gridItemCommonProps, children: content.map(({ Icon, label }, index) => (_jsxs(React.Fragment, { children: [_jsxs(Box, { display: "flex", gap: 0.5, children: [icon(Icon), typography(label)] }), !isMobile && index !== content.length - 1
                                        ? typography('|')
                                        : null] }, generateKey(index, id)))) }), _jsxs(Grid, { ...gridItemCommonProps, children: [revealedAt ? (_jsxs(_Fragment, { children: [_jsx(RevealedAt, { revealedAt: revealedAt }), !isMobile ? (_jsx(TypographySpan, { color: "black4", children: `|` })) : null] })) : null, _jsx(TypographySpan, { color: "black4", children: `ID ${id}` })] })] }), actions: actions })] }));
};
export default Candidate;
