import { jsx as _jsx } from "react/jsx-runtime";
import { TypographySpan } from '@profesia/adamui/components/common/typography';
import { styled } from '@mui/material';
const scaleInitialsFontSize = (noOfInitials, fontSize) => Math.max(fontSize * (1 - 0.1 * noOfInitials), 0.25);
const InitialsTypography = styled(TypographySpan, {
    shouldForwardProp: (prop) => prop !== 'size',
})(({ fontSize }) => ({
    height: 'auto',
    width: 'auto',
    margin: 'auto',
    fontSize: `${fontSize}rem`,
    lineHeight: 0.75,
}));
const Initials = ({ name, fontSize }) => {
    const initials = name
        .split(' ')
        .map((n) => n[0])
        .join('');
    const finalFontSize = initials.length > 2 ? scaleInitialsFontSize(initials.length, fontSize) : fontSize;
    return (_jsx(InitialsTypography, { fontSize: finalFontSize, color: "secondaryRed", children: initials }));
};
export default Initials;
