import { useMutation, useQuery } from '@tanstack/react-query';
import { getConfiguration } from '@profesia/mark-offer-form/configuration';
import { useGetLocale } from '@profesia/mark-offer-form/i18n';
import { GENERATE_TEXT_WITH_AI, GET_JOB_TITLE_AI_SUGGESTIONS } from '../keys';
const ACCEPTED_LANGUAGES = ['sk', 'en'];
export const useGetJobTitleAiSuggestions = (input, enabled) => {
    const { api } = getConfiguration();
    const language = useGetLocale();
    return useQuery({
        queryKey: [GET_JOB_TITLE_AI_SUGGESTIONS, input, language],
        queryFn: async () => api.getJobTitleAiSuggestions(input, language),
        enabled,
    });
};
export const useGenerateTextWithAi = (field, onSuccess) => {
    const { api } = getConfiguration();
    return useMutation({
        mutationKey: [GENERATE_TEXT_WITH_AI, field],
        mutationFn: async ({ jobTitle, userInput, tone, language }) => await api.generateTextWithAi(field, jobTitle, userInput, tone, language),
        onSuccess,
    });
};
export const isAiActiveForLanguage = (lang) => {
    return ACCEPTED_LANGUAGES.includes(lang);
};
