import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Box, Grid, styled, Divider } from '@mui/material';
import { getColor } from '@profesia/adamui/styles/colors';
import { tabletDown } from '@profesia/adamui/styles/theme';
import { Typography } from '@profesia/adamui/components/common/typography';
import Autocomplete from '@profesia/adamui/components/common/input/autocomplete/Autocomplete';
import { useGetFixedT } from '@profesia/mark-offer-form/i18n';
import { FORM_CONTACT_PERSON_FULL_NAME, FORM_CONTACT_PERSON_ID, } from '@profesia/mark-offer-form/components/content/form/valuePaths';
import transformContactPersons from '../../../../services/contactPersons/transformContactPersons';
const getContactPerson = (contactPersons, contactPersonId) => {
    return contactPersons?.find((contactPerson) => contactPerson.id === contactPersonId);
};
const Card = ({ contactPersons }) => {
    const t = useGetFixedT('contactDetails');
    const { getValues, setValue, control } = useFormContext();
    const contactPersonId = getValues(FORM_CONTACT_PERSON_ID);
    const transformedContactPersons = transformContactPersons(contactPersons);
    const value = useWatch({
        name: FORM_CONTACT_PERSON_FULL_NAME,
        control,
        defaultValue: getContactPerson(transformedContactPersons, contactPersonId),
    });
    const [contactPerson, setContactPerson] = useState(getContactPerson(contactPersons, contactPersonId));
    const handleSelectChange = (value) => {
        setValue(FORM_CONTACT_PERSON_FULL_NAME, value);
        setContactPerson(getContactPerson(contactPersons, value?.id));
    };
    return (_jsxs(Box, { "data-testid": "contact-details-content", children: [_jsx(SelectBox, { "data-testid": "select-box", children: _jsx(Autocomplete, { label: t('contactPerson.label'), placeholder: "", options: transformedContactPersons ?? [], value: value, onChange: (event, value) => handleSelectChange(value) }) }), _jsx(StyledDivider, { "data-testid": "divider" }), _jsxs(Grid, { container: true, rowSpacing: 1, children: [_jsx(Grid, { item: true, xs: 12, sm: 2, children: _jsx(Typography, { color: "primaryNavy", children: t('email.label') }) }), _jsx(Grid, { item: true, xs: 12, sm: 10, children: _jsx(Typography, { color: "primaryNavy", fontWeight: 700, children: contactPerson?.email }) }), _jsx(Grid, { item: true, xs: 12, sm: 2, mt: { xs: 2, sm: 0 }, children: _jsx(Typography, { color: "primaryNavy", children: t('phone.label') }) }), _jsx(Grid, { item: true, xs: 12, sm: 10, children: _jsx(Typography, { color: "primaryNavy", fontWeight: 700, children: contactPerson?.phone }) })] })] }));
};
const SelectBox = styled(Box)(({ theme }) => ({
    width: '440px',
    marginBottom: theme.spacing(3),
    [tabletDown]: {
        width: '100%',
    },
}));
const StyledDivider = styled(Divider)(({ theme: { spacing, palette } }) => ({
    maxWidth: '500px',
    marginTop: spacing(2.75),
    marginBottom: spacing(2.25),
    borderColor: getColor(palette.neutralLight4),
}));
export default Card;
