import { jsx as _jsx } from "react/jsx-runtime";
import { useGetFixedT } from '@profesia/mark-offer-form/i18n';
export var Requirements;
(function (Requirements) {
    Requirements["All"] = "all";
    Requirements["AtLeastOne"] = "atLeastOne";
})(Requirements || (Requirements = {}));
const requiredLanguageIds = Object.values(Requirements);
export const useGetRequiredOptions = () => {
    const t = useGetFixedT('common.codebooks.languagesRequired');
    return requiredLanguageIds.map((id) => ({
        id,
        label: _jsx("span", { dangerouslySetInnerHTML: { __html: t(id) } }),
    }));
};
