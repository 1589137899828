import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { IndicatorDisabledIcon, IndicatorEnabledIcon, } from '@profesia/adamui/components/common/icons';
import { TypographySpan } from '@profesia/adamui/components/common/typography';
import { FilterOrJobVariant, } from '@profesia/adamui/components/common/card/CardFooter/types';
import CardFooterContainer from '@profesia/adamui/components/common/card/CardFooter/CardFooterContainer';
const activeVariants = [FilterOrJobVariant.openJob, FilterOrJobVariant.activeFilter];
const FilterOrJob = ({ id, content, actions, variant }) => {
    const active = activeVariants.includes(variant);
    return (_jsx(CardFooterContainer, { id: id, content: _jsxs(Box, { display: "flex", gap: 1.125, children: [active ? _jsx(IndicatorEnabledIcon, {}) : _jsx(IndicatorDisabledIcon, {}), _jsx(TypographySpan, { children: content })] }), actions: actions }));
};
export default FilterOrJob;
