import Url from './Url';

const handleErrorResponse = (response: Response): void => {
    if (response.status === 403 || response.status === 401) {
        Url.redirectTo(Url.buildLoginLink());

        throw new Error('Unauthorized');
    }

    if (!response.ok) {
        throw new Error('Server error: ' + response.status);
    }
};

export default handleErrorResponse;
