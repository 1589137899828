import { Paper, styled } from '@mui/material';
import { getColor } from '@profesia/adamui/styles/colors';
const CardContainer = styled(Paper)(({ theme: { palette } }) => ({
    display: 'flex',
    flexDirection: 'column',
    borderWidth: 1,
    borderColor: getColor(palette.black5),
    borderStyle: 'solid',
    borderRadius: 4,
    backgroundColor: getColor(palette.neutralLight1),
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.06)',
}));
export default CardContainer;
