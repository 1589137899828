import { useTranslationInPageLanguage } from '@profesia/mark-offer-form/i18n';
import getErrors, { validatedFields } from './getErrors';
const useGetResolver = () => {
    const t = useTranslationInPageLanguage('errors.form');
    return async (val) => {
        const errors = getErrors(val, t);
        return {
            values: val,
            errors,
        };
    };
};
export { validatedFields };
export default useGetResolver;
