import { alpha, styled } from '@mui/material';
import TooltipUI from '@profesia/adamui/components/common/Tooltip';
import { getColor } from '@profesia/adamui/styles/colors';
const Tooltip = styled(TooltipUI)(({ theme: { palette } }) => ({
    '.MuiTooltip-tooltip': {
        backgroundColor: alpha(getColor(palette.primaryNavy), 0.85),
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: getColor(palette.primaryGray),
    },
    '.MuiTypography-root': {
        color: getColor(palette.white),
    },
}));
export default Tooltip;
