import { AiDropdownTonesT } from '@profesia/adamui/components/common/input/textField/wysiwygEditor/Toolbar/AiDropdown';
import { Languages } from '@profesia/mark-offer-form/i18n';
import { Field } from '@profesia/mark-offer-form/configuration';
import apiRequest from './apiRequest';

type Response = {
    suggestion: string;
};

const generateTextWithAi = async (
    field: Field,
    jobTitle: string,
    userInput: string,
    tone: AiDropdownTonesT,
    language: Languages
): Promise<string> => {
    const jsonResponse = await apiRequest<Response>(
        `ai/generate/${field === 'jobTasks' ? 'job-tasks' : field}`,
        {
            method: 'POST',
            body: JSON.stringify({
                jobTitle,
                [field]: userInput ?? '',
                language,
                tone,
            }),
        }
    );

    return jsonResponse.suggestion;
};

export default generateTextWithAi;
