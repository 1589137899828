import { useGetFixedT } from '@profesia/mark-offer-form/i18n';
export var Currency;
(function (Currency) {
    Currency["Eur"] = "2";
    Currency["Czk"] = "4";
    Currency["Huf"] = "5";
})(Currency || (Currency = {}));
const currencies = [
    {
        label: 'eur',
        id: Currency.Eur,
    },
    {
        label: 'czk',
        id: Currency.Czk,
    },
    {
        label: 'huf',
        id: Currency.Huf,
    },
];
export const useGetCurrenciesOptions = () => {
    const t = useGetFixedT('codebooks.currencies');
    return currencies.map(({ label, id }) => ({ id, label: t(label) }));
};
export const useGetCurrencyLabelsById = () => {
    const options = useGetCurrenciesOptions();
    return new Map(options.map(({ label, id }) => [id, label]));
};
export const DEFAULT_CURRENCY = Currency.Eur;
export default currencies;
