import { jsx as _jsx } from "react/jsx-runtime";
import { Checkbox as MuiCheckbox } from '@mui/material';
import { CheckboxIndeterminateIcon, CheckboxSquareIcon, } from '@profesia/adamui/components/common/icons';
import { MUI_ERROR_CLASS } from '@profesia/adamui/styles/classes';
import { sanitizeTestId } from '@profesia/adamui/tests/testId';
import FormControlLabel from './FormControlLabel';
import useFocusedState from './useFocusedState';
const getHighlightedClasses = (hasError) => `MuiFormControlLabel-checkboxHighlighted ${hasError ? MUI_ERROR_CLASS : ''}`;
const Checkbox = ({ type = 'primary', label = '', disabled = false, variant = 'item', onChange, hasError = false, testId, ...otherProps }) => {
    let finalType = type;
    const [focusClassName, onFocus, onBlur] = useFocusedState();
    if (disabled) {
        finalType = 'disabled';
    }
    const Checkbox = (_jsx(MuiCheckbox, { disableRipple: true, name: label, icon: _jsx(CheckboxSquareIcon, { state: "unchecked", type: finalType }), checkedIcon: _jsx(CheckboxSquareIcon, { state: "checked", type: finalType }), disabled: disabled, indeterminateIcon: _jsx(CheckboxIndeterminateIcon, {}), onFocus: onFocus, onBlur: onBlur, onChange: (event, checked) => {
            onChange?.(event, checked);
            onBlur();
        }, ...otherProps }));
    return label ? (_jsx(FormControlLabel, { className: `${focusClassName} ${variant === 'highlighted' ? getHighlightedClasses(hasError) : ''}`, control: Checkbox, label: label, labelPlacement: "end", "data-testid": sanitizeTestId(testId, `checkbox-${type}${variant === 'highlighted' ? '-highlighted' : ''}`) })) : (Checkbox);
};
export default Checkbox;
