import { jsx as _jsx } from "react/jsx-runtime";
import { useFormContext, useWatch } from 'react-hook-form';
import { Box } from '@mui/material';
import HiddenFields from '@profesia/adamui/components/common/HiddenFields';
import { CheckboxGroup } from '@profesia/adamui/components/common/input';
import { FORM_SKILLS_DRIVING_LICENCE } from '@profesia/mark-offer-form/components/content/form/valuePaths';
import { trackEvent } from '@profesia/mark-offer-form/utils/track/track';
import { LOCATION_JOB_AD, NAME_DRIVING_LICENCE, } from '@profesia/mark-offer-form/utils/track/constants';
import { useGetFixedT } from '@profesia/mark-offer-form/i18n';
import { useGetDrivingLicenceOptions } from '@profesia/mark-offer-form/codebooks';
const DrivingLicense = () => {
    const t = useGetFixedT('skills.otherSkills');
    const { setValue, control } = useFormContext();
    const checkedValues = useWatch({
        name: FORM_SKILLS_DRIVING_LICENCE,
        control,
    });
    const drivingLicenceOptions = useGetDrivingLicenceOptions();
    return (_jsx(Box, { pt: 1, children: _jsx(HiddenFields, { buttonLabel: t('drivingCard'), shouldBeHidden: checkedValues.length === 0, children: _jsx(CheckboxGroup, { label: t('drivingCard'), variant: "highlighted", options: drivingLicenceOptions, checkedValues: checkedValues, onChange: (checkedValues) => {
                    setValue(FORM_SKILLS_DRIVING_LICENCE, checkedValues);
                } }), onClick: () => trackEvent(LOCATION_JOB_AD, NAME_DRIVING_LICENCE, null) }) }));
};
export default DrivingLicense;
