import { Box, styled } from '@mui/material';
import { getColor } from '@profesia/adamui/styles/colors';
import { tabletDown } from '@profesia/adamui/styles/theme';
export const NotificationBox = styled(Box)(({ theme: { palette, spacing } }) => ({
    '& .info-card-link': {
        color: getColor(palette.primaryNavy),
        textDecoration: 'underline',
        '&:visited': {
            color: getColor(palette.primaryNavy),
        },
    },
    [tabletDown]: {
        margin: spacing(0, 2, 3),
    },
}));
