import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import createSvgIcon from '@profesia/adamui/components/common/icons/createSvgIcon';
const warningTypes = {
    standard: [
        _jsxs(_Fragment, { children: [_jsx("path", { d: "M3.29001 17.5364L10.5023 3.90196C10.6464 3.62968 10.8619 3.40182 11.1258 3.2429C11.3897 3.08398 11.6919 3 12 3C12.308 3 12.6102 3.08398 12.8741 3.2429C13.138 3.40182 13.3536 3.62968 13.4976 3.90196L20.7099 17.5364C20.8453 17.7937 20.9117 18.0817 20.9027 18.3723C20.8937 18.6629 20.8096 18.9462 20.6585 19.1946C20.5074 19.443 20.2946 19.6481 20.0407 19.7898C19.7868 19.9315 19.5006 20.005 19.2098 20.0031H4.78687C4.49641 20.0044 4.21057 19.9305 3.95714 19.7886C3.70371 19.6466 3.49132 19.4415 3.34063 19.1932C3.18995 18.9449 3.10609 18.6618 3.09722 18.3715C3.08835 18.0812 3.15477 17.7935 3.29001 17.5364Z", fill: "white", stroke: "#F6973A", strokeWidth: "2", strokeMiterlimit: "10", strokeLinecap: "square" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12.9983 7.66942V14.5267H10.9983V7.66942H12.9983Z", fill: "#F6973A" }), _jsx("path", { d: "M11.9983 17.5745C12.6689 17.5745 13.2126 17.0308 13.2126 16.3602C13.2126 15.6895 12.6689 15.1458 11.9983 15.1458C11.3276 15.1458 10.7839 15.6895 10.7839 16.3602C10.7839 17.0308 11.3276 17.5745 11.9983 17.5745Z", fill: "#F6973A" })] }),
        24,
    ],
    small: [
        _jsxs(_Fragment, { children: [_jsx("path", { d: "M2.74164 14.6137L8.75188 3.25163C8.87194 3.02473 9.05158 2.83485 9.27148 2.70242C9.49138 2.56998 9.74323 2.5 9.99994 2.5C10.2566 2.5 10.5085 2.56998 10.7284 2.70242C10.9483 2.83485 11.1279 3.02473 11.248 3.25163L17.2582 14.6137C17.3711 14.8281 17.4264 15.0681 17.4189 15.3103C17.4114 15.5524 17.3413 15.7885 17.2154 15.9955C17.0895 16.2025 16.9121 16.3734 16.7005 16.4915C16.489 16.6095 16.2504 16.6708 16.0082 16.6693H3.98902C3.74697 16.6703 3.50876 16.6087 3.29757 16.4905C3.08638 16.3722 2.90939 16.2013 2.78382 15.9944C2.65825 15.7874 2.58837 15.5515 2.58098 15.3096C2.57358 15.0676 2.62893 14.8279 2.74164 14.6137Z", fill: "white", stroke: "#F6973A", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinecap: "square" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M10.8319 6.39117V12.1056H9.16528V6.39117H10.8319Z", fill: "#F6973A" }), _jsx("path", { d: "M9.99863 14.6454C10.5575 14.6454 11.0106 14.1923 11.0106 13.6335C11.0106 13.0746 10.5575 12.6215 9.99863 12.6215C9.43975 12.6215 8.98669 13.0746 8.98669 13.6335C8.98669 14.1923 9.43975 14.6454 9.99863 14.6454Z", fill: "#F6973A" })] }),
        20,
    ],
};
const SimpleWarning = ({ type = 'standard', ...otherProps }) => {
    const [path, size] = warningTypes[type];
    const Icon = createSvgIcon(path, 'SimpleWarning', {
        width: size,
        height: size,
        viewBox: `0 0 ${size} ${size}`,
        fill: 'none',
    });
    return _jsx(Icon, { ...otherProps });
};
export default SimpleWarning;
