import { useGetFixedT } from '@profesia/mark-offer-form/i18n';
export var Period;
(function (Period) {
    Period["Month"] = "1";
    Period["Hour"] = "2";
})(Period || (Period = {}));
const periods = [
    {
        label: 'month',
        id: Period.Month,
    },
    {
        label: 'hour',
        id: Period.Hour,
    },
];
const useMapTranslations = (type) => {
    const t = useGetFixedT(`codebooks.periods.${type}`);
    return periods.map(({ label, id }) => ({ label: t(label), id }));
};
export const useGetPeriodOptions = () => useMapTranslations('select');
export const useGetPeriodLabelsById = () => {
    const labels = useMapTranslations('label');
    return new Map(labels.map(({ label, id }) => [id, label]));
};
export const DEFAULT_PERIOD = Period.Month;
export default periods;
