import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from '@profesia/adamui/configuration';
import { Box, styled } from '@mui/material';
import { AiIcon } from '@profesia/adamui/components/common/icons';
import { Typography } from '@profesia/adamui/components/common/typography';
const Loading = () => {
    const { t } = useTranslation();
    return (_jsxs(BoxStyled, { gap: 1.5, children: [_jsx(AiIcon, {}), _jsx(Typography, { fontWeight: 500, lineHeight: 2.5, fontSize: 14, color: "primaryNavy", children: t('common.aiLoading') })] }));
};
const BoxStyled = styled(Box)(({ theme: { spacing } }) => ({
    height: '200px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));
export default Loading;
