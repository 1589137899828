export const getSkillListErrors = (errorItems, length) => {
    let skillErrors = [];
    for (let i = 0; i < length; i++) {
        const error = errorItems?.[i]?.level;
        if (error) {
            skillErrors.push({ index: i, message: error.message });
        }
    }
    return skillErrors;
};
