import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { SelectLanguage } from '@profesia/adamui/components/common/dropdown';
import { FORM_LANGUAGE } from '@profesia/mark-offer-form/components/content/form/valuePaths';
import { useGetOfferLanguageOptions } from '@profesia/mark-offer-form/codebooks';
import { useGetLocale, useGetFixedT, loadAndChangeLanguage } from '@profesia/mark-offer-form/i18n';
import { DEFAULT_FORM_DATA } from '@profesia/mark-offer-form/components/content/form/defaultValues';
import useToastWithTranslation from '@profesia/mark-offer-form/services/useToastWithTranslation';
const LanguageDropdown = () => {
    const t = useGetFixedT('jobTitle.language');
    const { setValue, control } = useFormContext();
    const addToast = useToastWithTranslation();
    const languageValue = useWatch({
        name: FORM_LANGUAGE,
        control,
        defaultValue: DEFAULT_FORM_DATA.language,
    });
    const currentLanguage = useGetLocale();
    const offerLanguages = useGetOfferLanguageOptions();
    const handleOfferLanguage = async (language, currentLang) => {
        if (language && language !== currentLang) {
            await loadAndChangeLanguage(language).catch(() => {
                addToast({ message: 'common.responseError.loadLanguage', variant: 'error' });
            });
        }
    };
    useEffect(() => {
        handleOfferLanguage(languageValue, currentLanguage);
    }, [languageValue, currentLanguage]);
    return (_jsx(SelectLanguage, { options: offerLanguages, value: languageValue, onChange: (language) => {
            setValue(FORM_LANGUAGE, language);
        }, defaultValue: currentLanguage, label: t('label') }));
};
export default LanguageDropdown;
