import { getConfiguration } from '@profesia/mark-offer-form/configuration';
import { useMutation } from '@tanstack/react-query';
import { POST_FORM, UPDATE_FORM } from './keys';
export const usePostForm = ({ onSuccess }) => {
    const { api } = getConfiguration();
    return useMutation({
        mutationKey: [POST_FORM],
        mutationFn: async (data) => api.postForm(data),
        onSuccess,
    });
};
export const useUpdateForm = ({ onSuccess }) => {
    const { api } = getConfiguration();
    return useMutation({
        mutationKey: [UPDATE_FORM],
        mutationFn: async (data) => api.updateForm(data),
        onSuccess,
    });
};
