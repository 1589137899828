import { jsx as _jsx } from "react/jsx-runtime";
import { useLayoutEffect, useRef, useState } from 'react';
import Tag from '@profesia/adamui/components/common/Tag';
import { FIELD_TAG } from '@profesia/adamui/components/common/input/selectors';
import { getTagWidth } from '@profesia/adamui/utils/tags';
import { generateKey } from '@profesia/adamui/utils/input';
import { getMaxTagWidth, getTagSize, } from '@profesia/adamui/components/common/input/textField/tagField/common/helpers';
import useTagFieldSpacing from './useTagFieldSpacing';
export const MIN_TAG_WIDTH = 50;
const blurredPlaceholderMinWidth = 35;
const moreTagsWidthsBySize = {
    large: [43, 51],
    medium: [39, 47],
};
const getMoreTagsWidth = (remainingTags, size) => {
    const [oneDigit, doubleDigits] = moreTagsWidthsBySize[size];
    if (remainingTags > 9) {
        return doubleDigits;
    }
    return remainingTags !== 0 ? oneDigit : 0;
};
const useTagsState = (size, value, hasReachedLimit, onChange = () => { }, disabled, invalidCharsPattern, renderTagsModifier = (values) => values, groupValues = (values) => values, selectedGroup = null, displayUnwrapped) => {
    const [tagsLimit, setTagsLimit] = useState(-1);
    const [truncatedTagWidth, setTruncatedTagWidth] = useState(null);
    const [isFocused, setIsFocused] = useState(false);
    const [showPlaceholder, setShowPlaceholder] = useState(false);
    const [input, setInput] = useState('');
    const ref = useRef(null);
    const valueLength = useRef(0);
    const lastInput = useRef('');
    const { totalPaddingX, gap } = useTagFieldSpacing();
    const onInputChange = (event, value) => {
        if (!hasReachedLimit && event && value !== ' ') {
            const validValue = invalidCharsPattern ? value.replace(invalidCharsPattern, '') : value;
            setInput(validValue);
            lastInput.current = validValue;
        }
    };
    const resetInput = () => setInput('');
    const updateState = () => {
        let remainingWidth = (ref.current?.offsetWidth ?? 0) - totalPaddingX;
        let newTagsLimit = 0;
        let truncatedTagWidth = null;
        const querySelectorResult = ref.current?.querySelectorAll(`.${FIELD_TAG}`);
        const queryResultLength = querySelectorResult?.length ?? 0;
        querySelectorResult?.forEach((tag) => {
            const tagWidth = getTagWidth(tag) + gap;
            const widthDifference = remainingWidth - tagWidth;
            const moreTagsWidth = getMoreTagsWidth(valueLength.current - newTagsLimit - 1, size);
            if (!truncatedTagWidth) {
                if (widthDifference < moreTagsWidth) {
                    truncatedTagWidth =
                        remainingWidth - moreTagsWidth - (moreTagsWidth === 0 ? 0 : gap);
                }
                newTagsLimit++;
                remainingWidth = widthDifference;
            }
        });
        const hasMoreTags = queryResultLength > newTagsLimit;
        if (hasMoreTags && truncatedTagWidth && truncatedTagWidth < MIN_TAG_WIDTH) {
            newTagsLimit -= 1;
            truncatedTagWidth = null;
        }
        setShowPlaceholder(!hasMoreTags && remainingWidth >= blurredPlaceholderMinWidth);
        setTagsLimit(newTagsLimit);
        setTruncatedTagWidth(truncatedTagWidth);
    };
    useLayoutEffect(() => {
        const { length } = value.length > 0 && typeof value[0] !== 'string'
            ? groupValues(value, selectedGroup)
            : value;
        if (length) {
            setTagsLimit(-1);
        }
        else {
            setShowPlaceholder(true);
        }
        valueLength.current = length;
    }, [value]);
    useLayoutEffect(() => {
        if (value.length && tagsLimit === -1) {
            updateState();
        }
    }, [tagsLimit, value.length]);
    const renderTags = (values) => renderTagsModifier(values, selectedGroup).map((item, index) => {
        const isItemString = typeof item === 'string';
        const { value, isGrouped = undefined, categoryId = '', id = '', } = isItemString ? { value: item } : item;
        return (_jsx(Tag, { editable: true, className: FIELD_TAG, onRemove: () => {
                const filteredValues = values.filter((valueToCheck) => {
                    if (isItemString) {
                        return value !== valueToCheck;
                    }
                    const autocompleteItem = valueToCheck;
                    if (autocompleteItem.categoryId) {
                        return !(autocompleteItem.categoryId === categoryId &&
                            (isGrouped || autocompleteItem.id === id));
                    }
                    if (autocompleteItem.id) {
                        return autocompleteItem.id !== id;
                    }
                    if (autocompleteItem.value) {
                        return autocompleteItem.value !== value;
                    }
                });
                onChange(filteredValues);
            }, maxWidth: getMaxTagWidth(index, tagsLimit, truncatedTagWidth, displayUnwrapped), size: getTagSize(size), disabled: disabled, children: value }, generateKey(index, value)));
    });
    const resetLastInput = () => (lastInput.current = '');
    return {
        renderTags,
        onInputChange,
        resetInput,
        resetLastInput,
        input,
        lastInput: lastInput.current,
        limitTags: tagsLimit,
        ref,
        showPlaceholder: showPlaceholder || isFocused,
        onFocus: () => setIsFocused(true),
        onBlur: () => {
            setIsFocused(false);
            resetLastInput();
        },
    };
};
export default useTagsState;
