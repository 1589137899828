import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { SmartLink } from '@profesia/adamui/components/common/Link';
import { generateKey } from '@profesia/adamui/utils/input';
import { useFlexDirectionByBreakpoint } from '@profesia/adamui/utils/responsive';
const CardFooterContainer = ({ id, content, actions }) => {
    const direction = useFlexDirectionByBreakpoint('sm');
    return (_jsxs(Box, { display: "flex", justifyContent: "space-between", my: "auto", flexDirection: direction, gap: 1, children: [content, actions ? (_jsx(Box, { display: "flex", gap: 2, children: actions.map(({ label, dataCy, ...action }, index) => (_jsx(SmartLink, { typographyProps: { component: 'span' }, "data-cy": dataCy, ...action, children: label }, generateKey(index, id)))) })) : null] }));
};
export default CardFooterContainer;
