import { jsx as _jsx } from "react/jsx-runtime";
import { styled } from '@mui/material';
import { Typography } from '@profesia/adamui/components/common/typography';
const Title = styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'stretch',
})(({ theme, stretch }) => ({
    padding: theme.spacing(0, 2),
    ...(stretch ? { display: 'flex', flex: '1 1 auto' } : {}),
}));
const ModalTitle = ({ children, ...otherProps }) => {
    return (_jsx(Title, { variant: "h3Medium", ...otherProps, children: children }));
};
export default ModalTitle;
