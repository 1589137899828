import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, styled } from '@mui/material';
import BookmarkOff from './BookmarkOff';
import BookmarkOffHover from './BookmarkOffHover';
import BookmarkOn from './BookmarkOn';
import BookmarkOnHover from './BookmarkOnHover';
const BookmarkContainer = styled(Box)(() => ({
    width: 'fit-content',
    lineHeight: 0.1,
    '.MuiSvgIcon-root': {
        cursor: 'pointer',
    },
    '.MuiSvgIcon-BookmarkOffHover, .MuiSvgIcon-BookmarkOnHover': {
        display: 'none',
    },
    '&:hover': {
        '& .MuiSvgIcon-BookmarkOff, .MuiSvgIcon-BookmarkOn': {
            display: 'none',
        },
        '& .MuiSvgIcon-BookmarkOffHover, .MuiSvgIcon-BookmarkOnHover': {
            display: 'initial',
        },
    },
}));
const BookmarkOffWithHover = (props) => (_jsxs(BookmarkContainer, { children: [_jsx(BookmarkOff, { ...props }), _jsx(BookmarkOffHover, { ...props })] }));
const BookmarkOnWithHover = (props) => (_jsxs(BookmarkContainer, { children: [_jsx(BookmarkOn, { ...props }), _jsx(BookmarkOnHover, { ...props })] }));
const BookmarkRibbon = ({ isBookmarked, onClick }) => {
    return isBookmarked ? (_jsx(BookmarkOnWithHover, { onClick: onClick })) : (_jsx(BookmarkOffWithHover, { onClick: onClick }));
};
export default BookmarkRibbon;
