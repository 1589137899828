import { useEffect } from 'react';
import { useCodebooksContext } from '@profesia/mark-offer-form/services/CodebooksContext';
import { FORM_JOB_POSITIONS, FORM_POSITION_PLACE_REGIONS, FORM_LANGUAGES_ITEMS, } from '@profesia/mark-offer-form/components/content/form/valuePaths';
import updatePositionValues from '@profesia/mark-offer-form/services/updateCodebookTranslations/updatePositionValues';
import updateRegionsValues from '@profesia/mark-offer-form/services/updateCodebookTranslations/updateRegionsValues';
import updateLanguageValues from '@profesia/mark-offer-form/services/updateCodebookTranslations/updateLanguageValues';
export const useUpdateCodebookTranslations = (setValue, getValues) => {
    const { data } = useCodebooksContext();
    useEffect(() => {
        if (data) {
            updatePositionValues(setValue, data.jobPositions.positions, getValues(FORM_JOB_POSITIONS) ?? []);
            updateRegionsValues(setValue, data.regions ?? [], getValues(FORM_POSITION_PLACE_REGIONS) ?? []);
            updateLanguageValues(setValue, data.languages ? [...data.languages.favourites, ...data.languages.others] : [], getValues(FORM_LANGUAGES_ITEMS) ?? []);
        }
    }, [data, setValue, getValues]);
};
