import { jsx as _jsx } from "react/jsx-runtime";
import createSvgIcon from '@profesia/adamui/components/common/icons/createSvgIcon';
const variantRelatedProps = {
    right: {
        path: (_jsx("path", { d: "M11.9623 8.10396C11.9631 8.24347 11.9365 8.38177 11.8842 8.51092C11.8319 8.64008 11.7549 8.75755 11.6575 8.85661L6.75956 13.7965C6.55501 13.9469 6.30351 14.0178 6.05129 13.9962C5.79907 13.9745 5.56306 13.8618 5.38665 13.6787C5.21023 13.4956 5.10525 13.2545 5.09101 12.9996C5.07676 12.7447 5.1542 12.4932 5.3091 12.2912L9.51331 8.10396L5.3091 3.86367C5.11119 3.66406 5 3.39332 5 3.11102C5 2.82872 5.11119 2.55799 5.3091 2.35837C5.50702 2.15876 5.77545 2.04661 6.05535 2.04661C6.33525 2.04661 6.60368 2.15876 6.8016 2.35837L11.6154 7.35131C11.7204 7.44678 11.8053 7.56265 11.8649 7.69197C11.9245 7.8213 11.9576 7.96142 11.9623 8.10396Z" })),
        name: 'ChevronRight',
    },
    down: {
        path: (_jsx("path", { d: "M7.89604 11.9623C7.75653 11.9631 7.61823 11.9365 7.48908 11.8842C7.35992 11.8319 7.24245 11.7549 7.14339 11.6575L2.20346 6.75956C2.05307 6.55501 1.98217 6.30351 2.00382 6.05129C2.02546 5.79907 2.13818 5.56306 2.32127 5.38665C2.50436 5.21023 2.74551 5.10525 3.00042 5.09101C3.25532 5.07676 3.50684 5.1542 3.70876 5.3091L7.89604 9.51331L12.1363 5.3091C12.3359 5.11119 12.6067 5 12.889 5C13.1713 5 13.442 5.11119 13.6416 5.3091C13.8412 5.50702 13.9534 5.77545 13.9534 6.05535C13.9534 6.33525 13.8412 6.60368 13.6416 6.8016L8.64869 11.6154C8.55322 11.7204 8.43735 11.8053 8.30803 11.8649C8.1787 11.9245 8.03858 11.9576 7.89604 11.9623Z" })),
        name: 'ChevronDown',
    },
};
const Chevron = ({ variant = 'right', ...otherProps }) => {
    const { path, name } = variantRelatedProps[variant];
    const Icon = createSvgIcon(path, name, {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        color: 'neutralDark3',
    });
    return _jsx(Icon, { ...otherProps });
};
export default Chevron;
