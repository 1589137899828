import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from 'react';
import createSvgIcon from '@profesia/adamui/components/common/icons/createSvgIcon';
const menuDotsTypes = {
    small: {
        size: 24,
        path: (_jsxs(_Fragment, { children: [_jsx("circle", { cx: "12", cy: "21", r: "2", transform: "rotate(-90 12 21)", fill: "#384B69" }), _jsx("circle", { cx: "12", cy: "12", r: "2", transform: "rotate(-90 12 12)", fill: "#384B69" }), _jsx("circle", { cx: "12", cy: "3", r: "2", transform: "rotate(-90 12 3)", fill: "#384B69" })] })),
    },
    regular: {
        size: 32,
        path: (_jsxs(_Fragment, { children: [_jsx("circle", { cx: "16", cy: "28", r: "3", transform: "rotate(-90 16 28)", fill: "#384B69" }), _jsx("circle", { cx: "16", cy: "16", r: "3", transform: "rotate(-90 16 16)", fill: "#384B69" }), _jsx("circle", { cx: "16", cy: "4", r: "3", transform: "rotate(-90 16 4)", fill: "#384B69" })] })),
    },
};
const MenuDots = ({ variant = 'regular', ...props }) => {
    const { size, path } = menuDotsTypes[variant];
    const Icon = createSvgIcon(_jsx(Fragment, { children: path }), 'MenuDots', {
        width: size,
        height: size,
        viewBox: `0 0 ${size} ${size}`,
    });
    return _jsx(Icon, { ...props });
};
export default MenuDots;
