import { jsx as _jsx } from "react/jsx-runtime";
import { useFormContext, useWatch } from 'react-hook-form';
import { Box } from '@mui/material';
import { SelectDefault } from '@profesia/adamui/components/common/dropdown';
import { DEFAULT_CURRENCY, useGetCurrenciesOptions } from '@profesia/mark-offer-form/codebooks';
import { FORM_SALARY_CURRENCY } from '@profesia/mark-offer-form/components/content/form/valuePaths';
import { useGetFixedT } from '@profesia/mark-offer-form/i18n';
const Currency = () => {
    const t = useGetFixedT('salaryType');
    const currencies = useGetCurrenciesOptions();
    const { setValue, control } = useFormContext();
    const value = useWatch({ name: FORM_SALARY_CURRENCY, control, defaultValue: DEFAULT_CURRENCY });
    return (_jsx(Box, { maxWidth: 72, children: _jsx(SelectDefault, { color: "primary", options: currencies, value: value, onChange: (value) => setValue(FORM_SALARY_CURRENCY, value), label: t('currency') }) }));
};
export default Currency;
