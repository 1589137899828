import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl, FormGroup, styled } from '@mui/material';
import { generateKey } from '@profesia/adamui/utils/input';
import { InputLabel } from '@profesia/adamui/components/common/input';
import { Typography } from '@profesia/adamui/components/common/typography';
import ValidationMessage from '@profesia/adamui/components/common/input/ValidationMessage';
import Checkbox from '../Checkbox';
const CheckboxGroup = ({ checkedValues = [], description, options, onChange, direction = 'column', allowMultipleValues = true, variant, errorMessage, ...otherProps }) => {
    const onChangeHandler = (id) => {
        if (onChange) {
            let onChangeValues;
            if (allowMultipleValues) {
                const filteredValues = checkedValues.filter((checkedValueId) => checkedValueId !== id);
                onChangeValues =
                    filteredValues.length !== checkedValues.length
                        ? filteredValues
                        : [...checkedValues, id];
            }
            else {
                onChangeValues = checkedValues.includes(id) ? [] : [id];
            }
            onChange(onChangeValues);
        }
    };
    const [finalDirection, spacing] = variant === 'highlighted' ? ['row', 0.5] : [direction, direction === 'row' ? 6 : 0];
    const hasError = Boolean(errorMessage);
    const inputLabelProps = {
        ...otherProps,
        hasError,
    };
    //@TODO treba zmeniť id a stringKey na value a label
    return (_jsx(InputLabel, { ...inputLabelProps, children: _jsxs(FormControl, { component: "fieldset", variant: "standard", children: [_jsxs(CheckboxFormGroup, { row: finalDirection === 'row', spacing: spacing, children: [description ? _jsx(Typography, { variant: "body2", children: description }) : null, options.map(({ id, label }, index) => (_jsx(Checkbox, { label: label, variant: variant, value: id, checked: checkedValues.includes(id), onChange: () => onChangeHandler(id), hasError: hasError }, generateKey(index, id))))] }), errorMessage ? _jsx(ValidationMessage, { mt: 1, children: errorMessage }) : null] }) }));
};
const CheckboxFormGroup = styled(FormGroup, {
    shouldForwardProp: (prop) => prop !== 'spacing',
})(({ theme: { spacing }, spacing: spacingValue }) => ({
    '&.MuiFormGroup-root': {
        alignItems: 'start',
        gap: spacing(spacingValue),
    },
}));
export default CheckboxGroup;
