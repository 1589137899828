import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useFormContext } from 'react-hook-form';
import { Stack } from '@mui/material';
import BoxedSectionWrapper from '@profesia/mark-offer-form/components/content/form/components/BoxedSectionWrapper';
import { useGetFixedT } from '@profesia/mark-offer-form/i18n';
import EmploymentType from '@profesia/mark-offer-form/components/content/PositionDescription/EmploymentType';
import OfferSuitableFor from '@profesia/mark-offer-form/components/content/PositionDescription/OfferSuitableFor';
import JobDescription from '@profesia/mark-offer-form/components/content/PositionDescription/JobDescription';
import Benefits from '@profesia/mark-offer-form/components/content/PositionDescription/Benefits';
import JobPositions from '@profesia/mark-offer-form/components/content/PositionDescription/JobPositions';
import { getErrorAndScrollClass } from '@profesia/mark-offer-form/components/content/form';
import Hint from './JobPositions/Hint';
import { HintLayout } from '../hint';
import { POSITION_DESCRIPTION_SECTION, JOB_DESCRIPTION_SECTION, } from '@profesia/mark-offer-form/codebooks/dataTestId';
const PositionDescription = () => {
    const t = useGetFixedT('positionDescription');
    const { formState: { errors }, } = useFormContext();
    const [jobPositionsErrorMessage, jobPositionsErrorClass] = getErrorAndScrollClass(errors.jobPositions);
    const [employmentTypeErrorMessage, employmentTypeErrorClass] = getErrorAndScrollClass(errors.employmentTypes);
    const [jobDescriptionErrorMessage, jobDescriptionErrorClass] = getErrorAndScrollClass(errors.jobDescription);
    return (_jsxs(_Fragment, { children: [_jsx(BoxedSectionWrapper, { title: t('title'), className: employmentTypeErrorClass || jobPositionsErrorClass, testId: POSITION_DESCRIPTION_SECTION, children: _jsxs(Stack, { gap: 3.25, children: [_jsx(HintLayout, { hint: _jsx(Hint, {}), Content: JobPositions, ml: 8 }), _jsx(EmploymentType, { errorMessage: employmentTypeErrorMessage }), _jsx(OfferSuitableFor, {})] }) }), _jsx(BoxedSectionWrapper, { className: jobDescriptionErrorClass, testId: JOB_DESCRIPTION_SECTION, children: _jsxs(Stack, { gap: 3.25, children: [_jsx(JobDescription, { errorMessage: jobDescriptionErrorMessage }), _jsx(Benefits, {})] }) })] }));
};
export default PositionDescription;
