import { Typography as MuiTypography, styled, } from '@mui/material';
import { getColor } from '@profesia/adamui/styles/colors';
const mappedWeights = {
    regular: 'fontWeightRegular',
    medium: 'fontWeightMedium',
    bold: 'fontWeightBold',
};
const Typography = styled(MuiTypography, {
    shouldForwardProp: (propName) => propName !== 'weight',
})(({ weight, color = 'black0', theme: { typography, palette } }) => ({
    fontWeight: weight ? typography[mappedWeights[weight]] : undefined,
    color: getColor(palette[color]),
}));
export default Typography;
