import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, styled } from '@mui/material';
import { getColor } from '@profesia/adamui/styles/colors';
import { DesktopSIcon, MobileIcon } from '@profesia/adamui/components/common/icons';
import { useGetFixedT } from '@profesia/adamui/configuration';
import Item from './Item';
export var DeviceSwitcherValue;
(function (DeviceSwitcherValue) {
    DeviceSwitcherValue[DeviceSwitcherValue["desktop"] = 0] = "desktop";
    DeviceSwitcherValue[DeviceSwitcherValue["mobile"] = 1] = "mobile";
})(DeviceSwitcherValue || (DeviceSwitcherValue = {}));
const items = [
    {
        value: DeviceSwitcherValue.desktop,
        icon: _jsx(DesktopSIcon, {}),
        labelKey: 'desktop',
    },
    {
        value: DeviceSwitcherValue.mobile,
        icon: _jsx(MobileIcon, {}),
        labelKey: 'mobile',
    },
];
const DeviceSwitcher = ({ value: currentValue, onClick }) => {
    const t = useGetFixedT('common');
    return (_jsx(DeviceSwitcherWrapper, { children: items.map(({ icon, labelKey, value }) => (_jsx(Item, { className: currentValue === value ? 'Mui-active' : undefined, onClick: () => onClick(value), children: _jsxs(_Fragment, { children: [icon, t(labelKey)] }) }, labelKey))) }));
};
const DeviceSwitcherWrapper = styled(Box)(({ theme: { palette, spacing } }) => ({
    display: 'flex',
    width: 'fit-content',
    borderRadius: 8,
    backgroundColor: getColor(palette.neutralLight3),
    padding: spacing(0.5),
}));
export default DeviceSwitcher;
