import { isWysiWygEditorEmpty } from '@profesia/adamui/utils/WysiwygEditor';
import { FORM_EDUCATION_LEVEL, FORM_EMPLOYMENT_TYPES, FORM_JOB_DESCRIPTION, FORM_JOB_POSITIONS, FORM_JOB_TITLE, FORM_POSITION_PLACE_ADDRESS, FORM_POSITION_PLACE_REGIONS, FORM_POSITION_PLACE_TYPE, FORM_SALARY_FROM, FORM_SALARY_TO, FORM_SALARY_TYPE, FORM_SALARY_VALUE, } from '@profesia/mark-offer-form/components/content/form/valuePaths';
import { EmploymentType } from '@profesia/mark-offer-form/codebooks';
const createRequiredError = (message) => ({
    type: 'required',
    message,
});
const getLevelOptionsErrors = (items, errorMessage) => {
    let hasError = false;
    const errorData = items.reduce((errors, item) => {
        if (!item.level) {
            errors.push({ level: createRequiredError(errorMessage) });
            hasError = true;
        }
        else {
            errors.push({ level: null });
        }
        return errors;
    }, []) ?? null;
    return hasError ? errorData : null;
};
const getLanguagesLevelOptionsErrors = (languages, errorMessage) => getLevelOptionsErrors(languages?.items ?? [], errorMessage);
const getSkillsLevelOptionsErrors = (skills, errorMessage) => getLevelOptionsErrors(skills?.others ?? [], errorMessage);
const addError = (errors, field, condition, message) => {
    if (condition) {
        const keys = field.split('.');
        let current = errors;
        keys.forEach((key, index) => {
            if (index === keys.length - 1) {
                current[key] = createRequiredError(message);
            }
            else {
                current[key] = current[key] || {};
                current = current[key];
            }
        });
    }
};
const getErrors = ({ jobTitle, jobPositions, employmentTypes, jobDescription, positionPlace, salary, educationLevel, languages, skills, }, t) => {
    let errors = {};
    addError(errors, 'jobTitle', !jobTitle, t('jobTitle'));
    addError(errors, 'jobPositions', jobPositions.length === 0, t('jobPositions'));
    addError(errors, 'employmentTypes', employmentTypes.length === 0, t('employmentType'));
    addError(errors, 'jobDescription', !jobDescription || isWysiWygEditorEmpty(jobDescription), t('jobDescription'));
    addError(errors, 'positionPlace.type', !positionPlace?.type, t('positionPlace.type'));
    addError(errors, 'positionPlace.address', (positionPlace?.type === 1 || positionPlace?.type === 4) &&
        (!positionPlace?.address || positionPlace?.address.length === 0), t('positionPlace.address'));
    addError(errors, 'positionPlace.regions', positionPlace?.type === 2 &&
        (!positionPlace?.regions || positionPlace?.regions.length === 0), t('positionPlace.regions'));
    addError(errors, 'salary.type', !(!salary?.type &&
        employmentTypes.length === 1 &&
        employmentTypes.includes(EmploymentType.Contract)) && !salary?.type, t('salary.type'));
    addError(errors, 'salary.value', salary?.type === 'fixed' && !salary?.value, t('salary.value'));
    addError(errors, 'salary.from', salary?.type === 'range' && !salary?.from, t('salary.from'));
    addError(errors, 'salary.to', salary?.type === 'range' && !salary?.to, t('salary.to'));
    addError(errors, 'salary.to', salary !== null &&
        salary?.type === 'range' &&
        salary?.from !== null &&
        salary?.to !== null &&
        salary?.from > salary?.to, t('salary.minMaxCheck'));
    addError(errors, 'educationLevel', !educationLevel, t('education'));
    const languageErrors = getLanguagesLevelOptionsErrors(languages, t('languages.items.level'));
    if (languageErrors) {
        errors = { ...errors, languages: { items: languageErrors } };
    }
    const skillErrors = getSkillsLevelOptionsErrors(skills, t('skills.others.level'));
    if (skillErrors) {
        errors = { ...errors, skills: { others: skillErrors } };
    }
    return Object.keys(errors).length > 0 ? errors : {};
};
export const validatedFields = [
    FORM_JOB_TITLE,
    FORM_EDUCATION_LEVEL,
    FORM_EMPLOYMENT_TYPES,
    FORM_JOB_DESCRIPTION,
    FORM_JOB_POSITIONS,
    FORM_POSITION_PLACE_ADDRESS,
    FORM_POSITION_PLACE_REGIONS,
    FORM_POSITION_PLACE_TYPE,
    FORM_SALARY_FROM,
    FORM_SALARY_TO,
    FORM_SALARY_TYPE,
    FORM_SALARY_VALUE,
];
export default getErrors;
