import { jsx as _jsx } from "react/jsx-runtime";
import { FormControlLabel, Radio as MuiRadio, styled, } from '@mui/material';
import { HIDE_ON_HOVER, RadioButtonIcon, SHOW_ON_HOVER, } from '@profesia/adamui/components/common/icons';
const Radio = ({ variant = 'default', label = '', type = 'primary', ...otherProps }) => {
    return (_jsx(FormControlLabel, { control: _jsx(StyledRadio, { icon: _jsx(RadioButtonIcon, { variant: variant, state: "unchecked", type: type }), checkedIcon: _jsx(RadioButtonIcon, { variant: variant, state: "checked", type: type }), ...otherProps }), label: label, labelPlacement: "end" }));
};
const StyledRadio = styled(MuiRadio)(() => ({
    '&.MuiRadio-root': {
        [`.${SHOW_ON_HOVER}`]: {
            display: 'none',
        },
        [`.${HIDE_ON_HOVER}`]: {
            display: 'block',
        },
        '&:hover': {
            [`.${SHOW_ON_HOVER}`]: {
                display: 'block',
            },
            [`.${HIDE_ON_HOVER}`]: {
                display: 'none',
            },
        },
    },
}));
export default Radio;
