import { jsx as _jsx } from "react/jsx-runtime";
import { RadioGroup, Stack } from '@mui/material';
import InputLabel from '@profesia/adamui/components/common/input/InputLabel';
import { Radio } from '@profesia/adamui/components/common/input/radioButtons';
const RadioButtons = ({ label, optionalLabel, options, children, ...otherProps }) => {
    return (_jsx(InputLabel, { label: label, optionalLabel: optionalLabel, children: _jsx(RadioGroup, { ...otherProps, children: _jsx(Stack, { direction: "column", alignItems: "start", spacing: 1, children: options.map((option) => {
                    return _jsx(Radio, { value: option.id, label: option.label }, option.id);
                }) }) }) }));
};
export default RadioButtons;
