import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useGetFixedT } from '@profesia/adamui/configuration';
import Option from './Option';
import { filterOptions, getPaperComponent, mergeValues, remapValuesForTagField } from './helpers';
const useTagFieldProps = (values, onChange, options, favouriteItems, allowFreeValue, onFavouriteItemClick, optionsProps) => {
    const t = useGetFixedT('common.comboTagger');
    const placeholder = t('placeholder');
    const PaperComponent = useMemo(() => getPaperComponent(favouriteItems, onFavouriteItemClick), [favouriteItems]);
    return {
        onChange: (updatedValues) => {
            onChange(mergeValues(updatedValues, values));
        },
        value: remapValuesForTagField(values),
        placeholder: {
            empty: placeholder,
            filled: placeholder,
        },
        noOptionsText: t('noOptions'),
        options,
        optionsProps: {
            renderOption: (props, option) => (_jsx(Option, { option: option, liProps: props }, option.value)),
            filterOptions: (options, { inputValue }) => filterOptions(options, inputValue, allowFreeValue),
            PaperComponent,
            getOptionLabel: (option) => option?.value ?? '',
            isOptionEqualToValue: ({ id: id1 }, { id: id2 }) => id1 === id2,
            ...optionsProps,
        },
    };
};
export default useTagFieldProps;
