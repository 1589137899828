import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, styled } from '@mui/material';
import { tabletDown } from '@profesia/adamui/styles/theme';
import { generateSkeletonRectangularHeight10, Card, SkeletonRectangular, Wysiwyg } from './common';
const Skeleton = () => (_jsxs(StyledStack, { children: [_jsx(SkeletonRectangular, { maxWidth: 244 }), _jsxs(Card, { direction: "row", gap: 2.75, children: [_jsx(SkeletonRectangular, { maxWidth: 731 }), _jsx(SkeletonRectangular, { maxWidth: 44 })] }), _jsxs(Stack, { gap: 2.75, children: [_jsx(SkeletonRectangular, { maxWidth: 179 }), _jsxs(Card, { gap: 2.75, children: [_jsx(SkeletonRectangular, {}), generateSkeletonRectangularHeight10([116, 194], 3), generateSkeletonRectangularHeight10([188, 188], 3.25)] })] }), _jsxs(Stack, { gap: 2.75, children: [_jsx(SkeletonRectangular, { maxWidth: 314 }), _jsx(Wysiwyg, {})] }), _jsxs(Stack, { gap: 2.75, children: [_jsx(SkeletonRectangular, { maxWidth: 246 }), _jsxs(Stack, { gap: 2, children: [_jsx(Wysiwyg, {}), _jsx(Wysiwyg, {})] })] })] }));
const StyledStack = styled(Stack)(({ theme: { spacing } }) => ({
    boxSizing: 'border-box',
    maxWidth: '860px',
    width: '100%',
    gap: spacing(4),
    marginBottom: spacing(2),
    paddingTop: spacing(7),
    [tabletDown]: {
        padding: spacing(2),
    },
}));
export default Skeleton;
