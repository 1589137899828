import { useState } from 'react';
const useFocusedState = () => {
    const [className, setClassName] = useState('');
    return [
        className,
        () => {
            setClassName('Mui-focused');
        },
        () => {
            setClassName('');
        },
    ];
};
export default useFocusedState;
