import { jsx as _jsx } from "react/jsx-runtime";
import { InputField } from '@profesia/adamui/components/common/input';
const NumberField = ({ onChange, value, disableZeroValue, ...props }) => {
    const onChangeInternal = (event) => {
        const digitValue = event.target.value.replaceAll(/\D/g, '').substring(0, 16);
        const newValue = digitValue && !(disableZeroValue && digitValue === '0')
            ? parseInt(digitValue)
            : undefined;
        if (newValue !== value) {
            onChange(newValue);
        }
    };
    return (_jsx(InputField, { inputMode: "numeric", onChange: onChangeInternal, value: value?.toString() ?? '', ...props }));
};
export default NumberField;
