import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { Box } from '@mui/material';
import { TypographySpan } from '@profesia/adamui/components/common/typography';
import StyledSwitcher from './StyledSwitcher';
const Switcher = forwardRef(({ label = '', size = 's', checked = false, onChange = () => { }, ...otherSwitcherProps }, ref) => {
    const finalLabel = label ? getLabel(checked, label) : '';
    const labelFontWeight = typeof finalLabel === 'string' ? 'bold' : 'normal';
    const switcher = (_jsx(StyledSwitcher, { ref: ref, size: size, checked: checked, onChange: onChange, ...otherSwitcherProps }));
    return (_jsx(_Fragment, { children: finalLabel ? (_jsxs(Box, { display: "flex", gap: 1, children: [_jsx(TypographySpan, { fontWeight: labelFontWeight, children: finalLabel }), switcher] })) : (switcher) }));
});
const getLabel = (isOn, label) => {
    let stateBasedLabel;
    if (typeof label === 'object') {
        stateBasedLabel = label?.[isOn ? 'labelOn' : 'labelOff'];
    }
    return stateBasedLabel ?? label;
};
export default Switcher;
