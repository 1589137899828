import { getConfiguration } from '@profesia/mark-offer-form/configuration';
export const getAutocompleteSkills = async (inputValue, values) => {
    try {
        const { api } = getConfiguration();
        const response = await api.getOtherSkills(inputValue);
        const customValues = values.filter((value) => value.id === undefined);
        if ([...response, ...customValues].find((item) => item.value === inputValue) === undefined) {
            response.push({ id: `custom_${inputValue}`, value: inputValue });
        }
        return response;
    }
    catch (error) {
        throw new Error('Error while getting autocomplete predictions');
    }
};
