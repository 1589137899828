import { jsx as _jsx } from "react/jsx-runtime";
import { useFormContext, useWatch } from 'react-hook-form';
import { InputField } from '@profesia/adamui/components/common/input';
import HiddenFields from '@profesia/adamui/components/common/HiddenFields';
import { useGetFixedT } from '@profesia/mark-offer-form/i18n';
import { LOCATION_JOB_AD, NAME_START_DATE } from '@profesia/mark-offer-form/utils/track/constants';
import { trackEvent } from '@profesia/mark-offer-form/utils/track/track';
import { FORM_POSITION_START_DATE } from '@profesia/mark-offer-form/components/content/form/valuePaths';
const StartDate = () => {
    const t = useGetFixedT('positionPlace');
    const { setValue, control, } = useFormContext();
    const value = useWatch({ name: FORM_POSITION_START_DATE, control });
    return (_jsx(HiddenFields, { buttonLabel: t('startDate'), shouldBeHidden: !value, children: _jsx(InputField, { label: t('startDate'), value: value, onChange: ({ target }) => setValue(FORM_POSITION_START_DATE, target.value) }), onClick: () => trackEvent(LOCATION_JOB_AD, NAME_START_DATE, null) }));
};
export default StartDate;
