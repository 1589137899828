import { useGetCurrencyLabelsById, useGetPeriodLabelsById, } from '@profesia/mark-offer-form/codebooks';
import { useGetFixedT } from '@profesia/mark-offer-form/i18n';
const useGetLabel = ({ salary: { currency, period, from, to, value }, isDefault, }) => {
    const t = useGetFixedT('salaryType');
    const currenciesById = useGetCurrencyLabelsById();
    const periodsById = useGetPeriodLabelsById();
    const currencyAndPeriod = ` ${currenciesById.get(currency)}/${periodsById.get(period)}`;
    const fromPrefix = t('range.from').toLowerCase();
    if (value) {
        return [`${value}${currencyAndPeriod}`];
    }
    if (isDefault) {
        return [`${fromPrefix} ${from}${currencyAndPeriod}`, `${from} - ${to}${currencyAndPeriod}`];
    }
    const [prefix, toValue] = to ? ['', ` - ${to}`] : [`${fromPrefix} `, ''];
    return [`${prefix}${from}${toValue}${currencyAndPeriod}`];
};
export default useGetLabel;
