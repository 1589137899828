import { GetOtherSkillsResponse } from '@profesia/mark-offer-form/services';
import { OptionItem } from './types';
import apiRequest from './apiRequest';

type Response = OptionItem[];

const remapSkills = (otherSkills: OptionItem[]) =>
    otherSkills.map(({ id, name }) => ({ id: id.toString(), value: name }));

const getOtherSkills = async (otherSkill: string): Promise<GetOtherSkillsResponse> => {
    const jsonResponse = await apiRequest<Response>(
        `codebook/skill?locale=sk&query=${otherSkill}`,
        {
            method: 'GET',
        }
    );

    return remapSkills(jsonResponse);
};

export default getOtherSkills;
