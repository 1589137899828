import { styled } from '@mui/material';
import Label from '@profesia/adamui/components/common/Label';
import { MUI_ERROR_CLASS } from '@profesia/adamui/styles/classes';
import { getColor } from '@profesia/adamui/styles/colors';
const BottomLabel = styled(Label, { shouldForwardProp: (prop) => prop !== 'fontSize' })(({ theme: { palette: { neutralDark4, secondaryRed }, }, fontSize, }) => ({
    [`&.${MUI_ERROR_CLASS}`]: {
        fontSize: '14px',
        color: getColor(secondaryRed),
    },
    fontSize: fontSize === 'medium' ? '14px' : '12px',
    color: getColor(neutralDark4),
    lineHeight: '20px',
    fontWeight: '400',
}));
export default BottomLabel;
