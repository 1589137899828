import { jsx as _jsx } from "react/jsx-runtime";
import { Button, styled } from '@mui/material';
import { getConfiguration, useGetPath } from '@profesia/adamui/configuration';
const getRouterLinkProps = ({ routeName, routeOptions }) => {
    const { linkComponent: RouterLink } = getConfiguration().router;
    return {
        component: RouterLink,
        to: useGetPath(routeName, routeOptions),
    };
};
const StyledButtonUniversal = styled(Button)(() => ({
    padding: 0,
}));
const ButtonUniversal = ({ to, ...otherProps }) => {
    const finalProps = {
        onClick: () => { },
        ...(to && !otherProps.href ? getRouterLinkProps(to) : {}),
        ...otherProps,
    };
    return _jsx(StyledButtonUniversal, { ...finalProps });
};
export default ButtonUniversal;
