import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled, Box } from '@mui/material';
import { TranslatedSpan } from '@profesia/adamui/i18n/trans';
import { getColor } from '@profesia/adamui/styles/colors';
import { ButtonSquare } from '@profesia/adamui/components/common/button';
import { CloseIcon } from '@profesia/adamui/components/common/icons';
import { useMobile } from '@profesia/adamui/utils/responsive';
import { useGetIconSize } from '@profesia/adamui/utils/icons';
const Actions = ({ onClose, children, closeButtonText = _jsx(TranslatedSpan, { children: "common.actions.close" }), }) => {
    const isMobile = useMobile();
    const iconSize = useGetIconSize();
    return (_jsxs(ActionsContainer, { children: [_jsxs(ButtonSquare, { onClick: onClose, isMobile: isMobile, size: isMobile ? 'small' : 'large', children: [_jsx(CloseIcon, { type: iconSize }), !isMobile ? closeButtonText : null] }), children] }));
};
const ActionsContainer = styled(Box)(({ theme: { palette } }) => ({
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: getColor(palette.neutralLight3),
    backgroundColor: getColor(palette.neutralLight1),
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    '& > *': {
        marginLeft: 0,
    },
    '& > .MuiButtonBase-root:not(:first-of-type)': {
        borderRight: 0,
    },
    '& > .MuiButtonBase-root:first-of-type': {
        borderLeft: 0,
    },
}));
export default Actions;
