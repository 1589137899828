import { jsx as _jsx } from "react/jsx-runtime";
import Group from './Group';
import Option from './Option';
import wordsMatcher from './wordsMatcher';
export const MINIMAL_INPUT_LENGTH = 2;
const tagFieldAdapter = ({ jobPositions, PaperComponent, tooltips, }) => {
    const { groups, duplicates, options } = jobPositions ?? {};
    const groupValues = (values, selectedGroup) => {
        let firstFound = -1;
        return {
            values: selectedGroup
                ? values.filter(({ categoryId }, idx) => {
                    if (categoryId === selectedGroup) {
                        if (firstFound === -1) {
                            firstFound = idx;
                            return true;
                        }
                        return false;
                    }
                    return true;
                })
                : values,
            firstFound,
        };
    };
    return {
        freeSolo: true,
        renderGroup: ({ group, ...otherProps }) => (_jsx(Group, { group: group, groupData: groups?.[group], options: options, tooltipContent: tooltips.treeGroup, ...otherProps })),
        renderOption: (_, option, state) => {
            const categoryId = option.categoryId;
            return (_jsx(Option, { option: option, state: state, groupSize: groups?.[categoryId].count ?? 1, tooltipContent: tooltips.option }, option.id));
        },
        groupBy: ({ categoryId }) => categoryId,
        getOptionLabel: (option) => option?.value ?? '',
        PaperComponent,
        filterOptions: (options, { inputValue }) => {
            const checkForMatch = wordsMatcher(inputValue);
            return inputValue?.length >= MINIMAL_INPUT_LENGTH
                ? options.filter(({ value }) => checkForMatch(value))
                : options;
        },
        isOptionEqualToValue: ({ id: id1, categoryId: categoryId1 }, { id: id2, categoryId: categoryId2 }) => id1 === id2 && categoryId1 === categoryId2,
        groupProps: {
            renderTagsModifier: (values, selectedGroup) => {
                const { values: positions, firstFound } = groupValues(values, selectedGroup);
                return positions.map((position, idx) => {
                    const { id, value, categoryId } = position;
                    if (idx === firstFound) {
                        return {
                            ...position,
                            value: groups?.[categoryId].name,
                            isGrouped: true,
                        };
                    }
                    return ((id && duplicates?.get(id)) ?? 0)
                        ? {
                            ...position,
                            value: `${value} (${groups?.[categoryId].name})`,
                        }
                        : position;
                });
            },
            groupValues: (values, selectedGroup) => groupValues(values, selectedGroup).values,
            groups: jobPositions?.groups ?? {},
        },
    };
};
export default tagFieldAdapter;
