export var ComponentTypeEnum;
(function (ComponentTypeEnum) {
    ComponentTypeEnum["button"] = "button";
    ComponentTypeEnum["buttonModal"] = "button - modal";
    ComponentTypeEnum["buttonRewrite"] = "button - rewrite";
    ComponentTypeEnum["buttonNewOption"] = "button - new option";
    ComponentTypeEnum["buttonField"] = "button - field";
})(ComponentTypeEnum || (ComponentTypeEnum = {}));
var EventTypeEnum;
(function (EventTypeEnum) {
    EventTypeEnum["componentClick"] = "component_click";
})(EventTypeEnum || (EventTypeEnum = {}));
export const trackEvent = (location, name, componentType = ComponentTypeEnum.button, action = null, feature = '') => {
    getDataLayer();
    const hit = {
        component_location: location,
        component_name: name,
    };
    if (action !== null) {
        hit.component_action = normalize(action);
    }
    if (componentType !== null) {
        hit.component_type = componentType;
    }
    if (feature !== '') {
        hit.component_feature = feature;
    }
    const event = {
        hit,
        event: EventTypeEnum.componentClick,
        _clear: true,
    };
    window.dataLayer?.push(event);
};
const normalize = (value) => value.charAt(0).toUpperCase() + value.slice(1);
const getDataLayer = () => {
    window.dataLayer = window.dataLayer ?? [];
    return window.dataLayer;
};
