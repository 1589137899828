import { jsxs as _jsxs } from "react/jsx-runtime";
import { InputLabel as InputLabelUI, styled } from '@mui/material';
import { Typography } from '@profesia/adamui/components/common/typography';
import { getColor } from '@profesia/adamui/styles/colors';
const Label = ({ optionalLabel, children, fontWeight, ...otherProps }) => {
    return (_jsxs(InputLabel, { shrink: false, fontWeight: fontWeight, ...otherProps, children: [children, optionalLabel ? (_jsxs(Typography, { ml: 0.5, color: "neutralDark4", variant: "body2", component: "span", children: ["(", optionalLabel, ")"] })) : null] }));
};
const InputLabel = styled(InputLabelUI, {
    shouldForwardProp: (prop) => prop !== 'fontWeight',
})(({ theme: { palette }, fontWeight }) => ({
    '.MuiInputLabel-asterisk': {
        color: getColor(palette.secondaryRed),
    },
    fontWeight,
}));
export default Label;
