import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled } from '@mui/material';
import ButtonUniversal from '../ButtonUniversal';
import { AddItemIcon } from '@profesia/adamui/components/common/icons';
import { getColor } from '@profesia/adamui/styles/colors';
const ButtonAddItem = ({ width, children, ...otherProps }) => (_jsxs(ButtonUniversalStyled, { width: width, ...otherProps, children: [_jsx(AddItemIcon, {}), children] }));
const ButtonUniversalStyled = styled(ButtonUniversal, {
    shouldForwardProp: (prop) => prop !== 'width',
})(({ theme: { spacing, typography, palette }, width }) => ({
    padding: spacing(0.5, 1, 0.5, 0.5),
    fontWeight: typography.fontWeightBold,
    color: getColor(palette.neutralDark1),
    borderRadius: 4,
    width,
    '&:hover': {
        backgroundColor: getColor(palette.neutralLight0),
    },
    '.MuiSvgIcon-AddItem': {
        marginRight: spacing(0.5),
    },
}));
export default ButtonAddItem;
