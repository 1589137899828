import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { Slide, Snackbar } from '@mui/material';
import ToastContent from '@profesia/adamui/components/common/Toast/ToastContent';
const SlideTransition = ({ children, ...otherProps }) => {
    return (_jsx(Slide, { ...otherProps, direction: "left", children: children }));
};
const Toast = ({ isUsedInStack = false, onClose = () => { }, autoHideDuration, ...contentProps }) => {
    const [closeTriggered, setCloseTriggered] = useState(null);
    const isVisible = !closeTriggered;
    const visibilityProps = {
        onClose: (event, reason) => {
            setCloseTriggered({ event, reason });
        },
        autoHideDuration,
    };
    const addEndListener = () => {
        if (closeTriggered) {
            onClose(closeTriggered.event, closeTriggered.reason);
        }
    };
    const toastContent = _jsx(ToastContent, { ...contentProps, ...visibilityProps });
    return isUsedInStack ? (_jsx(Slide, { direction: "left", in: isVisible, addEndListener: addEndListener, children: toastContent })) : (_jsx(Snackbar, { open: isVisible, TransitionComponent: SlideTransition, TransitionProps: { addEndListener }, anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, children: toastContent }));
};
export default Toast;
