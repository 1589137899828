import { jsx as _jsx } from "react/jsx-runtime";
import OptionItem from '@profesia/adamui/components/common/OptionItem';
import useTagFieldContext from '@profesia/adamui/components/common/input/textField/tagField/hooks/useTagFieldContext';
import OptionLabel from '../OptionLabel';
import { FREE_VALUE } from '../consts';
const Option = ({ option, liProps }) => {
    const { onChange, values, hasReachedLimit } = useTagFieldContext();
    const { id, value = '' } = option;
    const onClick = () => {
        if (!hasReachedLimit) {
            onChange([...values, option]);
        }
    };
    const { onClick: _, ...otherLiProps } = liProps ?? {};
    return (_jsx(OptionItem, { divider: true, id: !id ? FREE_VALUE : undefined, value: id, disabled: hasReachedLimit, onClick: onClick, ...otherLiProps, children: _jsx(OptionLabel, { label: value, isHighlightingEnabled: Boolean(id) }) }));
};
export default Option;
