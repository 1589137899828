import { jsx as _jsx } from "react/jsx-runtime";
import { styled, alpha } from '@mui/material';
import { getColor } from '@profesia/adamui/styles/colors';
import { DislikeIcon } from '@profesia/adamui/components/common/icons';
import ButtonUniversal from './ButtonUniversal';
const Button = styled(ButtonUniversal)(({ theme: { palette: { secondaryRed }, spacing, }, }) => {
    const color = getColor(secondaryRed);
    return {
        color,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: spacing(0.5),
        height: '40px',
        width: '40px',
        minWidth: 'auto',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: alpha(color, 0.35),
        backgroundColor: alpha(color, 0.1),
        '&:hover': {
            backgroundColor: alpha(color, 0.15),
        },
        '&:focus': {
            backgroundColor: alpha(color, 0.2),
        },
    };
});
const ButtonReject = ({ ...props }) => {
    return (_jsx(Button, { ...props, children: _jsx(DislikeIcon, {}) }));
};
export default ButtonReject;
