import { styled } from '@mui/material';
import { Typography } from '@profesia/adamui/components/common/typography';
import { getColor } from '@profesia/adamui/styles/colors';
const Item = styled(Typography)(({ theme: { spacing, palette } }) => ({
    width: 104,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    gap: spacing(1),
    paddingLeft: spacing(1.5),
    boxSizing: 'border-box',
    '&.Mui-active': {
        backgroundColor: getColor(palette.white),
        borderRadius: 6,
        boxShadow: '0px 2px 4px 0px #00000026',
    },
}));
Item.defaultProps = {
    component: 'div',
    weight: 'bold',
    color: 'primaryNavy',
};
export default Item;
