export const getPlaceholder = (hasReachedLimit, showPlaceholder, internalValueLength, placeholder) => !hasReachedLimit && showPlaceholder
    ? placeholder?.[internalValueLength ? 'filled' : 'empty']
    : undefined;
export const getSizeClass = (size) => `MuiAutocomplete-${size}`;
export const getTagSize = (size) => (size === 'medium' ? 'small' : 'medium');
export const getMaxTagWidth = (index, tagsLimit, truncatedTagWidth, displayUnwrapped) => {
    if (displayUnwrapped)
        return '100%';
    return index === tagsLimit - 1 && truncatedTagWidth ? truncatedTagWidth : undefined;
};
