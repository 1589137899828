import { useMemo } from 'react';
import { useMapsLibrary } from '@vis.gl/react-google-maps';
export const useAutocompleteService = () => {
    const places = useMapsLibrary('places');
    return useMemo(() => {
        if (places) {
            return new google.maps.places.AutocompleteService();
        }
        return null;
    }, [places]);
};
