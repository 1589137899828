import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled, TextareaAutosize } from '@mui/material';
import { getColor } from '@profesia/adamui/styles/colors';
import { InputLabel } from '@profesia/adamui/components/common/input/index';
import ValidationMessage from '@profesia/adamui/components/common/input/ValidationMessage';
import { fontFamily } from '@profesia/adamui/styles/fonts';
const Textarea = ({ label, required, onChange, errorMessage, ...otherProps }) => {
    const hasError = Boolean(errorMessage);
    return (_jsxs(InputLabel, { required: required, label: label, hasError: hasError, children: [_jsx(TextareaStyled, { onChange: (e) => {
                    onChange(e.target.value);
                }, hasError: hasError, ...otherProps }), _jsx(ValidationMessage, { mt: 0.5, children: errorMessage })] }));
};
const TextareaStyled = styled(TextareaAutosize, {
    shouldForwardProp: (prop) => !['minHeight', 'hasError'].includes(prop),
})(({ hasError, minHeight, theme: { palette, spacing } }) => {
    const defaultBorderColor = getColor(hasError ? palette.secondaryRed : palette.black5);
    const activeBorderColor = getColor(hasError ? palette.secondaryRed : palette.primaryBlue);
    return {
        borderRadius: '4px',
        fontFamily: fontFamily,
        borderColor: defaultBorderColor,
        borderWidth: '1px',
        borderStyle: 'solid',
        resize: 'vertical',
        padding: spacing(1, 1.5),
        minHeight: minHeight ? minHeight - 16 : undefined,
        color: getColor(palette.primaryNavy),
        lineHeight: '20px',
        fontSize: '14px',
        '&::placeholder': {
            color: getColor(palette.black3),
        },
        '&:focus': {
            outline: 0,
            border: `1px solid ${activeBorderColor}`,
            boxShadow: '0px 0px 0px 2px rgba(39, 116, 240, 0.15)',
        },
        '&:hover': {
            borderColor: activeBorderColor,
        },
    };
});
export default Textarea;
