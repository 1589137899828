import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, styled } from '@mui/material';
import { tabletDown } from '@profesia/adamui/styles/theme';
import { Currency, Period, Fixed } from '../../form';
const FixedContent = () => (_jsxs(StyledStack, { children: [_jsx(Fixed, {}), _jsxs(Stack, { flexDirection: "row", gap: 1, children: [_jsx(Currency, {}), _jsx(Period, {})] })] }));
const StyledStack = styled(Stack)(({ theme: { spacing } }) => ({
    flexDirection: 'row',
    gap: spacing(1),
    marginBottom: spacing(0.5),
    paddingTop: spacing(1.5),
    [tabletDown]: {
        flexDirection: 'column',
    },
}));
export default FixedContent;
