import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import createSvgIcon from '@profesia/adamui/components/common/icons/createSvgIcon';
import { getColor, palette } from '@profesia/adamui/styles/colors';
const path = (_jsxs(_Fragment, { children: [_jsx("path", { d: "M21.6 18.1L14.4 4.40002C14.2 4.00002 13.8 3.60002 13.4 3.40002C12.6 2.90002 11.5 2.90002 10.6 3.40002C10.2 3.70002 9.79998 4.00002 9.59998 4.40002L2.39998 18.1C2.19998 18.5 2.09998 19 2.09998 19.4C2.09998 19.8 2.19998 20.3 2.49998 20.7C2.69998 21.1 3.09998 21.4 3.49998 21.7C3.89998 21.9 4.29998 22 4.79998 22H19.2C19.7 22 20.1 21.9 20.5 21.7C20.9 21.5 21.2 21.1 21.5 20.8C21.7 20.4 21.9 20 21.9 19.5C21.9 18.9 21.8 18.5 21.6 18.1ZM19.8 19.7C19.7 19.8 19.7 19.9 19.6 19.9C19.4 20 19.3 20 19.2 20H4.79998C4.69998 20 4.59998 20 4.49998 19.9C4.39998 19.8 4.29998 19.8 4.29998 19.7C4.19998 19.6 4.19998 19.5 4.19998 19.4C4.19998 19.3 4.19998 19.2 4.29998 19.1L11.5 5.50002C11.6 5.40002 11.6 5.30002 11.8 5.20002C12 5.10002 12.3 5.10002 12.5 5.20002C12.6 5.30002 12.7 5.40002 12.8 5.50002L19.8 19C19.9 19.1 19.9 19.2 19.9 19.3C19.9 19.5 19.9 19.6 19.8 19.7Z" }), _jsx("path", { d: "M13 8.69995H11V15.6H13V8.69995Z" }), _jsx("path", { d: "M12 18.6C12.7 18.6 13.2 18.1 13.2 17.4C13.2 16.7 12.7 16.2 12 16.2C11.3 16.2 10.8 16.7 10.8 17.4C10.8 18 11.3 18.6 12 18.6Z" })] }));
const SimpleWarning = ({ color = 'primaryNavy', ...props }) => {
    const Icon = createSvgIcon(_jsx(React.Fragment, { children: path }), 'Warning', {
        fill: getColor(palette[color]),
        width: 24,
        height: 24,
        viewBox: '0 0 24 24',
    });
    return _jsx(Icon, { ...props });
};
export default SimpleWarning;
