import { jsx as _jsx } from "react/jsx-runtime";
import { Select, styled } from '@mui/material';
import { getColor } from '@profesia/adamui/styles/colors';
import { getOption } from '@profesia/adamui/components/common/dropdown/helpers';
const DropdownLink = ({ options, ...otherProps }) => {
    return (_jsx(DropdownSelect, { ...otherProps, children: options.map((option, index) => getOption(option, index)) }));
};
const DropdownSelect = styled(Select)(({ theme: { palette, typography, spacing } }) => {
    return {
        height: 21,
        borderRadius: 0,
        borderBottomStyle: 'dashed',
        borderWidth: 1,
        '&:hover': {
            borderBottomStyle: 'solid',
        },
        '.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input': {
            padding: spacing(0, 1.75, 0, 0),
            fontSize: typography.body2.fontSize,
            fontWeight: 'bold',
        },
        '.MuiSvgIcon-root': {
            color: getColor(palette.primaryGray),
            right: -3,
        },
        '.MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: 0,
            borderBottomWidth: 1,
            borderColor: getColor(palette.primaryNavy),
        },
    };
});
export default DropdownLink;
