import { useFormContext, useWatch } from 'react-hook-form';
;
import { FORM_SALARY, FORM_SALARY_TYPE, } from '@profesia/mark-offer-form/components/content/form/valuePaths';
import { defaultSalaryByType } from '@profesia/mark-offer-form/components/content/form/defaultValues';
const useHandleSalaryType = () => {
    const { control, setValue } = useFormContext();
    const type = useWatch({ name: FORM_SALARY_TYPE, control });
    return [
        type,
        (newType) => {
            if (type !== newType) {
                setValue(FORM_SALARY, defaultSalaryByType[newType]);
            }
        },
    ];
};
export default useHandleSalaryType;
