import { GetContactPersonsResponse } from '@profesia/mark-offer-form/services';
import apiRequest from './apiRequest';
import { ContactPersonsApiResponse } from './types';
import { mapContactPersons } from './mapContactPersons';

const getContactPersons = async (companyId: number): Promise<GetContactPersonsResponse> => {
    const jsonResponse = await apiRequest<ContactPersonsApiResponse>(`company/${companyId}/users`, {
        method: 'GET',
    });

    return mapContactPersons(jsonResponse);
};

export default getContactPersons;
