import { Stack as StackUI, styled } from '@mui/material';
import { InputField } from '@profesia/adamui/components/common/input';
import { tabletDown } from '@profesia/adamui/styles/theme';
export const Input = styled(InputField)(({ value }) => ({
    input: {
        fontWeight: Boolean(value) ? 700 : 400,
        lineHeight: 20,
        fontSize: 18,
        '&::placeholder': {
            fontSize: '18px !important',
        },
    },
}));
export const Stack = styled(StackUI, {
    shouldForwardProp: (prop) => prop !== 'isAiActive',
})(({ isAiActive }) => ({
    '& > div:nth-of-type(1)': {
        width: '100%',
    },
    [tabletDown]: {
        '& > div:nth-of-type(1)': {
            width: isAiActive ? 'calc(100% - 56px)' : '100%',
        },
    },
}));
