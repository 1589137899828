import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import createSvgIcon from '@profesia/adamui/components/common/icons/createSvgIcon';
import { getColor, palette } from '@profesia/adamui/styles/colors';
const mappedIconTypes = {
    small: {
        size: 16,
        path: (_jsx("path", { d: "M12.7193 3.29279C12.3288 2.9024 11.6958 2.9024 11.3053 3.29279L8.01231 6.58579L4.71931 3.29279C4.32693 2.91381 3.70323 2.91923 3.31749 3.30497C2.93176 3.6907 2.92634 4.31441 3.30531 4.70679L6.59831 7.99979L3.30531 11.2928C3.04536 11.5439 2.9411 11.9157 3.03262 12.2653C3.12413 12.6149 3.39718 12.888 3.74681 12.9795C4.09644 13.071 4.46824 12.9667 4.71931 12.7068L8.01231 9.41379L11.3053 12.7068C11.6977 13.0858 12.3214 13.0803 12.7071 12.6946C13.0929 12.3089 13.0983 11.6852 12.7193 11.2928L9.42631 7.99979L12.7193 4.70679C13.1097 4.31629 13.1097 3.68329 12.7193 3.29279Z" })),
    },
    regular: {
        size: 24,
        path: (_jsxs(_Fragment, { children: [_jsx("path", { d: "M20.25 3.75L3.75 20.25", stroke: "#384B69", strokeWidth: "2", strokeLinecap: "square" }), _jsx("path", { d: "M20 20L4 4", stroke: "#384B69", strokeWidth: "2", strokeLinecap: "square" })] })),
    },
    large: {
        size: 32,
        path: (_jsxs(_Fragment, { children: [_jsx("path", { d: "M27 5L5 27", stroke: "#2774F0", strokeWidth: "2", strokeLinecap: "square" }), _jsx("path", { d: "M27 27L5 5", stroke: "#2774F0", strokeWidth: "2", strokeLinecap: "square" })] })),
    },
};
const Close = ({ type = 'small', color = getColor(palette.neutralDark3), ...props }) => {
    const { size, path } = mappedIconTypes[type];
    const Icon = createSvgIcon(_jsx(React.Fragment, { children: path }), 'Close', {
        width: size,
        viewBox: `0 0 ${size} ${size}`,
        fill: color,
    });
    return _jsx(Icon, { ...props });
};
export default Close;
