import { alpha, FormControlLabel as MuiFormControlLabel, styled } from '@mui/material';
import { SHOW_ON_FOCUS } from '@profesia/adamui/components/common/icons';
import { getColor } from '@profesia/adamui/styles/colors';
import { MUI_ERROR_CLASS } from '@profesia/adamui/styles/classes';
const FormControlLabel = styled(MuiFormControlLabel)(({ theme: { spacing, palette: { neutralDark4, primary, secondaryRed }, }, }) => ({
    minHeight: 28,
    [`.${SHOW_ON_FOCUS}`]: {
        display: 'none',
    },
    '&.MuiFormControlLabel-root': {
        '&:hover .MuiCheckbox-root': {
            '&, &:not(.Mui-disabled)': {
                boxShadow: 'none',
            },
        },
        '&:focus, &:focus-visible, &.Mui-focused': {
            [`.${SHOW_ON_FOCUS}`]: {
                display: 'block',
            },
        },
        '&.MuiFormControlLabel-checkboxHighlighted': {
            borderRadius: 4,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: alpha(getColor(neutralDark4), 0.4),
            padding: spacing(0.625, 2),
            [`&.${MUI_ERROR_CLASS}`]: {
                borderColor: getColor(secondaryRed),
            },
            '&:hover': {
                borderColor: alpha(getColor(neutralDark4), 0.8),
            },
            '&:focus, &:focus-visible, &.Mui-focused': {
                borderColor: alpha(getColor(neutralDark4), 0.4),
                outline: 'none',
            },
            '&:has(.Mui-checked)': {
                backgroundColor: alpha(getColor(primary), 0.08),
                borderColor: getColor(primary),
                '&:hover': {
                    backgroundColor: alpha(getColor(primary), 0.16),
                },
                '&:focus, &:focus-visible, &.Mui-focused': {
                    backgroundColor: alpha(getColor(primary), 0.08),
                },
            },
        },
    },
    '.MuiCheckbox-root': {
        margin: spacing(0.5, 1, 0.75, 0),
    },
    '.MuiTypography-root': {
        margin: spacing(0.5, 0),
    },
}));
export default FormControlLabel;
