import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { alpha, Box, Stack, styled } from '@mui/material';
import { getColor } from '@profesia/adamui/styles/colors';
import ValidationMessage from '@profesia/adamui/components/common/input/ValidationMessage';
import { TypographySpan } from '@profesia/adamui/components/common/typography';
import { SelectDefault } from '@profesia/adamui/components/common/dropdown';
import { CloseIcon } from '@profesia/adamui/components/common/icons';
import { chainClassNames } from '@profesia/adamui/styles/classes';
const CardSkill = ({ Icon, label, placeholder, onRemove, errorMessage, level, levelOptions, onLevelChange, required, className, }) => {
    const [validationMessage, hasError] = errorMessage
        ? [_jsx(ValidationMessage, { children: errorMessage }), true]
        : [null, false];
    const icon = Icon ? _jsx(Icon, {}) : null;
    return (_jsxs(Stack, { gap: 0.5, children: [_jsxs(SkillContainer, { className: chainClassNames(['Skill-container', className]), children: [_jsxs(SkillInnerContainer, { gap: 1.5, children: [icon, _jsx(TypographySpan, { alignContent: "center", weight: hasError ? 'bold' : undefined, color: hasError ? 'secondaryRed' : 'black0', children: label })] }), _jsxs(SkillInnerContainer, { gap: 2, justifyContent: "end", children: [_jsx(SelectDefault, { placeholder: placeholder, value: level, options: levelOptions, onChange: onLevelChange, requiredPlaceholder: required, hasError: hasError }), _jsx(CloseIcon, { onClick: onRemove })] })] }), validationMessage] }));
};
const SkillContainer = styled(Box)(({ theme: { spacing, palette: { primary }, }, }) => ({
    display: 'flex',
    padding: spacing(2),
    borderRadius: 4,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: getColor(primary),
    backgroundColor: alpha(getColor(primary), 0.08),
}));
const SkillInnerContainer = styled(Box)(() => ({
    display: 'flex',
    flexGrow: 1,
    '.MuiFormControl-root': {
        maxWidth: 240,
    },
}));
export default CardSkill;
