import { jsx as _jsx } from "react/jsx-runtime";
import { IconButton, Tooltip as MuiTooltip, styled, tooltipClasses, } from '@mui/material';
import { Typography } from '@profesia/adamui/components/common/typography';
import { getColor } from '@profesia/adamui/styles/colors';
import getTypographyProps from './getTypographyProps';
const openControlProps = {
    disableFocusListener: true,
    disableHoverListener: true,
    disableTouchListener: true,
};
export const TOOLTIP_ICON_BUTTON = 'tooltip-icon-button';
const Tooltip = ({ children, icon, variant, tooltipContent, placement = 'left', delay, open, PopperProps, ...otherProps }) => {
    const typographyProps = getTypographyProps(variant);
    return (_jsx(StyledTooltip, { title: _jsx(Typography, { ...typographyProps, children: tooltipContent }), placement: placement, enterDelay: delay, enterNextDelay: delay, PopperProps: PopperProps, open: open, ...(open === undefined ? {} : openControlProps), ...otherProps, children: icon ? (_jsx(IconContainer, { "data-testid": TOOLTIP_ICON_BUTTON, children: icon })) : (children) }));
};
const StyledTooltip = styled(({ className, children, ...props }) => (_jsx(MuiTooltip, { ...props, classes: { popper: className }, children: children })), {
    shouldForwardProp: (propName) => !['backgroundColor', 'pb', 'pt'].includes(propName),
})(({ theme: { palette, spacing }, backgroundColor, pb = 1, pt = 1 }) => {
    const marginLeftOrRight = `${spacing(0.5)} !important`;
    return {
        [`& .${tooltipClasses.tooltip}`]: {
            display: 'flex',
            backgroundColor: getColor(backgroundColor ?? palette.neutralLight0),
            borderWidth: 1,
            borderRadius: 4,
            borderStyle: 'solid',
            borderColor: getColor(palette.neutralLight4),
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.06)',
            padding: spacing(pt, 1.5, pb),
            margin: spacing(0),
        },
        [`& .${tooltipClasses.tooltipPlacementLeft}`]: {
            marginRight: marginLeftOrRight,
        },
        [`& .${tooltipClasses.tooltipPlacementRight}`]: {
            marginLeft: marginLeftOrRight,
        },
    };
});
const IconContainer = styled(IconButton)(() => ({
    padding: 0,
    '&:hover': {
        background: 'transparent',
    },
}));
export default Tooltip;
