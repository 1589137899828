import { Autocomplete, styled } from '@mui/material';
const BaseAutocomplete = styled(Autocomplete)(() => ({
    width: '100%',
    //TODO upravit v tasku CVDB-255
    '.MuiAutocomplete-tag': {
        margin: 0,
    },
    '& .MuiAutocomplete-clearIndicator': {
        display: 'none !important',
    },
}));
export default BaseAutocomplete;
