import { jsx as _jsx } from "react/jsx-runtime";
import { useFormContext, useWatch } from 'react-hook-form';
import { useGetEmploymentTypesOptions } from '@profesia/mark-offer-form/codebooks';
import { useGetFixedT } from '@profesia/mark-offer-form/i18n';
import { CheckboxGroup } from '@profesia/adamui/components/common/input';
import { FORM_EMPLOYMENT_TYPES } from '@profesia/mark-offer-form/components/content/form/valuePaths';
const EmploymentType = ({ errorMessage }) => {
    const t = useGetFixedT('positionDescription.employmentType');
    const employmentTypeOptions = useGetEmploymentTypesOptions();
    const { setValue, control } = useFormContext();
    const checkedValues = useWatch({ name: FORM_EMPLOYMENT_TYPES, control });
    return (_jsx(CheckboxGroup, { required: true, variant: "highlighted", direction: "row", label: t('label'), options: employmentTypeOptions, onChange: (checkedValues) => {
            setValue(FORM_EMPLOYMENT_TYPES, checkedValues);
        }, checkedValues: checkedValues, errorMessage: errorMessage }));
};
export default EmploymentType;
