import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled } from '@mui/material';
import { getColor } from '@profesia/adamui/styles/colors';
import { HelpingWithHeartIcon, RemoveIcon, UAFlagIcon, } from '@profesia/adamui/components/common/icons';
import ButtonUniversal from '@profesia/adamui/components/common/button/ButtonUniversal';
const mappedSizes = {
    small: '32px',
    standard: '48px',
};
const AvatarBox = styled(ButtonUniversal, {
    shouldForwardProp: (prop) => ![
        'size',
        'iconWidth',
        'isSelected',
        'showDefaultBorder',
        'showRemoveIconOnHoverOnly',
    ].includes(prop),
})(({ size, isSelected = false, showDefaultBorder = true, showRemoveIconOnHoverOnly = false, theme, }) => ({
    position: 'relative',
    width: mappedSizes[size],
    height: mappedSizes[size],
    minWidth: 'unset',
    borderWidth: `${isSelected ? 2 : showDefaultBorder ? 1 : 0}px`,
    borderRadius: '50%',
    borderStyle: 'solid',
    borderColor: getColor(theme.palette[isSelected ? 'primaryBlue' : 'neutralLight3']),
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: getColor(theme.palette.white),
    '& > img, > .MuiSvgIcon-root': {
        margin: 'auto',
    },
    '& .MuiSvgIcon-Remove': {
        display: showRemoveIconOnHoverOnly ? 'none' : 'initial',
        position: 'absolute',
        left: '66.67%',
        right: '0%',
        top: '0%',
        bottom: '66.67%',
    },
    '& .MuiSvgIcon-HelpingWithHeart, & .MuiSvgIcon-UAFlag': {
        position: 'absolute',
        left: '66.67%',
        right: '0%',
        top: '66.67%',
        bottom: '0%',
    },
    '&.MuiButton-root:hover': {
        backgroundColor: getColor(theme.palette.white),
        '& .MuiSvgIcon-Remove': {
            display: 'initial',
        },
    },
}));
const AvatarContainer = ({ size, hasInvalidity = false, isRefugee = false, onRemove, children, ...avatarBoxProps }) => {
    let removeIcon = null;
    let stateIcon = null;
    if (size === 'standard') {
        removeIcon = onRemove ? _jsx(RemoveIcon, { onClick: onRemove }) : null;
        stateIcon = hasInvalidity ? _jsx(HelpingWithHeartIcon, {}) : isRefugee ? _jsx(UAFlagIcon, {}) : null;
    }
    return (_jsxs(AvatarBox, { size: size, ...avatarBoxProps, children: [children, stateIcon, removeIcon] }));
};
export default AvatarContainer;
