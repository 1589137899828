import { jsx as _jsx } from "react/jsx-runtime";
import AvatarContainer from '@profesia/adamui/components/common/Avatar/AvatarContainer';
import Initials from '@profesia/adamui/components/common/Avatar/AvatarContainer/Initials';
const mappedFontSizes = {
    small: 0.875,
    standard: 1.25,
};
const AvatarInitials = ({ name, size = 'standard', ...otherProps }) => {
    return (_jsx(AvatarContainer, { size: size, ...otherProps, children: _jsx(Initials, { name: name, fontSize: mappedFontSizes[size] }) }));
};
export default AvatarInitials;
