const getHighlightedText = (input, label) => {
    const inputLowerCase = input.toLowerCase();
    const labelLowerCase = label.toLowerCase();
    const startIndex = labelLowerCase.indexOf(inputLowerCase);
    return startIndex !== -1
        ? [
            label.slice(0, startIndex),
            label.slice(startIndex, startIndex + input.length),
            label.slice(startIndex + input.length),
        ]
        : [];
};
export default getHighlightedText;
