import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack } from '@mui/material';
import { Typography } from '@profesia/adamui/components/common/typography';
import { useTranslationInPageLanguage } from '@profesia/mark-offer-form/i18n';
import { AiLoading } from '@profesia/mark-offer-form/components/icons';
const Loading = () => {
    const t = useTranslationInPageLanguage('jobTitle.aiModal');
    return (_jsxs(Stack, { p: 5, mt: 10, flexDirection: "column", alignItems: "center", children: [_jsx(AiLoading, {}), _jsx(Typography, { color: "primaryNavy", variant: "h3Medium", children: t('loading') })] }));
};
export default Loading;
