import { jsx as _jsx } from "react/jsx-runtime";
import { Gender } from '@profesia/adamui/components/common/types';
import AvatarGender from './variant/AvatarGender';
import AvatarPhoto from './variant/AvatarPhoto';
import AvatarInitials from './variant/AvatarInitials';
const Avatar = ({ candidate: { photo, gender, fullName, hasInvalidity, isRefugee }, ...otherProps }) => {
    const commonAvatarProps = {
        hasInvalidity,
        isRefugee,
        ...(otherProps ?? {}),
    };
    if (photo) {
        return _jsx(AvatarPhoto, { src: photo.thumbnailUrl, ...commonAvatarProps });
    }
    if (gender) {
        return _jsx(AvatarGender, { gender: gender, ...commonAvatarProps });
    }
    if (fullName) {
        return _jsx(AvatarInitials, { name: fullName, ...commonAvatarProps });
    }
    return _jsx(AvatarGender, { gender: Gender.Hidden, ...commonAvatarProps });
};
export default Avatar;
