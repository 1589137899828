import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { FORM_POSITION_PLACE_ADDRESS, FORM_POSITION_PLACE_TYPE, } from '@profesia/mark-offer-form/components/content/form/valuePaths';
import { PositionPlaceTypeId, scrollToFirstError, } from '@profesia/mark-offer-form/components/content/form';
import { SCROLL_TO_ERROR_CLASS } from '@profesia/mark-offer-form/styles/classes';
const useHandleError = () => {
    const { formState: { errors }, setValue, trigger, } = useFormContext();
    const shouldScrollOnNextRerender = useRef(false);
    const placeTypeError = errors.positionPlace?.type;
    const addressError = errors.positionPlace?.address;
    const regionError = errors.positionPlace?.regions;
    const errorClass = placeTypeError || addressError || regionError ? SCROLL_TO_ERROR_CLASS : undefined;
    useEffect(() => {
        if (placeTypeError) {
            setValue(FORM_POSITION_PLACE_TYPE, PositionPlaceTypeId.Onsite);
            trigger(FORM_POSITION_PLACE_ADDRESS);
            shouldScrollOnNextRerender.current = true;
            return;
        }
        if (addressError && !placeTypeError && shouldScrollOnNextRerender.current) {
            shouldScrollOnNextRerender.current = false;
            setTimeout(scrollToFirstError, 0);
        }
    }, [placeTypeError, addressError]);
    return errorClass;
};
export default useHandleError;
