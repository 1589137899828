import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useMemo } from 'react';
import { Select as MuiSelect, styled, FormHelperText, FormControl as MuiFormControl, Box, } from '@mui/material';
import { isEqual } from 'lodash';
import { getColor } from '@profesia/adamui/styles/colors';
import theme from '@profesia/adamui/styles/theme';
import Label from '@profesia/adamui/components/common/Label';
import { TypographySpan } from '@profesia/adamui/components/common/typography';
import { ValidationMessage } from '../../input';
import { getOption } from '../helpers';
import { useSelectState } from './useSelectState';
import ValueWithIcon from './ValueWithIcon';
function SelectDefault({ useCheckbox = false, variant = 'light', size = 'medium', placeholder, labelPlacementLeft, optionalLabel, label, hasError, helperText, value, options, onChange = () => { }, required, requiredPlaceholder, errorMessage, ...otherProps }) {
    const ref = useRef(null);
    const propsRef = useRef();
    const selectOptions = useMemo(() => {
        if ((useCheckbox && propsRef.current?.value !== value) ||
            !isEqual(propsRef.current?.options, options)) {
            const mappedOptions = options.map((option, index) => getOption(option, index, { useCheckbox, value: value }));
            propsRef.current = { options, mappedOptions, value };
            return mappedOptions;
        }
        return propsRef.current?.mappedOptions;
    }, [options, value]);
    const { renderTags } = useSelectState(ref, options, value, placeholder);
    return (_jsxs(FormControl, { labelPlacementLeft: labelPlacementLeft, error: hasError, children: [label ? (_jsx(Box, { ...(labelPlacementLeft ? { mb: 0, mr: 3.625 } : { mb: 0.5, mr: 0 }), children: _jsx(Label, { optionalLabel: optionalLabel, required: required, className: "select-mobile-hidden", children: label }) })) : null, _jsx(Select, { ref: ref, className: `MuiSelect-${variant} MuiSelect-${size}`, MenuProps: { PaperProps: { className: 'MuiPaper-select' } }, displayEmpty: Boolean(placeholder), value: value, SelectDisplayProps: {
                    className: Array.isArray(value) && value.length > 0
                        ? 'MuiSelect-overridePaddingLeft'
                        : '',
                }, renderValue: (values) => {
                    if (placeholder &&
                        ((Array.isArray(values) && values?.length === 0) || !values)) {
                        return requiredPlaceholder ? (_jsx(RequiredLabel, { required: requiredPlaceholder, children: placeholder })) : (_jsx(TypographySpan, { color: "black3", children: placeholder }));
                    }
                    if (useCheckbox) {
                        return renderTags(values);
                    }
                    return _jsx(ValueWithIcon, { value: values, options: options });
                }, onChange: ({ target: { value: newValue } }) => onChange((typeof value === 'number' ? +newValue : newValue)), ...otherProps, children: selectOptions }), helperText ? _jsx(FormHelperText, { children: helperText }) : null, errorMessage && _jsx(ValidationMessage, { mt: 0.5, children: errorMessage })] }));
}
const Select = styled(MuiSelect)(({ theme: { palette } }) => ({
    width: '100%',
    '&:not(.Mui-disabled):hover': {
        'fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: getColor(palette.primaryBlue),
        },
    },
    '&.Mui-focused': {
        boxShadow: '0px 0px 0px 2px rgba(39, 116, 240, 0.15)',
    },
    'fieldset.MuiOutlinedInput-notchedOutline': {
        borderWidth: '1px!important',
        borderColor: getColor(palette.neutralLight5),
    },
    '.MuiSvgIcon-root': {
        color: getColor(palette.primaryGray),
        right: theme.spacing(1),
    },
    '&.MuiSelect-light': {
        backgroundColor: getColor(palette.white),
    },
    '&.MuiSelect-dark': {
        backgroundColor: getColor(palette.neutralLight0),
    },
    '&.MuiSelect-medium': {
        height: 32,
    },
    '&.MuiSelect-large': {
        height: 36,
    },
}));
const FormControl = styled(MuiFormControl, {
    shouldForwardProp: (prop) => prop !== 'labelPlacementLeft',
})(({ labelPlacementLeft }) => {
    return {
        display: 'flex',
        ...(labelPlacementLeft
            ? { alignItems: 'center', flexDirection: 'row' }
            : { alignItems: 'space-between', flexDirection: 'column' }),
        '.MuiInputLabel-root': {
            position: 'relative',
            transform: 'none',
        },
    };
});
const RequiredLabel = styled(Label)(({ theme: { palette } }) => ({
    color: getColor(palette.primaryNavy),
    fontWeight: 400,
    '.MuiInputLabel-asterisk': {
        fontWeight: 700,
    },
}));
export default SelectDefault;
