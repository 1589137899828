import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Paper, Stack as MuiStack, styled } from '@mui/material';
import { getColor } from '@profesia/adamui/styles/colors';
import { Typography } from '@profesia/adamui/components/common/typography';
import { NotificationInfoIcon, NotificationWarningIcon, NotificationErrorIcon, NotificationGiftIcon, NotificationInfoNoTitleIcon, } from '@profesia/adamui/components/common/icons';
const mappedIcons = {
    info: _jsx(NotificationInfoIcon, {}),
    warning: _jsx(NotificationWarningIcon, {}),
    error: _jsx(NotificationErrorIcon, {}),
    gift: _jsx(NotificationGiftIcon, {}),
    infoNoTitle: _jsx(NotificationInfoNoTitleIcon, {}),
};
const CardNotificationContainer = styled(Paper)(({ theme: { palette, spacing } }) => ({
    display: 'flex',
    gap: spacing(1.5),
    backgroundColor: getColor(palette.neutralLight0),
    padding: spacing(1.375, 4, 1.375, 2),
    borderWidth: 1,
    borderStyle: 'solid',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.06)',
    '& .MuiSvgIcon-root': {
        marginBottom: 'auto',
    },
    '&.MuiPaper-info': {
        borderColor: getColor(palette.secondaryGreen),
    },
    '&.MuiPaper-warning': {
        borderColor: getColor(palette.secondaryTiger),
    },
    '&.Mui-error': {
        borderColor: getColor(palette.secondaryRed),
    },
    '&.MuiPaper-gift': {
        borderColor: getColor(palette.primaryBlue),
        paddingBottom: spacing(1.125),
        '& > div': {
            paddingTop: spacing(0.5),
        },
    },
    '&.MuiPaper-infoNoTitle': {
        borderColor: getColor(palette.secondaryGreen),
        gap: spacing(1),
        padding: spacing(1.125, 1.875, 1.125, 1.375),
    },
}));
const Stack = styled(MuiStack)(() => ({
    justifyContent: 'center',
}));
const CardNotification = ({ variant, title, description, longText }) => {
    const textColor = 'primaryNavy';
    /*
        @TODO pretypovanie as PaperProps['variant'] je len tmp riesenie.
        Problem je, ze to berie inu augmentaciu modulov nez by malo a TS si stazuje, ze variant nie je kompatibilny
    */
    return (_jsx(CardNotificationContainer, { variant: variant, children: _jsxs(_Fragment, { children: [mappedIcons[variant], _jsxs(Stack, { children: [_jsx(Typography, { variant: "h4", color: textColor, children: title }), _jsx(Typography, { variant: "small", color: textColor, children: description }), longText ? (_jsx(Typography, { color: textColor, mt: 2, children: longText })) : null] })] }) }));
};
export default CardNotification;
