import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Paper, Stack, styled } from '@mui/material';
import { SmartLink } from '@profesia/adamui/components/common/Link';
import { getColor } from '@profesia/adamui/styles/colors';
import { CheckmarkIcon, WarningIcon } from '@profesia/adamui/components/common/icons';
import { TypographySpan } from '@profesia/adamui/components/common/typography';
const mappedIcons = {
    available: _jsx(CheckmarkIcon, {}),
    unavailable: _jsx(WarningIcon, {}),
};
const CardCreditStatus = ({ variant, remainingCredits, creditsExpiration, actionLabel, to, onClick, href, dataDy, }) => {
    const commonActionProps = { to, onClick, href, 'data-dy': dataDy };
    return (_jsxs(CreditStatusContainerPaper, { className: variant, ...commonActionProps, children: [_jsx(Box, { display: "flex", gap: 0.5, children: _jsxs(_Fragment, { children: [mappedIcons[variant], _jsxs(Stack, { my: "auto", children: [_jsx(TypographySpan, { color: "primaryNavy", fontWeight: "medium", children: remainingCredits }), creditsExpiration ? (_jsx(TypographySpan, { color: "secondaryTiger", fontWeight: "medium", children: creditsExpiration })) : null] })] }) }), variant === 'unavailable' && actionLabel ? (_jsx(SmartLink, { ...commonActionProps, typographyProps: { my: 'auto', component: 'span', fontWeight: 'medium' }, children: actionLabel })) : null] }));
};
const CreditStatusContainerPaper = styled(Paper)(({ theme: { palette, spacing } }) => ({
    display: 'flex',
    gap: spacing(2),
    justifyContent: 'space-between',
    borderRadius: 4,
    border: '1px solid',
    padding: spacing(0.5, 2, 0.5, 1.5),
    minHeight: 48,
    boxShadow: 'none',
    width: '100%',
    '& .MuiSvgIcon-root': {
        margin: 'auto 0',
    },
    '&.available': {
        backgroundColor: getColor(palette.neutralLight0),
        borderColor: getColor(palette.secondaryGreen),
    },
    '&.unavailable': {
        backgroundColor: getColor(palette.extendedOchre5),
        borderColor: getColor(palette.extendedOchre2),
    },
}));
export default CardCreditStatus;
