import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { FormFooter } from '@profesia/adamui/components/common/modal';
import { Typography } from '@profesia/adamui/components/common/typography';
import { useTranslationInPageLanguage } from '@profesia/mark-offer-form/i18n';
import StyledModal from '@profesia/mark-offer-form/components/content/form/components/StyledModal';
import ModalWarningNotificationBox from '@profesia/mark-offer-form/components/content/form/components/ModalWarningNotificationBox';
import { FORM_COMPANY_BUSINESS_AREA_ID, FORM_COMPANY_CHARACTERISTICS, } from '@profesia/mark-offer-form/components/content/form/valuePaths';
import Form from '../Form';
const CompanyModal = ({ isOpen, onClose, onSubmit }) => {
    const t = useTranslationInPageLanguage('companyDetails.companyModal');
    const buttonsT = useTranslationInPageLanguage('buttons');
    const { getValues, setValue } = useFormContext();
    const characteristics = getValues(FORM_COMPANY_CHARACTERISTICS);
    const businessAreaId = getValues(FORM_COMPANY_BUSINESS_AREA_ID);
    const form = useForm({
        defaultValues: {
            company: {
                businessAreaId,
                characteristics,
            },
        },
    });
    const { handleSubmit } = form;
    const onFormSubmit = (data) => {
        // @TODO add service with request to save changes
        setValue(FORM_COMPANY_BUSINESS_AREA_ID, data.company.businessAreaId);
        setValue(FORM_COMPANY_CHARACTERISTICS, data.company.characteristics);
        onSubmit();
    };
    return (_jsx(StyledModal, { isOpen: isOpen, onClose: onClose, spacing: 4, header: _jsx(Typography, { variant: "h3Medium", ml: 3, children: t('title') }), content: _jsxs(_Fragment, { children: [_jsx(ModalWarningNotificationBox, { children: t('info.description') }), _jsxs(FormProvider, { ...form, children: [_jsx(Form, {}), _jsx(FormFooter, { leftSideActions: [], rightSideActions: [
                                {
                                    //@TODO implement save changes
                                    onClick: handleSubmit(onFormSubmit),
                                    variant: 'primary',
                                    label: buttonsT('save'),
                                },
                                {
                                    onClick: onClose,
                                    variant: 'secondary',
                                    label: buttonsT('cancel'),
                                },
                            ] })] })] }) }));
};
export default CompanyModal;
