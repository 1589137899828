import { jsx as _jsx } from "react/jsx-runtime";
import { useFormContext, useWatch } from 'react-hook-form';
import { CheckboxGroup } from '@profesia/adamui/components/common/input';
import { useGetFixedT } from '@profesia/mark-offer-form/i18n';
import { FORM_OFFER_SUITABLE_FOR } from '@profesia/mark-offer-form/components/content/form/valuePaths';
import { useGetSuitableForOptions } from '@profesia/mark-offer-form/codebooks';
const OfferSuitableFor = () => {
    const t = useGetFixedT('positionDescription.suitableFor');
    const options = useGetSuitableForOptions();
    const { setValue, control } = useFormContext();
    const checkedValues = useWatch({ name: FORM_OFFER_SUITABLE_FOR, control });
    return (_jsx(CheckboxGroup, { variant: "highlighted", label: t('label'), options: options, onChange: (checkedValues) => {
            setValue(FORM_OFFER_SUITABLE_FOR, checkedValues);
        }, checkedValues: checkedValues, direction: "row" }));
};
export default OfferSuitableFor;
