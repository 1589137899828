import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FormProvider, useForm } from 'react-hook-form';
import { FormFooter } from '@profesia/adamui/components/common/modal';
import { Typography } from '@profesia/adamui/components/common/typography';
import { useTranslationInPageLanguage, useGetFixedT } from '@profesia/mark-offer-form/i18n';
import StyledModal from '@profesia/mark-offer-form/components/content/form/components/StyledModal';
import ModalWarningNotificationBox from '@profesia/mark-offer-form/components/content/form/components/ModalWarningNotificationBox';
import ContactForm from '../ContactForm';
const ContactModal = ({ isOpen, onClose }) => {
    const pageT = useTranslationInPageLanguage('contactDetails.contactModal');
    const buttonsT = useTranslationInPageLanguage('buttons');
    const t = useGetFixedT('contactDetails');
    // @TODO fill the defaultValues with data sent via prop from parent
    const form = useForm({
        defaultValues: {
            contactPerson: {
                academicDegree: '',
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
            },
        },
    });
    const { handleSubmit } = form;
    const onSubmit = (data) => {
        // @TODO pass form data to parent via prop, call request with form data
        console.log('data', data);
    };
    return (_jsx(StyledModal, { isOpen: isOpen, onClose: onClose, spacing: 4, header: _jsx(Typography, { variant: "h3Medium", ml: 3, children: pageT('title') }), content: _jsxs(_Fragment, { children: [_jsx(ModalWarningNotificationBox, { children: t('info.description') }), _jsxs(FormProvider, { ...form, children: [_jsx(ContactForm, {}), _jsx(FormFooter, { leftSideActions: [], rightSideActions: [
                                {
                                    onClick: handleSubmit(onSubmit),
                                    //@TODO implement save changes
                                    variant: 'primary',
                                    label: buttonsT('save'),
                                },
                                {
                                    onClick: onClose,
                                    variant: 'secondary',
                                    label: buttonsT('cancel'),
                                },
                            ] })] })] }) }));
};
export default ContactModal;
