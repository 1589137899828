import { useQuery } from '@tanstack/react-query';
import { getConfiguration } from '@profesia/mark-offer-form/configuration';
import { useGetLocale } from '@profesia/mark-offer-form/i18n';
import { GET_BUSINESS_AREAS, GET_CODEBOOKS } from './keys';
export const useGetCodebooks = () => {
    const { api } = getConfiguration();
    const language = useGetLocale();
    return useQuery({
        queryKey: [GET_CODEBOOKS, language],
        queryFn: async () => api.getCodebooks(language),
    });
};
export const useGetBusinessAreas = () => {
    const { api } = getConfiguration();
    const language = useGetLocale();
    return useQuery({
        queryKey: [GET_BUSINESS_AREAS, language],
        queryFn: async () => api.getBusinessAreas(language),
    });
};
