import { hexToRgb } from '@mui/material';
import { getColor } from '@profesia/adamui/styles/colors';
const getSvgIconClassName = (name) => `MuiSvgIcon-${name}`;
const getSvgIconByName = (name) => getElementsByClassName(getSvgIconClassName(name))[0];
const getCheckbox = (checked) => getCheckboxes(checked)[0];
const getCheckboxes = (checked = false) => Array.from(getElementsByClassName(checked ? 'Mui-checked' : 'MuiCheckbox-root'));
const getElementByClassName = (name) => getElementsByClassName(name)[0];
const getElementsByClassName = (name) => Array.from(document.getElementsByClassName(name));
const getRgbColor = (color) => hexToRgb(getColor(color));
export { getCheckbox, getCheckboxes, getElementByClassName, getElementsByClassName, getSvgIconClassName, getSvgIconByName, getRgbColor, };
