import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useFormContext, useWatch } from 'react-hook-form';
import { Box } from '@mui/material';
import { FORM_POSITION_PLACE_ADDRESS, FORM_POSITION_PLACE_REGIONS, FORM_POSITION_PLACE_TYPE, } from '@profesia/mark-offer-form/components/content/form/valuePaths';
import useGetOptions from './useGetOptions';
import Option from './Option';
const Options = () => {
    const { control, setValue, clearErrors, formState: { errors }, } = useFormContext();
    const selectedType = useWatch({ name: FORM_POSITION_PLACE_TYPE, control });
    const setSelectedType = (type) => setValue(FORM_POSITION_PLACE_TYPE, type);
    const options = useGetOptions();
    const handleOnClick = (type) => {
        if (type !== selectedType) {
            setSelectedType(type);
            clearErrors([
                FORM_POSITION_PLACE_TYPE,
                FORM_POSITION_PLACE_ADDRESS,
                FORM_POSITION_PLACE_REGIONS,
            ]);
        }
    };
    return (_jsx(_Fragment, { children: options.map(({ type, ...option }) => (_jsx(Box, { pb: 0.5, children: _jsx(Option, { errors: errors, isSelected: type === selectedType, onClick: () => handleOnClick(type), ...option }) }, type))) }));
};
export default Options;
