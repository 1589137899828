import { Divider, styled } from '@mui/material';
import { getColor } from '@profesia/adamui/styles/colors';
const HorizontalDivider = styled(Divider)(({ theme: { palette, spacing }, mt, mb }) => ({
    marginTop: spacing(mt ?? 1),
    marginBottom: spacing(mb ?? 1),
    backgroundColor: getColor(palette.neutralLight2),
    backgroundOrigin: 'padding-box',
    height: 1,
    border: 0,
}));
export default HorizontalDivider;
