import { SCROLL_TO_ERROR_CLASS } from '@profesia/mark-offer-form/styles/classes';
export const getError = (error) => error?.message ?? undefined;
export const getErrorAndScrollClass = (error) => {
    const errorMessage = getError(error);
    return errorMessage ? [errorMessage, SCROLL_TO_ERROR_CLASS] : [undefined, undefined];
};
export const getInputError = (error) => error
    ? {
        bottomLabel: getError(error),
        isError: true,
    }
    : {};
