import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormContext } from 'react-hook-form';
import { Stack } from '@mui/material';
import { CardMultipleChoice } from '@profesia/adamui/components/common/card';
import { SCROLL_TO_ERROR_CLASS } from '@profesia/mark-offer-form/styles/classes';
import { useGetFixedT } from '@profesia/mark-offer-form/i18n';
import { ValidationMessage, } from '@profesia/mark-offer-form/components/content/form';
import Description from './Description';
import { useHandleSalaryType } from './form';
import { RangeContent, RangeMeta } from './range';
import { FixedContent, FixedMeta } from './fixed';
const SalaryType = ({ errorMessage }) => {
    const t = useGetFixedT('salaryType');
    const [salaryType, setSalaryType] = useHandleSalaryType();
    const { formState: { errors }, } = useFormContext();
    const getErrorClass = (type) => {
        const { value, from, to } = errors.salary || {};
        return (type === 'fixed' && value) || (type === 'range' && (from || to))
            ? SCROLL_TO_ERROR_CLASS
            : undefined;
    };
    return (_jsxs(Stack, { gap: 0.5, children: [_jsx(Stack, { gap: 1, children: content.map(({ type, Content, description, Meta }) => {
                    const isSelected = salaryType === type;
                    return (_jsx(CardMultipleChoice, { item: t(`${type}.title`), isSelected: isSelected, onClick: () => setSalaryType(type), description: description, meta: _jsx(Meta, { isSelected: isSelected }), hasError: Boolean(errorMessage), className: getErrorClass(type), children: _jsx(Content, {}) }, type));
                }) }), _jsx(ValidationMessage, { error: errorMessage })] }));
};
const content = [
    {
        type: 'fixed',
        Content: FixedContent,
        description: _jsx(Description, { type: "fixed" }),
        Meta: FixedMeta,
    },
    {
        type: 'range',
        Content: RangeContent,
        description: _jsx(Description, { type: "range" }),
        Meta: RangeMeta,
    },
];
export default SalaryType;
