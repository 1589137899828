import { Dispatch, Action } from 'redux';
import { FormT } from '@profesia/mark-offer-form/components/content/form/types';
import { mapFormToPostData } from './mapFormToPostData';
import {
    UPDATE_POSITION_DATA,
    UPDATE_POSITION_SUCCESS,
    UPDATE_POSITION_FAILURE,
} from '../../../actions/CreateJobActions';
import apiRequest from './apiRequest';

const updateForm = async (
    data: FormT,
    id: number,
    dispatch: Dispatch<Action>
): Promise<number> => {
    const transformedData = mapFormToPostData(data);
    transformedData.id = id;

    dispatch({ type: UPDATE_POSITION_DATA });

    try {
        const jsonResponse = await apiRequest<{ id: number }>('position', {
            method: 'PUT',
            body: JSON.stringify(transformedData),
        });

        dispatch({ type: UPDATE_POSITION_SUCCESS, payload: jsonResponse });

        return jsonResponse.id;
    } catch (error) {
        dispatch({ type: UPDATE_POSITION_FAILURE });
        throw error;
    }
};

export default updateForm;
