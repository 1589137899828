const getTypographyProps = (variant) => variant === 'helper'
    ? {
        variant: 'h6',
        component: 'h6',
        lineHeight: 1.333,
    }
    : {
        variant: 'body2',
        component: 'span',
        lineHeight: 1.428,
    };
export default getTypographyProps;
