import { jsx as _jsx } from "react/jsx-runtime";
import { Button as ButtonMui, styled } from '@mui/material';
import { getColor } from '@profesia/adamui/styles/colors';
const ToolbarButton = ({ editor, titleKey, icon, command, translateFunction, onToolbarButtonClick, isActive = false, disabled, }) => {
    const t = translateFunction('common.wysiwygEditor');
    const onClick = () => {
        onToolbarButtonClick?.(titleKey);
        editor.chain().focus()[command]().run();
    };
    return (_jsx(Button, { withSpace: ['toggleItalic', 'toggleBulletList'].includes(command), isActive: isActive, onClick: onClick, title: t(titleKey), disabled: disabled, children: icon }));
};
const Button = styled(ButtonMui, {
    shouldForwardProp: (prop) => !['withSpace', 'isActive'].includes(prop),
})(({ theme: { spacing, palette }, withSpace, isActive, disabled }) => ({
    minWidth: 'unset',
    marginRight: spacing(withSpace ? 2 : 0.5),
    marginTop: spacing(1),
    marginBottom: spacing(1),
    width: 24,
    height: 24,
    padding: 'unset',
    backgroundColor: isActive ? getColor(palette.neutralLight1) : 'unset',
    opacity: disabled ? 0.3 : 1,
    ':hover': {
        backgroundColor: getColor(palette.neutralLight0),
    },
    '.MuiSvgIcon-root': {
        cursor: 'pointer !important',
    },
}));
export default ToolbarButton;
