export const JOB_TITLE_MAX_LENGTH = 150;
export const NUMBER_OF_OPENINGS_MAX_LENGTH = 50;
export const APPLICABLE_TILL_MAX_LENGTH = 50;
export const REFERENCE_NUMBER_MAX_LENGTH = 20;
export const ACADEMIC_DEGREE_MAX_LENGTH = 50;
export const FIRST_NAME_MAX_LENGTH = 50;
export const LAST_NAME_MAX_LENGTH = 50;
export const EMAIL_MAX_LENGTH = 60;
export const PHONE_MAX_LENGTH = 50;
export const NARROW_INPUT_WIDTH = '300px';
