import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { Stack } from '@mui/material';
import { ButtonAddItem } from '@profesia/adamui/components/common/button';
const HiddenFields = ({ buttonLabel, children, onClick, shouldBeHidden = true }) => {
    const [shouldBeHiddenInternal, setShouldBeHiddenInternal] = useState(shouldBeHidden);
    const handleClick = (event) => {
        setShouldBeHiddenInternal(false);
        onClick?.(event);
    };
    return (_jsx(Stack, { gap: 2.75, children: shouldBeHiddenInternal ? (_jsx(ButtonAddItem, { onClick: handleClick, width: "fit-content", children: buttonLabel })) : (children) }));
};
export default HiddenFields;
