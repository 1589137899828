import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Stack, styled } from '@mui/material';
import { SkeletonRectangular as SkeletonRectangularUi } from '@profesia/adamui/components/common/skeleton';
import { getColor } from '@profesia/adamui/styles/colors';
export const generateSkeletonRectangularHeight10 = (widths, gap) => (_jsx(Box, { display: "flex", gap: gap, children: widths.map((maxWidth, index) => (_jsx(SkeletonRectangular, { maxWidth: maxWidth, height: 10 }, `${maxWidth}-${index}`))) }));
export const SkeletonRectangular = ({ maxWidth = '100%', height = 18, }) => (_jsx(Box, { maxWidth: maxWidth, flexGrow: 1, children: _jsx(SkeletonRectangularUi, { borderRadius: 12, height: height }) }));
export const Wysiwyg = () => (_jsxs(Card, { gap: 1.5, children: [_jsx(SkeletonRectangular, { maxWidth: 135 }), _jsx(SkeletonRectangular, { height: 146 })] }));
export const Card = styled(Stack)(({ theme: { palette: { neutralLight1, neutralLight4 }, spacing, }, }) => ({
    backgroundColor: getColor(neutralLight1),
    borderWidth: 1,
    borderColor: getColor(neutralLight4),
    borderStyle: 'solid',
    borderRadius: 4,
    boxShadow: '0px 2px 4px 0px #0000000F',
    padding: spacing(4),
}));
