import { jsx as _jsx } from "react/jsx-runtime";
import { useFormContext, useWatch } from 'react-hook-form';
import { NumberField } from '@profesia/adamui/components/common/input';
import { FORM_SALARY_FROM, } from '@profesia/mark-offer-form/components/content/form/valuePaths';
import RangeInputLabel from './RangeInputLabel';
const RangeInput = ({ valuePath, placeholder }) => {
    const { setValue, control, formState: { errors }, } = useFormContext();
    const value = useWatch({ name: valuePath, control });
    const error = valuePath === FORM_SALARY_FROM ? errors.salary?.from : errors.salary?.to;
    return (_jsx(NumberField, { value: value ?? undefined, onChange: (newValue) => {
            setValue(valuePath, newValue);
        }, label: _jsx(RangeInputLabel, { valuePath: valuePath, isError: !!error }), placeholder: placeholder, isError: !!error, disableZeroValue: true }));
};
export default RangeInput;
