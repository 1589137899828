import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Paper, styled } from '@mui/material';
import Actions from './Actions';
const ModalContent = ({ header, closeButtonText, content, onClose, spacing = 4, hasActionButtons, overlayContent, showHeaderInModal = true, }) => {
    const headerContent = showHeaderInModal ? (_jsx(Actions, { onClose: onClose, closeButtonGridSize: 2, closeButtonText: closeButtonText, children: header })) : null;
    return (_jsxs(ModalContentContainer, { children: [overlayContent, headerContent, _jsx(Box, { display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%", overflow: "overlay", px: spacing, pt: spacing, mb: hasActionButtons ? 10.5 : 0, children: content })] }));
};
const ModalContentContainer = styled(Paper)(() => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    height: '100%',
    iframe: {
        height: '100% !important',
        width: '100% !important',
    },
}));
export default ModalContent;
