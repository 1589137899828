import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import SimpleLink from './SimpleLink';
import { getConfiguration, useGetPath } from '@profesia/adamui/configuration';
const LinkToRoute = forwardRef(({ routeName, routeOptions, ...otherProps }, ref) => {
    const { linkComponent: RouterLink } = getConfiguration().router;
    const finalProps = {
        ...otherProps,
        to: useGetPath(routeName, routeOptions),
        component: RouterLink,
        ref,
    };
    return _jsx(SimpleLink, { ...finalProps });
});
LinkToRoute.displayName = 'LinkToRoute';
export default LinkToRoute;
