import { styled, Switch } from '@mui/material';
import { getColor } from '@profesia/adamui/styles/colors';
const StyledSwitcher = styled(Switch)(({ checked, theme: { palette } }) => ({
    '&.MuiSwitch-sizeL': {
        width: 51,
        height: 20,
        '& .MuiSwitch-thumb': {
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: getColor(checked ? palette.neutralLight3 : palette.black4),
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.199298)',
        },
        '& .MuiSwitch-switchBase': {
            zIndex: 2,
            left: -2,
            top: -1,
            '&.Mui-checked': {
                transform: 'translateX(35px)',
            },
        },
        overflow: 'inherit',
    },
    '&.MuiSwitch-sizeS': {
        width: 28,
        height: 16,
        '& .MuiSwitch-thumb': {
            width: 14,
            height: 14,
        },
        '& .MuiSwitch-switchBase': {
            top: 1,
            left: 1,
            '&.Mui-checked': {
                transform: 'translateX(12px)',
            },
        },
    },
    padding: 0,
    display: 'flex',
    '& .MuiSwitch-thumb': {
        borderRadius: 10.5,
    },
    '& .MuiSwitch-switchBase': {
        padding: 0,
        '&.Mui-checked': {
            transitionTimingFunction: 'ease-out',
            transition: '150ms',
            color: getColor(palette.white),
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: getColor(palette.primaryBlue),
            },
        },
    },
    '& .MuiSwitch-track': {
        borderRadius: 10.5,
        opacity: 1,
        backgroundColor: getColor(palette.black3),
        boxSizing: 'border-box',
    },
}));
export default StyledSwitcher;
