import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Box } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import { useGetFixedT } from '@profesia/mark-offer-form/i18n';
import { FORM_SALARY_FROM, FORM_SALARY_TO, } from '@profesia/mark-offer-form/components/content/form/valuePaths';
import RangeInput from './RangeInput';
const Range = () => {
    const t = useGetFixedT('salaryType.range');
    const errorT = useGetFixedT('errors.form.salary');
    const { control, setError } = useFormContext();
    const fromValue = useWatch({ name: FORM_SALARY_FROM, control });
    const toValue = useWatch({ name: FORM_SALARY_TO, control });
    useEffect(() => {
        const delayInputTimeoutId = setTimeout(() => {
            if (fromValue && toValue && fromValue > toValue) {
                setError('salary.to', {
                    type: 'required',
                    message: errorT('minMaxCheck'),
                });
            }
        }, 600);
        return () => clearTimeout(delayInputTimeoutId);
    }, [fromValue, toValue]);
    const boxProps = {
        maxWidth: 200,
        flexGrow: 1,
    };
    return (_jsxs(_Fragment, { children: [_jsx(Box, { ...boxProps, children: _jsx(RangeInput, { valuePath: FORM_SALARY_FROM, placeholder: t('fromPlaceholder') }) }), _jsx(Box, { ...boxProps, children: _jsx(RangeInput, { valuePath: FORM_SALARY_TO, placeholder: t('toPlaceholder') }) })] }));
};
export default Range;
