import { styled } from '@mui/material';
import InputField from '@profesia/adamui/components/common/input/textField/InputField';
import { getColor } from '@profesia/adamui/styles/colors';
const propsToFilter = ['isEmpty', 'hasReachedLimit', 'displayUnwrapped', 'hasTags'];
const AutocompleteInput = styled(InputField, {
    shouldForwardProp: (propName) => !propsToFilter.includes(propName),
})(({ isEmpty, hasReachedLimit, displayUnwrapped, hasTags, theme: { spacing, customComponents: { tagField: { px, gap }, }, palette, }, }) => {
    const paddingLeft = (value) => (isEmpty ? undefined : value);
    return {
        '.MuiAutocomplete-input': {
            '&.MuiOutlinedInput-input.MuiInputBase-inputSizeSmall': {
                paddingTop: '0px !important',
                height: 'auto',
                paddingBottom: '0px !important',
                paddingLeft: spacing(1),
                opacity: hasReachedLimit ? 0 : 1,
            },
            '.MuiAutocomplete-root &': hasTags
                ? { paddingLeft: paddingLeft('0px !important') }
                : { fontSize: '14px !important' },
        },
        '.MuiOutlinedInput-root': {
            backgroundColor: getColor(palette.white),
            '&.MuiInputBase-sizeSmall': {
                padding: `${spacing(0.5)} ${px} !important`,
                gap,
            },
        },
        '.MuiOutlinedInput-input': {
            position: 'relative',
        },
        '&.MuiAutocomplete-medium': {
            '& .MuiAutocomplete-inputRoot, & .MuiInputBase-root': {
                minHeight: 32,
            },
        },
        '&.MuiAutocomplete-large': {
            '& .MuiAutocomplete-inputRoot, & .MuiInputBase-root': {
                minHeight: 38,
                '&.Mui-focused': {
                    padding: `${spacing(0.375)}  ${px} !important`,
                },
            },
        },
        '.MuiAutocomplete-inputRoot': {
            flexWrap: displayUnwrapped ? 'wrap' : 'nowrap',
            ...(displayUnwrapped && { height: 'fit-content' }),
            pointerEvents: hasReachedLimit ? 'none' : 'auto',
            '&.Mui-focused': {
                height: 'fit-content',
                flexWrap: 'wrap',
                '.MuiOutlinedInput-input': {
                    position: 'relative',
                },
            },
            '.field-tag': {
                pointerEvents: 'auto',
            },
        },
    };
});
export default AutocompleteInput;
