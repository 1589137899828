import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { I18nextProvider } from 'react-i18next';
import { ThemeProvider } from '@mui/material';
import { QueryClientProvider } from '@tanstack/react-query';
import { APIProvider } from '@vis.gl/react-google-maps';
import themeAdamUi from '@profesia/adamui/styles/theme';
import { ToastProvider } from '@profesia/adamui/components/common/toastService';
import Layout from '@profesia/mark-offer-form/components/Layout';
import { Form } from '@profesia/mark-offer-form/components/content/form';
import { Steps } from '@profesia/mark-offer-form/types';
import { queryClient } from '@profesia/mark-offer-form/services';
import i18n, { initializeI18n } from '@profesia/mark-offer-form/i18n';
import { DEFAULT_FORM_DATA } from '@profesia/mark-offer-form/components/content/form/defaultValues';
import OptionsProvider from '@profesia/mark-offer-form/services/OptionsContext';
import { ACTION_CLOSE, LOCATION_JOB_AD, NAME_HEADER, } from '@profesia/mark-offer-form/utils/track/constants';
import { trackEvent } from '@profesia/mark-offer-form/utils/track/track';
import CodebooksProvider from '@profesia/mark-offer-form/services/CodebooksContext';
import { getConfiguration } from './configuration';
export const MarkOfferForm = forwardRef(({ translations, language, googleMapsApiKey, currentStep = Steps.positionDescription, isEditMode = false, isExtendedFormVisible = false, isLoading = false, data, }, ref) => {
    initializeI18n(language, translations);
    const { form } = getConfiguration();
    return (_jsx(I18nextProvider, { i18n: i18n, children: _jsx(APIProvider, { apiKey: googleMapsApiKey, children: _jsx(QueryClientProvider, { client: queryClient, children: _jsx(OptionsProvider, { isEditMode: isEditMode, isExtendedFormVisible: isExtendedFormVisible, language: language, children: _jsx(ThemeProvider, { theme: themeAdamUi, children: _jsx(ToastProvider, { children: _jsx(Layout, { content: _jsx(CodebooksProvider, { children: _jsx(Form, { ref: ref, currentStep: isEditMode
                                            ? Steps.positionDescription
                                            : currentStep, isLoading: isLoading, data: {
                                            ...DEFAULT_FORM_DATA,
                                            ...data,
                                        } }) }), onClose: () => {
                                    form?.onCancel();
                                    trackEvent(LOCATION_JOB_AD, NAME_HEADER, null, ACTION_CLOSE);
                                } }) }) }) }) }) }) }));
});
