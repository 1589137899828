import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, MenuItem, styled } from '@mui/material';
import { Typography } from '@profesia/adamui/components/common/typography';
import { getColor } from '@profesia/adamui/styles/colors';
const OptionItem = ({ icon = null, checkbox, children, additionalLabel, ...otherProps }) => {
    return (_jsx(StyledMenuItem, { ...otherProps, children: _jsx(Box, { display: "flex", ...(additionalLabel
                ? { width: '100%', alignItems: 'center', justifyContent: 'space-between' }
                : null), children: checkbox ? (_jsxs(_Fragment, { children: [checkbox, _jsx(Typography, { variant: "body2", children: children })] })) : (_jsxs(_Fragment, { children: [_jsx(Box, { display: "flex", alignItems: "center", children: _jsxs(_Fragment, { children: [icon, _jsx(Typography, { variant: "body2", ml: icon ? 1 : 0, children: children })] }) }), additionalLabel ? (_jsx(Typography, { variant: "body2", color: "black3", ml: 3, children: additionalLabel })) : null] })) }) }));
};
const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    '&.MuiMenuItem-divider': {
        borderBottomStyle: 'solid',
        borderBottomColor: getColor(theme.palette.neutralLight0),
        borderBottomWidth: 1,
    },
}));
export default OptionItem;
