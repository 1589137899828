import { jsx as _jsx } from "react/jsx-runtime";
import createSvgIcon from '@profesia/adamui/components/common/icons/createSvgIcon';
const Euro = createSvgIcon(_jsx("path", { d: "M14.334 14.219C13.5457 14.8441 12.5339 15.1695 11.5 15.1303C10.6708 15.1707 9.84891 14.9674 9.15013 14.549C8.45135 14.1307 7.91092 13.5184 7.605 12.7984H13V10.9328H7.068C7.02571 10.6234 7.003 10.312 7 10.0001C7.003 9.68819 7.02571 9.37674 7.068 9.06733H13V7.20182H7.605C7.91092 6.4818 8.45135 5.86948 9.15013 5.45113C9.84891 5.03278 10.6708 4.8295 11.5 4.86992C12.5339 4.83071 13.5457 5.15607 14.334 5.78123L15.081 6.40245L16.412 5.00891L15.666 4.38862C14.5141 3.45378 13.0261 2.95937 11.5 3.00441C10.1298 2.95363 8.78236 3.34261 7.68246 4.10647C6.58256 4.87032 5.79671 5.96286 5.456 7.20182H3V9.06733H5.053C5.021 9.38074 5 9.69321 5 10.0001C5 10.307 5.021 10.6194 5.053 10.9328H3V12.7984H5.456C5.79671 14.0373 6.58256 15.1299 7.68246 15.8937C8.78236 16.6576 10.1298 17.0465 11.5 16.9958C13.0261 17.0408 14.5141 16.5464 15.666 15.6116L16.412 14.9913L15.081 13.5977L14.334 14.219Z", fill: "#506B95" }), 'Euro', {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    color: 'neutralDark3',
    fill: 'none',
});
export default Euro;
