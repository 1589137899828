import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Box, styled } from '@mui/material';
import { useEditor, EditorContent } from '@tiptap/react';
import { Placeholder } from '@tiptap/extension-placeholder';
import { StarterKit } from '@tiptap/starter-kit';
import InputLabel from '@profesia/adamui/components/common/input/InputLabel';
import { getColor } from '@profesia/adamui/styles/colors';
import ValidationMessage from '@profesia/adamui/components/common/input/ValidationMessage';
import { MUI_ERROR_CLASS } from '@profesia/adamui/styles/classes';
import Toolbar from './Toolbar';
import Loading from './Loading';
import Tags from './Tags';
const WysiwygEditor = ({ value = '<p></p>', label, required, placeholder, onChange, aiDropdownProps, onToolbarButtonClick, onTagClick, errorMessage, isLoading, tags, }) => {
    const editor = useEditor({
        extensions: [
            StarterKit,
            Placeholder.configure({
                placeholder,
            }),
        ],
        onUpdate: ({ editor }) => {
            onChange(editor.getHTML());
        },
        content: value,
    });
    const updatePlaceholder = (newPlaceholder) => {
        const placeholderExtension = editor.extensionManager.extensions.find(({ name }) => name === 'placeholder');
        if (placeholderExtension) {
            placeholderExtension.options.placeholder = newPlaceholder;
            editor.view.dispatch(editor.state.tr);
        }
    };
    useEffect(() => {
        if (value !== editor.getHTML()) {
            editor.commands.setContent(value);
        }
    }, [value]);
    useEffect(() => {
        updatePlaceholder(placeholder);
    }, [placeholder]);
    const [errorClass, hasError] = errorMessage ? [MUI_ERROR_CLASS, true] : ['', false];
    const onTagClickInternal = (tag) => {
        const { from, to } = editor.state.selection;
        const contentLength = editor.getText().length + 1;
        if (!editor.isFocused || (from === to && to === contentLength)) {
            const regex = /(<\/ul>|<\/ol>)(<\/div>)?$/;
            const newLiTagValue = `<li>${tag}</li>`;
            const newUlTagValue = `<ul>${newLiTagValue}</ul>`;
            let newContent = '';
            if (editor.getHTML() === '<p></p>') {
                newContent = newUlTagValue;
            }
            else if (regex.test(value)) {
                newContent = value.replace(regex, `${newLiTagValue}$1`);
            }
            else {
                newContent = value + newUlTagValue;
            }
            editor.commands.setContent(newContent);
            onChange(newContent);
        }
        else {
            editor.commands.insertContent(tag);
            editor.commands.focus(to + tag.length - (to - from));
        }
        onTagClick?.(tag);
    };
    return (_jsx(Box, { children: _jsxs(InputLabel, { label: label, required: required, hasError: hasError, children: [_jsxs(BoxStyled, { className: errorClass, children: [_jsx(Toolbar, { editor: editor, aiDropdownProps: aiDropdownProps, onToolbarButtonClick: onToolbarButtonClick }), _jsx(EditorContentStyled, { hidden: isLoading, editor: editor, className: "editor-textarea" }), isLoading ? _jsx(Loading, {}) : null, _jsx(Box, { className: "footer" })] }), _jsx(ValidationMessage, { mt: 0.5, children: errorMessage }), tags && _jsx(Tags, { onTagClick: onTagClickInternal, tags: tags })] }) }));
};
const EditorContentStyled = styled(EditorContent)(({ theme: { spacing, palette, typography } }) => ({
    '.tiptap': {
        minHeight: 152,
        maxHeight: 700,
        overflow: 'auto',
        padding: spacing(3),
        outline: 'none',
        'p, li::marker': {
            margin: 0,
            lineHeight: 1.5,
            fontSize: typography.htmlFontSize,
            color: getColor(palette.primaryNavy),
            fontFamily: typography.fontFamily,
        },
        'ul:not(li > ul), ol:not(li > ol)': {
            margin: spacing(0.5, 0, 1.5, 0),
        },
        'ul, ol': {
            paddingLeft: spacing(3),
        },
        'p.is-editor-empty:first-of-type::before': {
            color: getColor(palette.black3),
            content: 'attr(data-placeholder)',
            float: 'left',
            height: 0,
            pointerEvents: 'none',
        },
    },
}));
const BoxStyled = styled(Box)(({ theme: { spacing, palette: { neutralLight5, secondaryRed }, }, }) => ({
    borderRadius: 4,
    borderColor: getColor(neutralLight5),
    borderWidth: 1,
    borderStyle: 'solid',
    '.footer': {
        borderWidth: 1,
        borderTopStyle: 'solid',
        height: spacing(0.5),
        borderColor: getColor(neutralLight5),
    },
    [`&.${MUI_ERROR_CLASS}`]: {
        borderColor: getColor(secondaryRed),
        '.footer': {
            borderColor: getColor(secondaryRed),
        },
    },
}));
export default WysiwygEditor;
