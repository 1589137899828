import { useMobile } from '@profesia/adamui/utils/responsive';
export const createMappedIcons = (smallPath, regularPath, largePath) => ({
    small: {
        size: 16,
        path: smallPath,
    },
    regular: {
        size: 24,
        path: regularPath,
    },
    large: {
        size: 32,
        path: largePath,
    },
});
export const useGetIconSize = () => (useMobile() ? 'regular' : 'large');
