import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormContext } from 'react-hook-form';
import BoxedSectionWrapper from '@profesia/mark-offer-form/components/content/form/components/BoxedSectionWrapper';
import { useGetFixedT } from '@profesia/mark-offer-form/i18n';
import { getErrorAndScrollClass } from '@profesia/mark-offer-form/components/content/form';
import { EDUCATION_SECTION, LANGUAGES_SECTION, OTHER_SKILLS_SECTION, EXPERIENCE_SECTION, PERSONAL_SKILLS_SECTION, } from '@profesia/mark-offer-form/codebooks/dataTestId';
import OtherSkills from './OtherSkills';
import PracticeAtPosition from './PracticeAtPosition';
import PersonalSkills from './PersonalSkills';
import Education from './Education';
import Languages from './Languages';
const Skills = () => {
    const t = useGetFixedT('skills');
    const { formState: { errors }, } = useFormContext();
    const [educationErrorMessage, educationErrorClass] = getErrorAndScrollClass(errors.educationLevel);
    return (_jsxs(_Fragment, { children: [_jsx(BoxedSectionWrapper, { title: t('title'), className: educationErrorClass, testId: EDUCATION_SECTION, children: _jsx(Education, { educationLevelErrorMessage: educationErrorMessage }) }), _jsx(BoxedSectionWrapper, { testId: LANGUAGES_SECTION, children: _jsx(Languages, {}) }), _jsx(BoxedSectionWrapper, { testId: OTHER_SKILLS_SECTION, children: _jsx(OtherSkills, {}) }), _jsx(BoxedSectionWrapper, { testId: EXPERIENCE_SECTION, children: _jsx(PracticeAtPosition, {}) }), _jsx(BoxedSectionWrapper, { testId: PERSONAL_SKILLS_SECTION, children: _jsx(PersonalSkills, {}) })] }));
};
export default Skills;
