import { jsx as _jsx } from "react/jsx-runtime";
import Option from './Option';
import PaperComponent from './Paper';
const useAsyncOptionsTagFieldAdapter = (getOptions) => {
    return {
        renderOption: (props, option) => _jsx(Option, { option: option, liProps: props }, option.id),
        filterOptions: (option) => option,
        getOptions: (inputValue) => {
            return getOptions({ inputValue });
        },
        getOptionLabel: (option) => option.value,
        PaperComponent,
    };
};
export default useAsyncOptionsTagFieldAdapter;
