import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled, Skeleton as MuiSkeleton, Stack, Box } from '@mui/material';
import { getColor } from '@profesia/adamui/styles/colors';
const Skeleton = () => {
    return (_jsxs(SkeletonCardContainer, { direction: "row", children: [_jsx(Box, { mr: 2, children: _jsx(SkeletonCircular, { size: 48 }) }), _jsxs(Stack, { flex: "1", children: [_jsxs(Stack, { mb: 3, spacing: 1, children: [_jsx(SkeletonRectangular, { width: 92 }), _jsx(SkeletonRectangular, { width: 60 })] }), _jsxs(Stack, { spacing: 1, mb: 1.5, children: [_jsx(SkeletonRectangular, {}), _jsx(SkeletonRectangular, {}), _jsx(SkeletonRectangular, {})] }), _jsxs(Stack, { direction: "row", spacing: 1.5, alignItems: "center", children: [_jsx(SkeletonCircular, { size: 18 }), _jsx(SkeletonRectangular, { width: 89, height: 10 })] })] })] }));
};
const SkeletonCircular = ({ size }) => {
    return _jsx(MuiSkeleton, { variant: "circular", width: size, height: size });
};
const SkeletonRectangular = ({ width, height = 18 }) => {
    return _jsx(SkeletonBase, { variant: "rectangular", height: height, width: width });
};
const SkeletonBase = styled(MuiSkeleton)(() => ({
    borderRadius: 9,
}));
const SkeletonCardContainer = styled(Stack)(({ theme: { palette, spacing } }) => ({
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 4,
    borderColor: getColor(palette.neutralLight4),
    backgroundColor: getColor(palette.neutralLight0),
    padding: spacing(2.5, 3, 3, 3),
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.06)',
}));
export default Skeleton;
