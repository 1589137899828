import { jsx as _jsx } from "react/jsx-runtime";
import { Box, styled } from '@mui/material';
import OptionItem from '@profesia/adamui/components/common/input/textField/ComboTagger/Option';
import useTagFieldContext from '@profesia/adamui/components/common/input/textField/tagField/hooks/useTagFieldContext';
const Option = ({ option, liProps }) => {
    const { onChange, values, hasReachedLimit } = useTagFieldContext();
    const { key, ...restLiProps } = liProps;
    const onClick = () => {
        if (!hasReachedLimit) {
            onChange([...values, option]);
        }
    };
    return (_jsx(BoxStyled, { children: _jsx(OptionItem, { option: option, liProps: { onClick, ...restLiProps } }, option.id) }));
};
const BoxStyled = styled(Box)(({ theme }) => ({
    '.MuiMenuItem-root': {
        padding: theme.spacing(0.75, 1),
    },
}));
export default Option;
