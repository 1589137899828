export var DrivingLicence;
(function (DrivingLicence) {
    DrivingLicence["A"] = "A";
    DrivingLicence["B"] = "B";
    DrivingLicence["C"] = "C";
    DrivingLicence["D"] = "D";
    DrivingLicence["E"] = "E";
    DrivingLicence["T"] = "T";
})(DrivingLicence || (DrivingLicence = {}));
const drivingLicencesIds = Object.values(DrivingLicence);
export const useGetDrivingLicenceOptions = () => {
    return drivingLicencesIds.map((id) => ({ id, label: id }));
};
