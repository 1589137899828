import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { MenuItem as MenuItemUI, Button as ButtonUI, Menu as MenuUI, Box, styled, alpha, } from '@mui/material';
import { AiDisabledIcon, AiIcon } from '@profesia/adamui/components/common/icons';
import { Typography } from '@profesia/adamui/components/common/typography';
import { getColor } from '@profesia/adamui/styles/colors';
import TooltipUI from '@profesia/adamui/components/common/Tooltip';
import { tabletDown } from '@profesia/adamui/styles/theme';
import { isWysiWygEditorEmpty } from '@profesia/adamui/utils/WysiwygEditor';
const AiDropdownTones = ['serious', 'friendly', 'motivational'];
const AiDropdown = ({ value, translateFunction, aiDropdownProps }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const t = translateFunction('common.aiDropdown');
    if (!aiDropdownProps?.showAiDropdown) {
        return null;
    }
    const { onOptionClick, onAiDropdownButtonClick, disabledAiDropdown = false } = aiDropdownProps;
    const handleClick = (event) => {
        onAiDropdownButtonClick?.(event);
        setAnchorEl(event.currentTarget);
    };
    const closeMenu = () => {
        setAnchorEl(null);
    };
    const onItemClick = (tone) => {
        closeMenu();
        onOptionClick?.(tone);
    };
    const isOpen = Boolean(anchorEl);
    const tPrefix = isWysiWygEditorEmpty(value) ? '' : 're';
    return (_jsxs(_Fragment, { children: [_jsx(Tooltip, { tooltipContent: t(`tooltip.${disabledAiDropdown ? 'disabled' : 'enabled'}`), variant: "content", placement: "top-start", children: _jsx(Box, { children: _jsx(Button, { id: "ai-dropdown-button", onClick: handleClick, disabled: disabledAiDropdown, isOpen: isOpen, children: _jsxs(Box, { display: "flex", flexDirection: "row", alignItems: "center", gap: 1, children: [_jsx(StyledTypography, { color: disabledAiDropdown ? 'black3' : 'secondaryViolet', fontWeight: 500, children: t('title') }), disabledAiDropdown ? _jsx(AiDisabledIcon, {}) : _jsx(AiIcon, {})] }) }) }) }), _jsx(Menu, { anchorEl: anchorEl, open: isOpen, onClose: closeMenu, MenuListProps: {
                    'aria-labelledby': 'ai-dropdown-button',
                }, anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                }, transformOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                }, children: AiDropdownTones.map((tone) => (_jsx(MenuItem, { onClick: () => onItemClick(tone), children: t(`item.${tPrefix}generate.${tone}`) }, tone))) })] }));
};
const MenuItem = styled(MenuItemUI)(({ theme: { spacing, palette } }) => ({
    color: getColor(palette.primaryNavy),
    height: spacing(4.5),
}));
const Menu = styled(MenuUI)(({ theme: { palette } }) => ({
    '.MuiMenu-paper': {
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 0,
        borderColor: getColor(palette.secondaryViolet),
    },
    ul: {
        padding: 0,
    },
    li: {
        lineHeight: '20px',
        fontSize: '14px',
        color: getColor(palette.primaryNavy),
    },
}));
const Button = styled(ButtonUI, {
    shouldForwardProp: (prop) => prop !== 'isOpen',
})(({ theme: { palette }, isOpen }) => ({
    backgroundColor: isOpen ? getColor(palette.neutralLight1) : undefined,
    '&:hover': {
        backgroundColor: getColor(palette.neutralLight0),
    },
}));
const Tooltip = styled(TooltipUI)(({ theme }) => ({
    '.MuiTooltip-tooltip': {
        backgroundColor: alpha(getColor(theme.palette.primaryNavy), 0.85),
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: getColor(theme.palette.primaryGray),
    },
    '.MuiTypography-root': {
        color: getColor(theme.palette.white),
    },
}));
const StyledTypography = styled(Typography)(() => ({
    [tabletDown]: {
        display: 'none',
    },
}));
export default AiDropdown;
