import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled, alpha } from '@mui/material';
import { getColor } from '@profesia/adamui/styles/colors';
import { LoadingCircle } from '@profesia/adamui/components/common/loadings';
import ButtonUniversal from '../ButtonUniversal';
const getColorStyles = ({ backgroundColor, color }) => {
    return {
        backgroundColor: getColor(backgroundColor),
        color: getColor(color),
        '&:hover': {
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), ${getColor(backgroundColor)}`,
        },
        '&:active': {
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), ${getColor(backgroundColor)}`,
        },
    };
};
const ButtonDefault = styled(ButtonUniversal)(({ startIcon, theme: { palette: { primaryOrange, primaryNavy, neutralLight2, extendedBurgandy3, secondaryGreen, neutralLight1, white, }, spacing, }, }) => {
    return {
        lineHeight: 1,
        border: `1px solid ${alpha(getColor(primaryNavy), 0.2)}`,
        padding: spacing(0, 2),
        '.MuiCircularProgress-root': {
            marginRight: startIcon ? spacing(1.5) : 'auto',
        },
        '&.MuiButton-small': {
            height: 32,
        },
        '&.MuiButton-medium': {
            height: 36,
        },
        '&.MuiButton-large': {
            height: 48,
        },
        '&.MuiButton-primary': {
            ...getColorStyles({ backgroundColor: primaryOrange, color: primaryNavy }),
        },
        '&.MuiButton-secondary': {
            ...getColorStyles({ backgroundColor: neutralLight2, color: primaryNavy }),
        },
        '&.MuiButton-destructive': {
            ...getColorStyles({ backgroundColor: extendedBurgandy3, color: white }),
        },
        '&.MuiButton-positive': {
            ...getColorStyles({ backgroundColor: secondaryGreen, color: white }),
        },
        '&.Mui-Loading.Mui-disabled': {
            ...getColorStyles({ backgroundColor: primaryOrange, color: primaryNavy }),
        },
        '&.Mui-disabled': {
            backgroundColor: alpha(getColor(neutralLight1), 0.45),
            color: alpha(getColor(primaryNavy), 0.45),
        },
    };
});
const Button = ({ variant = 'primary', isLoading, disabled, children, size = 'medium', ...props }) => {
    let loadingCircle = null;
    let content = children;
    if (isLoading) {
        loadingCircle = _jsx(LoadingCircle, { color: "primaryNavy", size: 13 });
        content = props.startIcon ? content : null;
    }
    return (_jsxs(ButtonDefault, { className: `${isLoading ? 'Mui-Loading' : ''} MuiButton-${size}`, variant: variant, disabled: isLoading || disabled, ...props, children: [loadingCircle, content] }));
};
export default Button;
