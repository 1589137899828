// BooleanField
export const BOOLEAN_FIELD_STACK = 'boolean-field-stack';
// TagField
export const FIELD_TAG = 'field-tag';
export const MORE_TAGS = 'more-tags';
export const INPUT_FIELD_WRAPPER = 'input-field-wrapper';
export const ADD_BOOLEAN_FIELD_BUTTON = 'add-boolean-field-button';
// Select Group
export const SELECT_GROUP = 'select-group';
export const REMOVE_SELECT_GROUP_BUTTON = 'remove-select-group-button';
