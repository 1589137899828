import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormContext } from 'react-hook-form';
import BoxedSectionWrapper from '@profesia/mark-offer-form/components/content/form/components/BoxedSectionWrapper';
import { useGetFixedT } from '@profesia/mark-offer-form/i18n';
import SalaryType from './SalaryType';
import OtherInformation from '@profesia/mark-offer-form/components/content/Salary/OtherInformation';
import { getErrorAndScrollClass } from '@profesia/mark-offer-form/components/content/form';
import { SALARY_SECTION, SALARY_OTHER_INFORMATION_SECTION, } from '@profesia/mark-offer-form/codebooks/dataTestId';
const Salary = () => {
    const t = useGetFixedT('salary');
    const { formState: { errors }, } = useFormContext();
    const [salaryTypeErrorMessage, salaryTypeErrorClass] = getErrorAndScrollClass(errors.salary?.type);
    return (_jsxs(_Fragment, { children: [_jsx(BoxedSectionWrapper, { title: t('title'), className: salaryTypeErrorClass, testId: SALARY_SECTION, children: _jsx(SalaryType, { errorMessage: salaryTypeErrorMessage }) }), _jsx(BoxedSectionWrapper, { testId: SALARY_OTHER_INFORMATION_SECTION, children: _jsx(OtherInformation, {}) })] }));
};
export default Salary;
