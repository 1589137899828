export const sortContactPersons = (contactPersons) => {
    return contactPersons?.sort((a, b) => {
        const labelA = a.name.toLowerCase().trim();
        const labelB = b.name.toLowerCase().trim();
        if (labelA < labelB)
            return -1;
        if (labelA > labelB)
            return 1;
        return 0;
    });
};
const transformContactPersons = (contactPersons) => {
    const mappedContactPersons = contactPersons?.map((contactPerson) => ({
        id: contactPerson.id,
        name: `${contactPerson.lastName} ${contactPerson.firstName}`,
    }));
    return sortContactPersons(mappedContactPersons);
};
export default transformContactPersons;
