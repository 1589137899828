import { FormT } from '@profesia/mark-offer-form/components/content/form/types';
import { mapFormToPostData } from './mapFormToPostData';
import apiRequest from './apiRequest';

const postForm = async (data: FormT): Promise<number> => {
    const transformedData = mapFormToPostData(data);

    const jsonResponse = await apiRequest<{ id: number }>('position', {
        method: 'POST',
        body: JSON.stringify(transformedData),
    });

    return jsonResponse.id;
};

export default postForm;
