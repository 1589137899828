import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Stack } from '@mui/material';
import { Typography } from '@profesia/adamui/components/common/typography';
import { CardItem } from '@profesia/adamui/components/common/card';
import { useTranslationInPageLanguage } from '@profesia/mark-offer-form/i18n';
import { ACTION_ORIGINAL_SUGGESTION, ACTION_SUGGESTION, LOCATION_AI_WIZARD, NAME_JOB_TITLE, } from '@profesia/mark-offer-form/utils/track/constants';
import { trackEvent, ComponentTypeEnum } from '@profesia/mark-offer-form/utils/track/track';
const Suggestions = ({ selectedTone, onSuggestionClick, userValue, suggestions, }) => {
    const t = useTranslationInPageLanguage('jobTitle.aiModal');
    return (_jsxs(Box, { pt: 4.5, px: 3.75, children: [_jsx(Typography, { color: "primaryNavy", fontSize: '16px', mb: 2, children: t('suggestionTitle') }), _jsxs(Stack, { gap: 1, children: [_jsx(CardItem, { title: userValue, description: t('originalSuggestion'), onClick: () => {
                            trackEvent(LOCATION_AI_WIZARD, NAME_JOB_TITLE, ComponentTypeEnum.buttonField, ACTION_ORIGINAL_SUGGESTION);
                            onSuggestionClick(userValue, ACTION_ORIGINAL_SUGGESTION);
                        }, isSelected: selectedTone === ACTION_ORIGINAL_SUGGESTION }), suggestions.map((suggestion, i) => (_jsx(CardItem, { title: suggestion, description: `${t('suggestion')} ${i + 1}`, onClick: () => {
                            const tone = `${ACTION_SUGGESTION} ${i + 1}`;
                            trackEvent(LOCATION_AI_WIZARD, NAME_JOB_TITLE, ComponentTypeEnum.buttonField, tone);
                            onSuggestionClick(suggestion, tone);
                        }, isSelected: selectedTone === `${ACTION_SUGGESTION} ${i + 1}` }, suggestion)))] })] }));
};
export default Suggestions;
