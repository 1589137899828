import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { styled, Link as MuiLink, } from '@mui/material';
import { Typography } from '@profesia/adamui/components/common/typography';
import { getColor } from '@profesia/adamui/styles/colors';
const mappedColors = {
    primary: 'primaryBlue',
    primaryVisited: 'extendedRoyal2',
    secondary: 'primaryNavy',
    destructive: 'secondaryRed',
    white: 'white',
};
const SimpleLink = forwardRef(({ typographyProps, ...linkProps }, ref) => {
    const link = _jsx(Link, { ref: ref, ...linkProps });
    return typographyProps ? _jsx(Typography, { ...typographyProps, children: link }) : link;
});
SimpleLink.displayName = 'SimpleLink';
const Link = styled(MuiLink, {
    shouldForwardProp: (prop) => !['variantType'].includes(prop),
})(({ variantType = 'primary', theme: { spacing, palette } }) => {
    const textColor = getColor(palette[mappedColors[variantType]]);
    return {
        padding: spacing(0, 0.125),
        marginLeft: spacing(-0.125),
        width: 'fit-content',
        textDecoration: 'none',
        borderBottomWidth: 1,
        borderBottomStyle: 'dashed',
        cursor: 'pointer',
        color: textColor,
        '&:hover': {
            borderBottomStyle: 'solid',
        },
    };
});
export default SimpleLink;
