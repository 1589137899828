import { Languages } from '@profesia/mark-offer-form/i18n';
import apiRequest from './apiRequest';

type Response = {
    suggestions: string[];
};

const getJobTitleAiSuggestions = async (
    userInput: string,
    locale: Languages
): Promise<string[]> => {
    const jsonResponse = await apiRequest<Response>('ai/generate/job-title', {
        method: 'POST',
        body: JSON.stringify({
            userInput,
            language: locale,
        }),
    });

    return jsonResponse.suggestions;
};

export default getJobTitleAiSuggestions;
