import { jsx as _jsx } from "react/jsx-runtime";
import createSvgIcon from '@profesia/adamui/components/common/icons/createSvgIcon';
import React from 'react';
export const getInputIcon = (iconPath, iconName, state, props) => {
    const Icon = createSvgIcon(_jsx(React.Fragment, { children: iconPath }), `${iconName}${state[0].toUpperCase() + state.slice(1)}`, {
        width: 18,
        viewBox: '0 0 18 18',
    });
    return _jsx(Icon, { ...props });
};
