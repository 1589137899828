import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid, styled } from '@mui/material';
import { Button } from '@profesia/adamui/components/common/button';
import { getColor } from '@profesia/adamui/styles/colors';
import { useGetFixedT } from '@profesia/adamui/configuration';
const ContentActions = ({ useInDialog, submitButtonProps, cancelButtonProps, }) => {
    const t = useGetFixedT('common.actions');
    let submitButton = null;
    if (submitButtonProps) {
        const { isTypeSubmit, submitHref, dataCy, dataDy, onSubmit, label, ...otherSubmitButtonProps } = submitButtonProps;
        const onSubmitProps = isTypeSubmit
            ? { type: 'submit' }
            : submitHref
                ? { href: submitHref }
                : { onClick: onSubmit };
        submitButton = (_jsx(Button, { ...onSubmitProps, ...otherSubmitButtonProps, "data-cy": dataCy, "data-dy": dataDy, children: label ?? t('submit') }));
    }
    return (_jsxs(StyledButtonActionsWrapper, { className: useInDialog ? '' : 'modal-content-actions', children: [submitButton, cancelButtonProps ? (_jsx(Button, { onClick: cancelButtonProps.onCancel, variant: "secondary", "data-cy": cancelButtonProps.dataCy, "data-dy": cancelButtonProps.dataDy, children: cancelButtonProps?.label ?? t('cancel') })) : null] }));
};
const StyledButtonActionsWrapper = styled(Grid)(({ theme: { palette, spacing } }) => ({
    '&.modal-content-actions': {
        position: 'absolute',
        left: 0,
        padding: spacing(3, 4),
    },
    backgroundColor: getColor(palette.white),
    width: '100%',
    gap: spacing(1),
    display: 'flex',
    paddingTop: spacing(3),
    justifyContent: 'flex-end',
    marginTop: 'auto',
}));
export default ContentActions;
