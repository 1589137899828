import { jsx as _jsx } from "react/jsx-runtime";
import { Button } from '@mui/material';
import { getConfiguration, useGetPath } from '@profesia/adamui/configuration';
const ButtonLink = ({ routeName, routeOptions, ...otherProps }) => {
    const configuration = getConfiguration();
    const { linkComponent: Link } = configuration.router;
    const transformedProps = {
        ...otherProps,
        component: Link,
        to: useGetPath(routeName, routeOptions),
    };
    return _jsx(Button, { ...transformedProps });
};
export default ButtonLink;
