import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, styled, Box } from '@mui/material';
import { Radio } from '@profesia/adamui/components/common/input/radioButtons';
import { getColor } from '@profesia/adamui/styles/colors';
import { TypographySpan } from '@profesia/adamui/components/common/typography';
const PricePerContact = styled(Box)(({ theme }) => ({
    '& .MuiTypography-root': {
        color: theme.palette.neutralDark2,
    },
}));
const StackContainer = styled(Stack)(() => ({
    justifyContent: 'center',
}));
const HeaderContainer = styled(Box)(({ theme: { spacing } }) => ({
    display: 'flex',
    gap: spacing(1.5),
    '& .MuiTypography-root': {
        margin: 'auto 0',
    },
}));
const CardBuyPackageContainer = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isSelected',
})(({ theme: { palette, spacing }, isSelected }) => ({
    display: 'flex',
    backgroundColor: getColor(palette.neutralLight1),
    padding: spacing(1.375, 2, 0.875, 1.5),
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: getColor(isSelected ? palette.primaryBlue : palette.black5),
    borderRadius: 4,
    cursor: 'pointer',
}));
const CardBuyPackage = ({ item, description, badge, price, pricePerContact, currency = '€', isSelected, onClick, }) => {
    return (_jsxs(CardBuyPackageContainer, { onClick: onClick, isSelected: isSelected, children: [_jsx(Box, { children: _jsx(Radio, { size: "medium", checked: isSelected }) }), _jsxs(Box, { display: "flex", flex: "1 1 auto", children: [_jsxs(StackContainer, { flex: "1 1 auto", children: [_jsxs(HeaderContainer, { children: [_jsx(TypographySpan, { weight: "bold", children: item }), badge] }), _jsx(TypographySpan, { children: description })] }), _jsxs(StackContainer, { textAlign: "end", children: [_jsxs(TypographySpan, { weight: "bold", children: [price, currency] }), _jsx(PricePerContact, { children: _jsx(TypographySpan, { variant: "small", color: "neutralDark2", children: pricePerContact }) })] })] })] }));
};
export default CardBuyPackage;
