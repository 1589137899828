import { useGetFixedT } from '@profesia/mark-offer-form/i18n';
export var EmploymentType;
(function (EmploymentType) {
    EmploymentType["FullTime"] = "full_time";
    EmploymentType["PartTime"] = "part_time";
    EmploymentType["Internship"] = "internship";
    EmploymentType["Contract"] = "contract";
    EmploymentType["Agreement"] = "auxiliary_work";
})(EmploymentType || (EmploymentType = {}));
const employmentTypeOptions = [
    { label: 'fullTime', id: EmploymentType.FullTime },
    { label: 'partTime', id: EmploymentType.PartTime },
    { label: 'internship', id: EmploymentType.Internship },
    { label: 'contract', id: EmploymentType.Contract },
    { label: 'agreement', id: EmploymentType.Agreement },
];
export const useGetEmploymentTypesOptions = () => {
    // @TODO root key should be codebooks
    const t = useGetFixedT('positionDescription.employmentType');
    return employmentTypeOptions.map(({ id, label }) => ({ id, label: t(label) }));
};
