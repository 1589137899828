import { jsx as _jsx } from "react/jsx-runtime";
import { styled } from '@mui/material';
import { getColorStyling } from '@profesia/adamui/components/common/button/ButtonSquare/colorStyling';
import ButtonUniversal from '../ButtonUniversal';
const squareSize = {
    large: {
        minWidth: 92,
        width: 92,
        height: 92,
    },
    medium: {
        minWidth: 72,
        width: 72,
        height: 72,
    },
    small: {
        minWidth: 48,
        width: 48,
        height: 72,
    },
};
const moreSize = {
    large: {
        minWidth: 56,
        width: 56,
        height: 92,
    },
    medium: {
        minWidth: 56,
        width: 56,
        height: 92,
    },
    small: {
        minWidth: 48,
        width: 48,
        height: 72,
    },
};
const Button = styled(ButtonUniversal, {
    shouldForwardProp: (prop) => !['isMobile', 'size'].includes(prop),
})(({ theme: { typography, palette: { neutralLight2, neutralLight3, black0, black1, primaryBlue, white, neutralDark1, }, }, isMobile, size = 'large', }) => {
    return {
        padding: 0,
        borderRadius: 0,
        fontSize: typography.body2.fontSize,
        lineHeight: 1.71,
        fontWeight: typography.fontWeightRegular,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minWidth: 48,
        '& > *': {
            margin: '0 auto',
        },
        '& .MuiSvgIcon-root': {
            ...typography.icons.modalAction,
        },
        '.MuiCircularProgress-root': {
            margin: '0 auto',
        },
        '&.MuiButton-squareLight': {
            ...squareSize[size],
            ...getColorStyling({
                hover: neutralLight2,
                active: neutralLight3,
                font: black0,
                icon: isMobile ? neutralDark1 : primaryBlue,
                borderRight: neutralLight3,
                borderLeft: neutralLight3,
            }),
        },
        '&.MuiButton-squareDark': {
            ...squareSize[size],
            ...getColorStyling({
                hover: black1,
                active: black0,
                font: white,
                icon: white,
                borderRight: black0,
            }),
        },
        '&.MuiButton-more': {
            ...moreSize[size],
            ...getColorStyling({
                hover: neutralLight2,
                active: neutralLight3,
                font: black0,
                icon: black1,
                borderRight: neutralLight3,
                borderLeft: neutralLight3,
            }),
        },
    };
});
const ButtonSquare = ({ children, variant = 'squareLight', dataCy, ...otherProps }) => (_jsx(Button, { variant: variant, "data-cy": dataCy, ...otherProps, children: children }));
export default ButtonSquare;
