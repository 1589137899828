import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { useBreakpoint } from '@profesia/adamui/styles/theme';
import StyledTooltip from '@profesia/mark-offer-form/components/content/common/Tooltip';
const Tooltip = ({ tooltipContent, tooltipChildren, noTooltipChildren }) => {
    const canShowTooltip = useBreakpoint(HINT_TOOLTIP_BREAKPOINT, 'down');
    return canShowTooltip ? (_jsx(StyledTooltip, { tooltipContent: tooltipContent, variant: "content", placement: "top-start", children: _jsx(Box, { children: tooltipChildren }) })) : (_jsxs(_Fragment, { children: [tooltipChildren, noTooltipChildren] }));
};
export const HINT_TOOLTIP_BREAKPOINT = 1400;
export const HINT_HIDE_BREAKPOINT = 980;
export default Tooltip;
