// Job Ad Form 1st page:
export const JOB_TITLE_SECTION = 'job-title';
export const POSITION_DESCRIPTION_SECTION = 'position-description';
export const JOB_DESCRIPTION_SECTION = 'job-description';
export const POSITION_LOCATION_SECTION = 'position-location';
export const SALARY_SECTION = 'salary';
export const SALARY_OTHER_INFORMATION_SECTION = 'other-information-salary';
export const EDUCATION_SECTION = 'education';
export const LANGUAGES_SECTION = 'languages';
export const OTHER_SKILLS_SECTION = 'other-skills';
export const EXPERIENCE_SECTION = 'experience';
export const PERSONAL_SKILLS_SECTION = 'personal-skills';
export const SELECTION_PROCESS_INFO_SECTION = 'selection-process-info';
export const JOB_AD_SETTINGS_SECTION = 'job-ad-settings';
export const CONTACT_DETAILS_SECTION = 'contact-details';
