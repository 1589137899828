import { jsx as _jsx } from "react/jsx-runtime";
import { useFormContext } from 'react-hook-form';
import { FORM_COMPANY_ID } from '@profesia/mark-offer-form/components/content/form/valuePaths';
import { useGetContactPersons } from '@profesia/mark-offer-form/services';
import Content from './Content';
import Skeleton from './Skeleton';
const ContactDetails = () => {
    const { getValues } = useFormContext();
    const companyId = getValues(FORM_COMPANY_ID);
    const { data: contactPersons, isLoading: isContactPersonsLoading } = useGetContactPersons(companyId);
    return isContactPersonsLoading ? _jsx(Skeleton, {}) : _jsx(Content, { contactPersons: contactPersons });
};
export default ContactDetails;
