import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, styled } from '@mui/material';
import { ErrorIcon } from '@profesia/adamui/components/common/icons';
import { getColor } from '@profesia/adamui/styles/colors';
import { TypographySpan } from '@profesia/adamui/components/common/typography';
const CardValidationContainer = styled(Box)(({ theme: { palette } }) => {
    const red = getColor(palette.secondaryRed);
    return {
        display: 'flex',
        width: '100%',
        minHeight: 41,
        '.MuiBox-root': {
            display: 'flex',
            borderColor: red,
            borderStyle: 'solid',
            borderWidth: 1,
            '&:first-of-type': {
                width: 46,
                borderBottomLeftRadius: 4,
                borderTopLeftRadius: 4,
                backgroundColor: red,
                '.MuiSvgIcon-root': {
                    margin: 'auto',
                },
            },
            '&:nth-of-type(2)': {
                flex: '1 1 auto',
                borderBottomRightRadius: 4,
                borderTopRightRadius: 4,
                backgroundColor: getColor(palette.neutralLight0),
            },
        },
    };
});
const CardValidation = ({ children }) => {
    return (_jsxs(CardValidationContainer, { children: [_jsx(Box, { children: _jsx(ErrorIcon, {}) }), _jsx(Box, { children: _jsx(TypographySpan, { my: "auto", mx: 2, children: children }) })] }));
};
export default CardValidation;
