import { jsx as _jsx } from "react/jsx-runtime";
import wordsMatcher from '@profesia/adamui/components/common/treeList/tagFieldAdapter/wordsMatcher';
import { MINIMAL_INPUT_LENGTH } from '@profesia/adamui/components/common/treeList/tagFieldAdapter';
import Paper from './Paper';
export const filterOptions = (options, inputValue, allowFreeValue = false) => {
    const checkForMatch = wordsMatcher(inputValue);
    if (inputValue?.length < MINIMAL_INPUT_LENGTH) {
        return options;
    }
    const filteredOptions = options.filter(({ value }) => checkForMatch(value));
    return !filteredOptions.length && allowFreeValue ? [{ value: inputValue }] : filteredOptions;
};
export const filterFavouriteItems = (selectedValues, favouriteItems = []) => {
    const selectedIds = new Set(selectedValues.map(({ id }) => id).filter((value) => value));
    return favouriteItems.filter(({ id }) => !selectedIds.has(id));
};
export const getPaperComponent = (favouriteItems, onFavouriteItemClick) => (props) => _jsx(Paper, { favouriteItems: favouriteItems, onFavouriteItemClick: onFavouriteItemClick, ...props });
export const remapValuesForTagField = (values) => values.map(({ id, value }) => ({ id, value }));
export const mergeValues = (tagFieldValues, values) => {
    const levelsByIds = new Map(values.map(({ id, value, level }) => [id ?? value, level]));
    return tagFieldValues.map(({ id, value }) => ({
        id: id,
        value: value,
        level: levelsByIds.get((id ?? value)) ?? '',
    }));
};
export const createSkillListErrorsMap = (errors) => new Map(errors?.map(({ message, index }) => [index, message]));
