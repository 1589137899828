import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { get, useFormContext, useWatch } from 'react-hook-form';
import { Stack } from '@mui/material';
import ComboTagger from '@profesia/adamui/components/common/input/textField/ComboTagger';
import { useGetFixedT } from '@profesia/mark-offer-form/i18n';
import { FORM_LANGUAGES_ITEMS } from '@profesia/mark-offer-form/components/content/form/valuePaths';
import { useCodebooksContext } from '@profesia/mark-offer-form/services/CodebooksContext';
import { useGetLanguageLevelOptions } from '@profesia/mark-offer-form/codebooks';
import { SCROLL_TO_ERROR_CLASS } from '@profesia/mark-offer-form/styles/classes';
import { trackEvent } from '@profesia/mark-offer-form/utils/track/track';
import { ACTION_RECOMMENDED_TAG, LOCATION_JOB_AD, NAME_LANGUAGE_SKILLS, } from '@profesia/mark-offer-form/utils/track/constants';
import LanguagesRequired from './LanguagesRequired';
import { getSkillListErrors } from '../getErrors';
const Languages = () => {
    const t = useGetFixedT('skillsAndRequirements.languages');
    const { data } = useCodebooksContext();
    const levelOptions = useGetLanguageLevelOptions();
    const { setValue, getValues, control, trigger, formState: { errors }, } = useFormContext();
    const values = useWatch({ name: FORM_LANGUAGES_ITEMS, control });
    let languageItemErrors = get(errors, FORM_LANGUAGES_ITEMS);
    return (_jsxs(Stack, { gap: 2.375, children: [_jsx(ComboTagger, { requiredSkillLevel: true, onChange: (newValues) => {
                    const currentValues = getValues(FORM_LANGUAGES_ITEMS);
                    const newItemAdded = currentValues?.length < newValues.length;
                    setValue(FORM_LANGUAGES_ITEMS, newValues);
                    if (!newItemAdded && languageItemErrors) {
                        trigger(FORM_LANGUAGES_ITEMS);
                    }
                }, values: values, label: t('label'), options: data?.languages.others ?? [], levelOptions: levelOptions, favouriteItems: data?.languages.favourites ?? [], errors: {
                    skillErrors: getSkillListErrors(languageItemErrors, values.length),
                }, skillErrorClassName: SCROLL_TO_ERROR_CLASS, onFavouriteItemClick: (favouriteItem) => trackEvent(LOCATION_JOB_AD, NAME_LANGUAGE_SKILLS, null, ACTION_RECOMMENDED_TAG, favouriteItem.value), displayUnwrapped: true }), _jsx(LanguagesRequired, { selectedLanguagesCount: values.length })] }));
};
export default Languages;
