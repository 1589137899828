import { useGetFixedT } from '@profesia/mark-offer-form/i18n';
export var EducationLevel;
(function (EducationLevel) {
    EducationLevel["Elementary"] = "elementary";
    EducationLevel["SecondaryWithoutExam"] = "secondaryWithoutExam";
    EducationLevel["SecondaryWithExam"] = "secondaryWithExam";
    EducationLevel["Professional"] = "professional";
    EducationLevel["University1"] = "university1";
    EducationLevel["University2"] = "university2";
    EducationLevel["University3"] = "university3";
})(EducationLevel || (EducationLevel = {}));
const educationIds = Object.values(EducationLevel);
export const useGetEducationOptions = () => {
    const t = useGetFixedT('common.codebooks.education');
    return educationIds.map((id, index) => ({ id: index.toString(), label: t(id) }));
};
