import { useGetFixedT } from '@profesia/mark-offer-form/i18n';
export var SuitableFor;
(function (SuitableFor) {
    SuitableFor["Graduates"] = "graduates";
    SuitableFor["HighSchoolStudents"] = "highSchoolStudents";
    SuitableFor["UniversityStudents"] = "universityStudents";
    SuitableFor["Refugees"] = "refugees";
})(SuitableFor || (SuitableFor = {}));
const suitableFor = Object.values(SuitableFor);
export const useGetSuitableForOptions = () => {
    // @TODO root key should be codebooks
    const t = useGetFixedT('positionDescription.suitableFor');
    return suitableFor.map((id) => ({ id, label: t(id) }));
};
