import { jsx as _jsx } from "react/jsx-runtime";
import { useFormContext, useWatch } from 'react-hook-form';
import HiddenFields from '@profesia/adamui/components/common/HiddenFields';
import { InputField } from '@profesia/adamui/components/common/input';
import { useGetFixedT } from '@profesia/mark-offer-form/i18n';
import { FORM_EDUCATION_SPECIALIZATION } from '@profesia/mark-offer-form/components/content/form/valuePaths';
const EducationSpecialization = () => {
    const { setValue, control } = useFormContext();
    const value = useWatch({ name: FORM_EDUCATION_SPECIALIZATION, control });
    const t = useGetFixedT('skillsAndRequirements.educationSpecialization');
    return (_jsx(HiddenFields, { buttonLabel: t('label'), shouldBeHidden: !value, children: _jsx(InputField, { label: t('label'), value: value || '', onChange: ({ target }) => setValue(FORM_EDUCATION_SPECIALIZATION, target.value) }) }));
};
export default EducationSpecialization;
