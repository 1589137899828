import { jsx as _jsx } from "react/jsx-runtime";
import LinkToRoute from './LinkToRoute';
import SimpleLink from './SimpleLink';
import LinkToAction from './LinkToAction';
const SmartLink = ({ to, onClick = () => { }, state, href, ...otherProps }) => {
    if (href) {
        return _jsx(SimpleLink, { href: href, ...otherProps });
    }
    return to ? (_jsx(LinkToRoute, { state: state, onClick: onClick, ...to, ...otherProps })) : (_jsx(LinkToAction, { onClick: onClick, typographyProps: { component: 'span' }, ...otherProps }));
};
export default SmartLink;
