import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, styled } from '@mui/material';
import { Typography } from '@profesia/adamui/components/common/typography';
import { getColor } from '@profesia/adamui/styles/colors';
import formatThousands from '@profesia/adamui/utils/formatThousands';
const ReactionsContainer = styled(Stack)(({ theme }) => ({
    textAlign: 'center',
    '.MuiTypography-root': {
        color: getColor(theme.palette.neutralDark1),
    },
}));
const EntityCounter = ({ number, label }) => {
    return (_jsxs(ReactionsContainer, { children: [_jsx(Typography, { variant: "h2", children: formatThousands(number) }), _jsx(Typography, { variant: "h6", children: label })] }));
};
export default EntityCounter;
