import { useEffect } from 'react';
import { LEFT_SIDE_ACTIONS_ID, ON_WRAP_CLASS, RIGHT_SIDE_ACTIONS_ID } from './consts';
const wrapObserver = new ResizeObserver(() => {
    const [left, right] = [LEFT_SIDE_ACTIONS_ID, RIGHT_SIDE_ACTIONS_ID].map(document.getElementById, document);
    if (left && right) {
        left.classList[left.offsetTop !== right.offsetTop ? 'add' : 'remove'](ON_WRAP_CLASS);
    }
});
const useWrapObserver = (id) => useEffect(() => {
    const formFooter = document.getElementById(id);
    if (formFooter) {
        wrapObserver.observe(formFooter);
        return () => wrapObserver.unobserve(formFooter);
    }
}, []);
export default useWrapObserver;
