import { jsx as _jsx } from "react/jsx-runtime";
import { ModalContent } from '@profesia/adamui/components/common/modal';
import Header from '@profesia/mark-offer-form/components/Layout/Header';
import { useOptionsContext } from '@profesia/mark-offer-form/services/OptionsContext';
import { useTranslationInPageLanguage } from '@profesia/mark-offer-form/i18n';
const Layout = ({ content, onClose }) => {
    const t = useTranslationInPageLanguage('common.actions');
    const { isEditMode } = useOptionsContext();
    return (_jsx(ModalContent, { closeButtonText: t('cancel'), spacing: 0, content: content, onClose: onClose, showHeaderInModal: !isEditMode, header: isEditMode ? null : _jsx(Header, {}) }));
};
export default Layout;
