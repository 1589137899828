import { getColor } from '@profesia/adamui/styles/colors';
const getBorder = (type, color) => ({
    [`${type}Width`]: '1px',
    [`${type}Style`]: 'solid',
    [`${type}Color`]: getColor(color),
});
export const getColorStyling = ({ hover, active, icon, font, borderLeft, borderRight, }) => ({
    ...(borderLeft ? getBorder('borderLeft', borderLeft) : {}),
    ...(borderRight ? getBorder('borderRight', borderRight) : {}),
    color: getColor(font),
    '&:hover': {
        backgroundColor: getColor(hover),
    },
    '&:active': {
        backgroundColor: getColor(active),
    },
    '& .MuiSvgIcon-root': {
        color: getColor(icon),
    },
});
