import { jsx as _jsx } from "react/jsx-runtime";
import { useFormContext, useWatch } from 'react-hook-form';
import HiddenFields from '@profesia/adamui/components/common/HiddenFields';
import { InputField } from '@profesia/adamui/components/common/input';
import { useGetFixedT } from '@profesia/mark-offer-form/i18n';
import { trackEvent } from '@profesia/mark-offer-form/utils/track/track';
import { LOCATION_JOB_AD, NAME_REFERENCE_NUMBER, } from '@profesia/mark-offer-form/utils/track/constants';
import { FORM_REFERENCE_NUMBER } from '@profesia/mark-offer-form/components/content/form/valuePaths';
import { NARROW_INPUT_WIDTH, REFERENCE_NUMBER_MAX_LENGTH, } from '@profesia/mark-offer-form/components/content/form/constants';
const ReferenceNumber = () => {
    const { setValue, control } = useFormContext();
    const value = useWatch({ name: FORM_REFERENCE_NUMBER, control });
    const t = useGetFixedT('jobSettings.referenceNumber');
    return (_jsx(HiddenFields, { buttonLabel: t('label'), children: _jsx(InputField, { label: t('label'), value: value, inputProps: { maxLength: REFERENCE_NUMBER_MAX_LENGTH }, inputWidth: NARROW_INPUT_WIDTH, onChange: ({ target }) => setValue(FORM_REFERENCE_NUMBER, target.value) }), onClick: () => trackEvent(LOCATION_JOB_AD, NAME_REFERENCE_NUMBER, null) }));
};
export default ReferenceNumber;
