import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Stack } from '@mui/material';
import { useBreakpoint } from '@profesia/adamui/styles/theme';
import { Typography, TypographySpan } from '@profesia/adamui/components/common/typography';
import { BulbIcon } from '@profesia/mark-offer-form/components/icons';
import Tooltip from './Tooltip';
const Content = ({ title, description }) => {
    const isMobile = useBreakpoint('sm', 'down');
    if (isMobile) {
        return null;
    }
    return (_jsx(Box, { display: "flex", gap: 1, children: _jsx(Tooltip, { tooltipContent: description, tooltipChildren: _jsx(BulbIcon, {}), noTooltipChildren: _jsxs(Stack, { gap: 0.5, children: [_jsx(TypographySpan, { fontWeight: "bold", color: "neutralDark4", children: title }), _jsx(Typography, { color: "neutralDark4", variant: "small", children: description })] }) }) }));
};
export default Content;
