import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconButton, Stack, styled } from '@mui/material';
import InputLabel from '@profesia/adamui/components/common/input/InputLabel';
import { ADD_BOOLEAN_FIELD_BUTTON, BOOLEAN_FIELD_STACK, } from '@profesia/adamui/components/common/input/selectors';
import { generateKey } from '@profesia/adamui/utils/input';
import { FilterOperator } from '@profesia/adamui/components/common/input/types';
import { AddIcon } from '@profesia/adamui/components/common/icons';
import Operator from '../Operator';
const BooleanFieldWrapper = ({ type, labelProps, values, onChange, Input, defaultValue, canClearFirstValue = false, limit, getOptions, isNotVisible, }) => {
    const updateState = ({ action, index, value }) => {
        if (!onChange) {
            return;
        }
        let newState;
        switch (action) {
            case 'add':
                newState = [
                    ...values,
                    { operator: FilterOperator.Or },
                    { ...defaultValue },
                ];
                break;
            case 'remove':
                if (canClearFirstValue && index === 0) {
                    newState =
                        values.length === 1
                            ? [defaultValue]
                            : values.filter(({ operator }, idx) => !(index === idx || (index + 1 === idx && operator)));
                }
                else {
                    newState = values.filter(({ operator }, idx) => !((index - 1 === idx && operator) || index === idx));
                }
                break;
            case 'updateIsNot':
                newState = values.map((item, idx) => {
                    return idx === index ? { ...item, ...value } : item;
                });
                break;
            default:
                newState = values.map((item, idx) => idx === index ? { ...item, ...value } : item);
        }
        onChange(newState);
    };
    return (_jsx(InputLabel, { ...labelProps, children: _jsxs(Stack, { spacing: 0.5, "data-testid": BOOLEAN_FIELD_STACK, children: [values.map((item, idx) => item.operator ? (_jsx(Operator, { value: item.operator, onChange: ({ target }) => updateState({
                        action: 'update',
                        index: idx,
                        value: { operator: target.value },
                    }) }, generateKey(idx, type))) : (_jsx(Input, { index: idx, value: item, isNotVisible: isNotVisible, isNotHandler: (value) => {
                        updateState({
                            action: 'updateIsNot',
                            index: idx,
                            value: { isNot: value },
                        });
                    }, onChange: (value) => updateState({
                        action: 'update',
                        index: idx,
                        value,
                    }), onRemove: () => updateState({ action: 'remove', index: idx }), getOptions: getOptions }, generateKey(idx, type)))), !limit || (values.length + 1) / 2 !== limit ? (_jsx(AddButton, { "data-testid": ADD_BOOLEAN_FIELD_BUTTON, onClick: () => updateState({ action: 'add' }), disableRipple: true, children: _jsx(AddIcon, {}) })) : null] }) }));
};
const AddButton = styled(IconButton)(() => ({
    width: 32,
    '&:hover': {
        backgroundColor: 'transparent',
    },
}));
export default BooleanFieldWrapper;
