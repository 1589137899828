import { jsx as _jsx } from "react/jsx-runtime";
import Option from '@profesia/mark-offer-form/components/content/common/useAsyncOptionsTagFieldAdapter/Option';
import PaperComponent from '@profesia/mark-offer-form/components/content/common/useTagFieldAdapter/Paper';
const useTagFieldAdapter = (values, onChange) => {
    return {
        onChange: (newValues) => {
            onChange(newValues);
        },
        value: values,
        optionsProps: {
            PaperComponent,
            getOptionLabel: (option) => option.value,
            renderOption: (props, option) => {
                return _jsx(Option, { option: option, liProps: props }, option.id);
            },
        },
    };
};
export default useTagFieldAdapter;
