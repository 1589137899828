import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, styled, Box as BoxUI } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { tabletDown } from '@profesia/adamui/styles/theme';
import { getError, ValidationMessage, } from '@profesia/mark-offer-form/components/content/form';
import { Currency, Period, Range, Switcher } from '../../form';
const RangeContent = () => {
    const { formState: { errors }, } = useFormContext();
    const error = getError(errors.salary?.from) || getError(errors.salary?.to);
    return (_jsxs(Stack, { gap: 1.5, mb: 1, pt: 1.5, children: [_jsxs(Stack, { children: [_jsxs(StyledStack, { children: [_jsx(Range, {}), _jsxs(Stack, { gap: 1, flexDirection: "row", children: [_jsx(Currency, {}), _jsx(Period, {})] })] }), _jsx(Box, { children: _jsx(ValidationMessage, { error: error }) })] }), _jsx(Switcher, {})] }));
};
const StyledStack = styled(Stack)(({ theme: { spacing } }) => ({
    flexDirection: 'row',
    gap: spacing(1),
    [tabletDown]: {
        flexDirection: 'column',
    },
}));
const Box = styled(BoxUI)(({ theme: { spacing } }) => ({
    [tabletDown]: {
        marginTop: spacing(1),
    },
}));
export default RangeContent;
