import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack } from '@mui/material';
import { TagField } from '../tagField';
import SkillList from './SkillList';
import useTagFieldProps from './useTagFieldProps';
const ComboTagger = ({ limit, options, favouriteItems, allowEnter, onChange, values, optionsProps, levelOptions, errors, label, minimalInputLength, allowFreeValue, isHighlightingEnabled, iconsById, requiredSkillLevel, skillErrorClassName, onFavouriteItemClick, displayUnwrapped, }) => {
    const tagFieldProps = useTagFieldProps(values, onChange, options, favouriteItems, allowFreeValue, onFavouriteItemClick, optionsProps);
    return (_jsxs(Stack, { gap: 1.5, children: [_jsx(TagField, { shouldCloseOnLimitReached: true, filterSelectedOptions: true, limit: limit, allowEnter: allowEnter, errorMessage: errors?.inputError, label: label, minimalInputLength: minimalInputLength, isHighlightingEnabled: isHighlightingEnabled, size: "large", displayUnwrapped: displayUnwrapped, ...tagFieldProps }), _jsx(SkillList, { values: values, options: levelOptions, onChange: onChange, errors: errors?.skillErrors, iconsById: iconsById, required: requiredSkillLevel, errorClassName: skillErrorClassName })] }));
};
export default ComboTagger;
