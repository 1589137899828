import { jsx as _jsx } from "react/jsx-runtime";
import { Select, styled } from '@mui/material';
import { getColor } from '@profesia/adamui/styles/colors';
import { getOption } from '@profesia/adamui/components/common/dropdown/helpers';
const DropdownButton = ({ value, options, type = 'small', styleVariant = 'primary', ...otherProps }) => {
    return (_jsx(DropdownSelect, { value: value, className: `MuiDropDownButton-${type} MuiDropDownButton-${styleVariant}`, MenuProps: type === 'small'
            ? {
                classes: { list: 'menu-small' },
            }
            : {}, ...otherProps, children: options.map((option, index) => getOption(option, index)) }));
};
const DropdownSelect = styled(Select)(({ theme: { palette, typography, spacing } }) => {
    const primaryOrange = getColor(palette.primaryOrange);
    const extendedOchre4 = getColor(palette.extendedOchre4);
    const neutralLight2 = getColor(palette.neutralLight2);
    return {
        width: 'fit-content',
        '&.MuiDropDownButton-primary': {
            backgroundColor: primaryOrange,
            '&:hover': {
                background: `linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), ${primaryOrange}`,
            },
            '&.MuiDropDownButton-small': {
                backgroundColor: extendedOchre4,
                '&:hover': {
                    background: `linear-gradient(0deg, rgba(33, 44, 61, 0.1), rgba(33, 44, 61, 0.1)), ${extendedOchre4}`,
                },
            },
        },
        '&.MuiDropDownButton-secondary': {
            '&.MuiDropDownButton-small': {
                backgroundColor: neutralLight2,
                '&:hover': {
                    background: `linear-gradient(0deg, rgba(33, 44, 61, 0.05), rgba(33, 44, 61, 0.05)), ${neutralLight2}`,
                },
            },
        },
        '&.MuiDropDownButton-medium': {
            height: 40,
            '.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input': {
                padding: spacing(1.25, 4, 1.25, 1.75),
            },
            '.MuiSelect-icon': {
                right: 10,
            },
        },
        '&.MuiDropDownButton-small': {
            height: 32,
            width: 57,
            '.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input': {
                padding: spacing(0.75, 2, 0.75, 1),
            },
            '.MuiSelect-icon': {
                right: 2,
            },
        },
        '.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input': {
            fontSize: typography.body2.fontSize,
            fontWeight: 500,
        },
        '.MuiSelect-icon': {
            color: getColor(palette.primaryGray),
        },
        borderRadius: 4,
        border: '1px solid rgba(33, 44, 61, 0.2)',
        '.MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
    };
});
export default DropdownButton;
