import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, styled } from '@mui/material';
import { UserMulticolorIcon } from '@profesia/adamui/components/common/icons';
import { getColor } from '@profesia/adamui/styles/colors';
import { TypographySpan } from '@profesia/adamui/components/common/typography';
const LastLogin = ({ lastLogin }) => {
    if (!lastLogin) {
        return null;
    }
    return (_jsxs(LastLoginWrapper, { children: [_jsx(UserMulticolorIcon, {}), _jsx(TypographySpan, { fontSize: 12, fontWeight: "medium", color: "primaryNavy", children: lastLogin })] }));
};
const LastLoginWrapper = styled(Box)(({ theme: { palette, spacing } }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: spacing(0.25),
    padding: spacing(0, 0.5),
    borderColor: getColor(palette.secondaryViolet),
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 4,
    height: 20,
}));
export default LastLogin;
