import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { Box, styled } from '@mui/material';
import { CheckIcon, CloseIcon, ErrorIcon, InfoIcon, } from '@profesia/adamui/components/common/icons';
import { TypographySpan } from '@profesia/adamui/components/common/typography';
import { SmartLink } from '@profesia/adamui/components/common/Link';
import { getColor } from '@profesia/adamui/styles/colors';
import { CLOSE_TOAST, ERROR_ACTION, TOAST_CONTENT } from './testIds';
const ToastContent = React.forwardRef(function ToastContent(props, ref) {
    const { variant, autoHideDuration, onClose, message } = props;
    const { color, icon, additionalContent = () => null } = variantMappedToastProps[variant];
    useEffect(() => {
        if (autoHideDuration) {
            const closeTimer = setTimeout((event) => {
                onClose(event, 'timeout');
            }, autoHideDuration);
            return () => clearTimeout(closeTimer);
        }
    }, []);
    return (_jsx(ToastContentContainer, { color: color, ref: ref, gap: 1, "data-testid": TOAST_CONTENT, children: _jsxs(_Fragment, { children: [icon, _jsx(TypographySpan, { my: "auto", color: "white", fontWeight: "medium", children: message }), additionalContent(props)] }) }));
});
const variantMappedToastProps = {
    success: {
        color: 'extendedGreen2',
        icon: _jsx(CheckIcon, {}),
    },
    successConfirm: {
        color: 'extendedGreen2',
        icon: _jsx(CheckIcon, {}),
        additionalContent: ({ onClose = () => { } }) => (_jsx(CloseIcon, { "data-testid": CLOSE_TOAST, color: "white", onClick: (event) => onClose(event, 'escapeKeyDown') })),
    },
    info: {
        color: 'extendedRoyal4',
        icon: _jsx(InfoIcon, { color: "white" }),
    },
    error: {
        color: 'secondaryRed',
        icon: _jsx(ErrorIcon, {}),
        additionalContent: ({ errorAction }) => errorAction ? (_jsx(SmartLink, { "data-testid": ERROR_ACTION, variantType: "white", typographyProps: { pt: 0.25, component: 'span' }, ...errorAction })) : null,
    },
};
const ToastContentContainer = styled(Box, {
    shouldForwardProp: (propName) => propName !== 'color',
})(({ color, theme: { palette, spacing } }) => ({
    display: 'flex',
    minWidth: 200,
    minHeight: 24,
    borderRadius: 4,
    backgroundColor: getColor(palette[color]),
    padding: spacing(1.5),
    boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.25)',
    '& .MuiSvgIcon-Close': {
        marginLeft: 'auto',
        paddingTop: spacing(0.125),
        cursor: 'pointer',
    },
}));
export default ToastContent;
