import { useFormContext, useWatch } from 'react-hook-form';
;
import { FORM_SALARY } from '@profesia/mark-offer-form/components/content/form/valuePaths';
import { DEFAULT_FIXED_SALARY_PREVIEW, DEFAULT_RANGE_SALARY_PREVIEW, } from '@profesia/mark-offer-form/components/content/form/defaultValues';
const useGetSalaryPreviewProps = (type, isSelected) => {
    const { control } = useFormContext();
    const currentSalary = useWatch({ name: FORM_SALARY, control });
    if (checkIsSalaryFilled(currentSalary, type)) {
        return [modifyToVisibility(currentSalary), false];
    }
    if (isSelected) {
        return [
            type === 'range'
                ? modifyToVisibility({
                    ...DEFAULT_RANGE_SALARY_PREVIEW,
                    showTo: currentSalary?.showTo ?? false,
                })
                : DEFAULT_FIXED_SALARY_PREVIEW,
            false,
        ];
    }
    return [type === 'range' ? DEFAULT_RANGE_SALARY_PREVIEW : DEFAULT_FIXED_SALARY_PREVIEW, true];
};
const checkIsSalaryFilled = (salary, targetType) => {
    if (!salary) {
        return false;
    }
    const { type, value, from } = salary;
    return type === targetType && ((type === 'fixed' && value) || (type === 'range' && from));
};
const modifyToVisibility = (salary) => {
    if (salary.type !== 'range') {
        return salary;
    }
    const { showTo, ...otherSalary } = salary;
    return {
        ...otherSalary,
        ...(showTo ? {} : { to: null }),
    };
};
export default useGetSalaryPreviewProps;
