import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { tabletDown } from '@profesia/adamui/styles/theme';
import EducationSpecialization from './EducationSpecialization';
import EducationLevel from './EducationLevel';
const Education = ({ educationLevelErrorMessage }) => {
    return (_jsxs(_Fragment, { children: [_jsx(EducationLevelBox, { children: _jsx(EducationLevel, { errorMessage: educationLevelErrorMessage }) }), _jsx(Box, { pt: 2, children: _jsx(EducationSpecialization, {}) })] }));
};
const EducationLevelBox = styled(Box)(() => ({
    width: '440px',
    [tabletDown]: {
        width: '100%',
    },
}));
export default Education;
