import { jsx as _jsx } from "react/jsx-runtime";
import { CandidateVariant } from '@profesia/adamui/components/common/card/types';
import FilterOrJob from './variants/FilterOrJob';
import Candidate from './variants/Candidate';
const candidateVariants = [
    CandidateVariant.availableCandidate,
    CandidateVariant.unavailableCandidate,
];
const CardFooter = ({ id, variant, content, actions }) => candidateVariants.includes(variant) ? (_jsx(Candidate, { id: id, variant: variant, content: content })) : (_jsx(FilterOrJob, { id: id, variant: variant, content: content, actions: actions }));
export default CardFooter;
