import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Stack } from '@mui/material';
import Label from '@profesia/adamui/components/common/Label';
import { FORM_SALARY_FROM, } from '@profesia/mark-offer-form/components/content/form/valuePaths';
import { useGetFixedT } from '@profesia/mark-offer-form/i18n';
const RangeInputLabel = ({ valuePath, isError = false }) => {
    const t = useGetFixedT('salaryType.range');
    return (_jsx(_Fragment, { children: valuePath === FORM_SALARY_FROM ? (_jsxs(Stack, { gap: 0.5, direction: 'row', children: [_jsx(Label, { error: isError, children: t('from') }), _jsx(Label, { required: true, error: isError, fontWeight: 400, children: t('basicGrossPay') })] })) : (_jsx(Label, { error: isError, required: true, children: t('to') })) }));
};
export default RangeInputLabel;
