import { jsx as _jsx } from "react/jsx-runtime";
import WysiwygWithAi from '@profesia/mark-offer-form/components/content/common/WysiwygWithAi';
import { ComponentTypeEnum, trackEvent } from '@profesia/mark-offer-form/utils/track/track';
import { ACTION_RECOMMENDED_TAG, LOCATION_AI_WIZARD, LOCATION_JOB_AD, NAME_BENEFITS, NAME_SALARY_ADDITIONAL_INFO, } from '@profesia/mark-offer-form/utils/track/constants';
import { useGetTags } from '@profesia/mark-offer-form/codebooks/tags';
const Benefits = () => {
    const tags = useGetTags();
    return (_jsx(WysiwygWithAi, { field: "benefits", onAiOptionClick: (tone, firstUse) => trackEvent(LOCATION_AI_WIZARD, NAME_BENEFITS, firstUse ? ComponentTypeEnum.buttonNewOption : ComponentTypeEnum.buttonRewrite, tone), onAiDropdownButtonClick: () => trackEvent(LOCATION_AI_WIZARD, NAME_BENEFITS, ComponentTypeEnum.buttonField), onToolbarButtonClick: (buttonName) => trackEvent(LOCATION_JOB_AD, NAME_BENEFITS, ComponentTypeEnum.buttonField, buttonName), onTagClick: (tag) => trackEvent(LOCATION_JOB_AD, NAME_SALARY_ADDITIONAL_INFO, null, ACTION_RECOMMENDED_TAG, tag), tags: tags }));
};
export default Benefits;
