import React from 'react';

const JobStatisticsSkeleton = () => (
    <div className="list">
        <div className="list__data skeleton">
            <div className="ui grid">
                <div className="sixteen wide column">
                    <div className="row">
                        <h4>Dorobit skeleton</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default JobStatisticsSkeleton;
