import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Stack } from '@mui/material';
import { useGetFixedT } from '@profesia/adamui/configuration';
import { CardSkill } from '@profesia/adamui/components/common/card';
import { createSkillListErrorsMap } from '../helpers';
const SkillList = ({ values, options, iconsById, onChange, errors, required, errorClassName, }) => {
    const t = useGetFixedT('common.comboTagger.skillList');
    const placeholder = t('placeholder');
    const errorsByIndex = useMemo(() => createSkillListErrorsMap(errors), [errors]);
    const onLevelChange = (level, index) => {
        const valuesCopy = [...values];
        valuesCopy[index] = { ...valuesCopy[index], level };
        onChange(valuesCopy);
    };
    const onRemove = (index) => onChange(values.filter((_, currentIndex) => currentIndex !== index));
    return (_jsx(Stack, { gap: 1, children: values.map(({ id, value, level }, index) => {
            const errorMessage = errorsByIndex?.get(index);
            return (_jsx(CardSkill, { placeholder: placeholder, label: value, levelOptions: options, level: level, onLevelChange: (level) => onLevelChange(level, index), onRemove: () => onRemove(index), Icon: iconsById?.get(id), errorMessage: errorMessage, className: errorMessage ? errorClassName : undefined, required: required }, value));
        }) }));
};
export default SkillList;
