import { jsx as _jsx } from "react/jsx-runtime";
import { Box, styled } from '@mui/material';
import { DropdownButton } from '@profesia/adamui/components/common/dropdown';
const options = [
    {
        id: 'is',
        label: 'IS',
    },
    {
        id: 'isNot',
        label: 'NOT',
    },
];
const IsNotDropDown = ({ onClick, value }) => (_jsx(StyledDropdownButtonWrapper, { children: _jsx(DropdownButton, { fullWidth: true, styleVariant: "secondary", value: value, type: "small", options: options, onChange: ({ target: { value } }) => {
            onClick(value === 'isNot');
        } }) }));
const StyledDropdownButtonWrapper = styled(Box)(() => ({
    marginRight: -3,
    '& > .MuiInputBase-root': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
    },
}));
export default IsNotDropDown;
