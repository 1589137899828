import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormContext, useWatch } from 'react-hook-form';
import Stack from '@mui/material/Stack';
import { Checkbox } from '@profesia/adamui/components/common/input';
import Label from '@profesia/adamui/components/common/Label';
import { useGetFixedT } from '@profesia/mark-offer-form/i18n';
import { FORM_IS_APPLICABLE_WITHOUT_CV } from '@profesia/mark-offer-form/components/content/form/valuePaths';
const ApplicationWithoutCv = () => {
    const { setValue, control } = useFormContext();
    const isChecked = useWatch({ name: FORM_IS_APPLICABLE_WITHOUT_CV, control });
    const t = useGetFixedT('jobSettings.applicationWithoutCv');
    const handleCheckboxChange = (event) => {
        const { checked } = event.target;
        setValue(FORM_IS_APPLICABLE_WITHOUT_CV, checked);
    };
    return (_jsxs(Stack, { gap: 0.5, alignItems: "start", "data-testid": "application-without-cv-stack", children: [_jsx(Label, { children: t('label') }), _jsx(Checkbox, { label: t('value'), variant: "highlighted", checked: isChecked, onChange: handleCheckboxChange })] }));
};
export default ApplicationWithoutCv;
