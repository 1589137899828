import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Box, Paper as MuiPaper, styled } from '@mui/material';
import { useGetFixedT } from '@profesia/adamui/configuration';
import FavouriteItems from './FavouriteItems';
import Title from './Title';
const Paper = ({ children, favouriteItems, onFavouriteItemClick, ...otherProps }) => {
    const t = useGetFixedT('common.comboTagger');
    const [props, title] = useMemo(() => document.querySelector('#free-value') || !favouriteItems?.length
        ? [undefined, null]
        : [{ pt: 2 }, _jsx(Title, { paddingLeft: 1, title: t('otherItems') })], [children, favouriteItems?.length]);
    return (_jsxs(StyledPaper, { ...otherProps, children: [_jsx(FavouriteItems, { favouriteItems: favouriteItems, onFavouriteItemClick: onFavouriteItemClick }), _jsxs(Box, { p: 1, ...props, children: [title, children] })] }));
};
const StyledPaper = styled(MuiPaper)(({ theme: { spacing } }) => ({
    marginTop: spacing(0.25),
    '.MuiAutocomplete-listbox': {
        padding: 0,
    },
}));
export default Paper;
