import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { TextField as MuiTextField, styled, Stack } from '@mui/material';
import { getColor } from '@profesia/adamui/styles/colors';
import InputLabel from '@profesia/adamui/components/common/input/InputLabel';
import { INPUT_FIELD_WRAPPER } from '@profesia/adamui/components/common/input/selectors';
import RemoveInputButton from '@profesia/adamui/components/common/input/RemoveInputButton';
import IsNotDropDown from '@profesia/adamui/components/common/input/textField/InputField/isNotDropDown';
import BottomLabel from '@profesia/adamui/components/common/input/textField/BottomLabel';
const TextField = styled(MuiTextField, {
    shouldForwardProp: (prop) => !['isNotVisible', 'variant', 'height', 'isError'].includes(prop),
})(({ theme: { palette, spacing, typography }, isNotVisible, isError, height }) => {
    const notButtonVisibleStyles = isNotVisible
        ? {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4,
            zIndex: -1,
            backgroundColor: getColor(palette.white),
        }
        : {};
    return {
        '&.MuiFormControl-root': {
            borderRadius: spacing(1),
        },
        '&.MuiInputField-light': {
            backgroundColor: isNotVisible ? 'transparent!important' : getColor(palette.white),
        },
        '&.MuiInputField-dark': {
            backgroundColor: isNotVisible
                ? 'transparent!important'
                : getColor(palette.neutralLight0),
        },
        '.MuiOutlinedInput-root': {
            height: height ?? 32,
            '&:hover': {
                fieldset: {
                    borderColor: getColor(isError ? palette.secondaryRed : palette.primaryBlue),
                },
            },
            '&.Mui-focused': {
                color: getColor(palette.primaryNavy),
                boxShadow: '0px 0px 0px 2px rgba(39, 116, 240, 0.15)',
                '&:not(:hover)': {
                    fieldset: {
                        borderColor: isError ? getColor(palette.secondaryRed) : undefined,
                    },
                },
            },
            '&.Mui-disabled': {
                borderColor: getColor(palette.black5),
                backgroundColor: getColor(palette.neutralLight0),
                fieldset: {
                    borderColor: getColor(palette.black5),
                },
                '&:hover': {
                    fieldset: {
                        borderColor: getColor(palette.black5),
                    },
                },
            },
            fieldset: {
                ...notButtonVisibleStyles,
                borderWidth: '1px!important',
                borderColor: getColor(isError ? palette.secondaryRed : palette.neutralLight5),
            },
            '.MuiOutlinedInput-input': {
                padding: spacing(0.563, 1.5),
                '&::placeholder': {
                    color: getColor(palette.black3),
                    fontSize: typography.body2.fontSize,
                    opacity: 1,
                },
            },
        },
        '.MuiFormHelperText-root': {
            marginLeft: 0,
            fontSize: typography.body2.fontSize,
        },
        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            display: 'none',
        },
    };
});
const InputField = forwardRef(({ variant = 'light', inputFieldLabelProps, label, onRemove, isNotHandler, isNotVisible, isNotValue, bottomLabel, isError, inputWidth, ...otherProps }, ref) => {
    return (_jsxs(Stack, { gap: 0.5, children: [_jsx(InputLabel, { ...inputFieldLabelProps, label: label, children: _jsxs(Stack, { direction: "row", alignItems: "center", children: [isNotVisible && isNotHandler ? (_jsx(IsNotDropDown, { value: isNotValue ? 'isNot' : 'is', onClick: (value) => isNotHandler(value) })) : null, _jsxs(Stack, { direction: "row", alignItems: "center", spacing: 1, width: inputWidth ?? '100%', children: [_jsx(TextField, { isNotVisible: isNotVisible, inputRef: ref, className: `MuiInputField-${variant} ${INPUT_FIELD_WRAPPER}`, inputProps: {
                                        className: 'input-field',
                                    }, isError: isError, ...otherProps }), onRemove ? _jsx(RemoveInputButton, { onClick: onRemove }) : null] })] }) }), _jsx(BottomLabel, { error: isError, children: bottomLabel })] }));
});
export default InputField;
