import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import createSvgIcon from '@profesia/adamui/components/common/icons/createSvgIcon';
const warningTypes = {
    standard: [
        _jsxs(_Fragment, { children: [_jsx("path", { opacity: "0.3", d: "M14.4912 0.903976C19.4779 0.0494122 23.8846 4.34575 23.9166 7.49591C23.9486 10.6461 20.3667 17.4222 24.4386 20.1237C28.5104 22.8252 30.6236 26.8335 27.1825 29.4006C23.7414 31.9677 15.4768 31.617 14.9286 27.7819C14.3804 23.9468 8.41923 25.8025 5.33374 22.7877C2.24825 19.7728 2.06785 16.1886 3.35898 10.5961C4.6501 5.00361 9.5045 1.75854 14.4912 0.903976Z", fill: "url(#paint0_linear_3566_4160)" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M6.6665 24.6666L15.9998 7.33325L25.3332 24.6666H6.6665V24.6666Z", fill: "white", stroke: "#E0523E", strokeWidth: "2", strokeLinecap: "square" }), _jsx("path", { d: "M15.9995 14V18", stroke: "#E0523E", strokeWidth: "2", strokeLinecap: "square" }), _jsx("circle", { cx: "15.9995", cy: "21", r: "1", fill: "#E0523E" }), _jsx("defs", { children: _jsxs("linearGradient", { id: "paint0_linear_3566_4160", x1: "26.4817", y1: "10.5922", x2: "5.90894", y2: "22.9535", gradientUnits: "userSpaceOnUse", children: [_jsx("stop", { stopColor: "#F5DEBB" }), _jsx("stop", { offset: "1", stopColor: "#F5B63E" })] }) })] }),
        32,
    ],
    large: [
        _jsxs(_Fragment, { children: [_jsx("path", { opacity: "0.3", d: "M21.7368 1.35596C29.2169 0.0741181 35.8268 6.51863 35.8749 11.2439C35.9229 15.9691 30.5501 26.1333 36.6578 30.1855C42.7656 34.2377 45.9353 40.2502 40.7737 44.1009C35.6121 47.9516 23.2152 47.4254 22.3929 41.6729C21.5705 35.9203 12.6289 38.7038 8.00061 34.1815C3.37238 29.6592 3.10177 24.2829 5.03846 15.8941C6.97515 7.50542 14.2568 2.63781 21.7368 1.35596Z", fill: "url(#paint0_linear_7977_1626)" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M10 37L24 11L38 37H10Z", fill: "white", stroke: "#E0523E", strokeWidth: "2", strokeLinecap: "square" }), _jsx("path", { d: "M24 21V28", stroke: "#E0523E", strokeWidth: "2", strokeLinecap: "square" }), _jsx("circle", { cx: "24", cy: "32", r: "1", fill: "#E0523E" }), _jsx("defs", { children: _jsxs("linearGradient", { id: "paint0_linear_7977_1626", x1: "39.7226", y1: "15.8882", x2: "8.8634", y2: "34.4303", gradientUnits: "userSpaceOnUse", children: [_jsx("stop", { stopColor: "#F5DEBB" }), _jsx("stop", { offset: "1", stopColor: "#F5B63E" })] }) })] }),
        48,
    ],
};
const Warning = ({ type = 'standard', ...otherProps }) => {
    const [path, size] = warningTypes[type];
    const Icon = createSvgIcon(path, 'Warning', {
        width: size,
        height: size,
        viewBox: `0 0 ${size} ${size}`,
        fill: 'none',
    });
    return _jsx(Icon, { ...otherProps });
};
export default Warning;
