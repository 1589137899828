import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormContext, useWatch } from 'react-hook-form';
import { Stack, styled } from '@mui/material';
import { FORM_SALARY_SHOW_TO } from '@profesia/mark-offer-form/components/content/form/valuePaths';
import { Typography } from '@profesia/adamui/components/common/typography';
import { Switcher as CommonSwitcher } from '@profesia/adamui/components/common/switcher';
import { useGetFixedT } from '@profesia/mark-offer-form/i18n';
const Switcher = () => {
    const t = useGetFixedT('salaryType.range');
    const { setValue, control } = useFormContext();
    const showTo = useWatch({ name: FORM_SALARY_SHOW_TO, control, defaultValue: false });
    return (_jsxs(SwitcherContainer, { onClick: () => setValue(FORM_SALARY_SHOW_TO, !showTo), children: [_jsx(CommonSwitcher, { checked: showTo }), _jsx(Typography, { children: t('switcher') })] }));
};
const SwitcherContainer = styled(Stack)(({ theme: { spacing } }) => ({
    flexDirection: 'row',
    gap: spacing(1),
    alignItems: 'center',
    cursor: 'pointer',
}));
export default Switcher;
