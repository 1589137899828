import { useEffect, useRef } from 'react';
import scrollToFirstError from '../scrollToFirstError';
const useScrollToFirstError = (errors, submitCount) => {
    const submitCountInternal = useRef(0);
    useEffect(() => {
        if (Object.keys(errors).length > 0 && submitCount > submitCountInternal.current) {
            scrollToFirstError();
            submitCountInternal.current = submitCount;
        }
    }, [errors]);
};
export default useScrollToFirstError;
