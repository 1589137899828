export var Steps;
(function (Steps) {
    Steps[Steps["positionDescription"] = 0] = "positionDescription";
    Steps[Steps["order"] = 1] = "order";
})(Steps || (Steps = {}));
export var ContainerSize;
(function (ContainerSize) {
    ContainerSize["modal"] = "704px";
    ContainerSize["formContent"] = "860px";
})(ContainerSize || (ContainerSize = {}));
