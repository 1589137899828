export var Gender;
(function (Gender) {
    Gender["Female"] = "FEMALE";
    Gender["Hidden"] = "HIDDEN";
    Gender["Male"] = "MALE";
})(Gender || (Gender = {}));
export var SectionWrapperColorVariant;
(function (SectionWrapperColorVariant) {
    SectionWrapperColorVariant["Light"] = "light";
    SectionWrapperColorVariant["Dark"] = "dark";
})(SectionWrapperColorVariant || (SectionWrapperColorVariant = {}));
