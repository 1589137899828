import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CloseIcon } from '@profesia/adamui/components/common/icons';
import { TypographySpan } from '@profesia/adamui/components/common/typography';
import TagWrapper from './TagWrapper';
const Tag = ({ className, maxWidth, editable = false, onRemove, size = 'small', children, disabled, onClick, onMouseDown, }) => {
    const clickable = Boolean(onClick || onMouseDown);
    return (_jsxs(TagWrapper, { className: `${className ? className + ' ' : ''}${getTagClass(size, editable)}`, maxWidth: maxWidth, clickable: clickable, onClick: onClick, onMouseDown: onMouseDown, tabIndex: -1, children: [_jsx(TypographySpan, { children: children }), editable ? (_jsx(CloseIcon, { type: "small", onClick: (e) => {
                    e.stopPropagation();
                    if (!disabled) {
                        onRemove?.(e);
                    }
                } })) : null] }));
};
const getTagClass = (size, editable) => `Tag-${size}${editable ? '-editable' : ''}`;
export default Tag;
