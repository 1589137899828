import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled, alpha } from '@mui/material';
import { getColor } from '@profesia/adamui/styles/colors';
import { SadIcon } from '@profesia/adamui/components/common/icons';
import ButtonUniversal from './ButtonUniversal';
const Button = styled(ButtonUniversal)(({ theme: { palette: { primaryBlue, primaryNavy }, spacing }, }) => {
    const color = getColor(primaryNavy);
    const buttonColor = getColor(primaryBlue);
    return {
        color,
        flexShrink: 0,
        fontWeight: 500,
        gap: spacing(1),
        padding: spacing(7 / 8, 11 / 8),
        transition: 'none',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: spacing(0.5),
        minWidth: 'auto',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: alpha(buttonColor, 0.35),
        backgroundColor: alpha(buttonColor, 0.1),
        '&:hover': {
            backgroundColor: alpha(buttonColor, 0.15),
        },
        '&:focus': {
            backgroundColor: alpha(buttonColor, 0.2),
        },
    };
});
const ButtonUnsuitable = ({ children, ...otherProps }) => {
    return (_jsxs(Button, { ...otherProps, children: [children, _jsx(SadIcon, {})] }));
};
export default ButtonUnsuitable;
