import { jsx as _jsx } from "react/jsx-runtime";
import { Paper as MuiPaper, styled } from '@mui/material';
const Paper = ({ children }) => {
    return _jsx(StyledPaper, { children: children });
};
const StyledPaper = styled(MuiPaper)(({ theme: { spacing } }) => ({
    padding: spacing(0, 1),
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.16)',
}));
export default Paper;
