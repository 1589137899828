import { jsx as _jsx } from "react/jsx-runtime";
import { MenuItem, styled, Box } from '@mui/material';
import { Typography } from '@profesia/adamui/components/common/typography';
import OptionItem from '@profesia/adamui/components/common/OptionItem';
import { Checkbox } from '@profesia/adamui/components/common/input';
import Tooltip from '@profesia/adamui/components/common/Tooltip';
import { generateKey } from '@profesia/adamui/utils/input';
// @TODO mozno by bolo fajn vytvorit vlastny prefix pre custom cvdb className(s)
const getOption = ({ id, label, additionalLabel, isDisabled, tooltip }, index = 0, params) => {
    const { useCheckbox = false, value = '' } = params ?? {};
    const isPlaceholder = !id;
    if (isPlaceholder) {
        return (_jsx(MenuItem, { value: id, className: "select-placeholder", children: _jsx(Typography, { color: "black3", children: label }) }, generateKey(index, id)));
    }
    else {
        const props = useCheckbox
            ? {
                className: 'MuiMenuItem-checkbox',
                checkbox: _jsx(Checkbox, { checked: value?.indexOf(id) > -1 }),
            }
            : {
                additionalLabel,
            };
        const optionItem = (_jsx(OptionItem, { value: id, disabled: isDisabled, ...props, children: label }, generateKey(index, id)));
        return tooltip ? (_jsx(Tooltip, { tooltipContent: tooltip, variant: "content", placement: "right", children: _jsx(OptionItemWrapper, { isDisabled: isDisabled, children: optionItem }) }, `${id}-tooltip`)) : (optionItem);
    }
};
const OptionItemWrapper = styled(Box, {
    shouldForwardProp: (propName) => propName !== 'isDisabled',
})(({ isDisabled }) => ({
    cursor: isDisabled ? 'not-allowed' : 'auto',
}));
const getSelectedOptionLabel = (options, selectedOptionValue) => {
    const selectedOption = options.find((option) => option.id === selectedOptionValue && option.label);
    //@TODO toto bude treba upraviť pri CVDB-278
    return selectedOption ? selectedOption.label : '';
};
export { getSelectedOptionLabel, getOption };
