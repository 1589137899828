import { jsx as _jsx } from "react/jsx-runtime";
import { styled } from '@mui/material';
import { TreeItem as MuiTreeItem } from '@mui/lab';
import { CheckboxSquareIcon } from '@profesia/adamui/components/common/icons';
import useTagFieldContext from '@profesia/adamui/components/common/input/textField/tagField/hooks/useTagFieldContext';
import TooltipWrapper from '@profesia/adamui/components/common/treeList/TooltipWrapper';
import HighlightedLabel from './HighlightedLabel';
const Option = ({ option: { id, value, categoryId }, state: { inputValue, selected }, groupSize, tooltipContent, }) => {
    const { onChange, values, hasReachedLimit, groupCount, selectedGroup } = useTagFieldContext();
    const isLastFromGroup = !selected && selectedGroup && groupCount?.get(categoryId) === groupSize - 1;
    const isSelectable = (selected || !hasReachedLimit) && !isLastFromGroup;
    const iconState = selected ? 'checked' : 'unchecked';
    const icon = isSelectable ? (_jsx(CheckboxSquareIcon, { state: iconState, type: "primary" })) : (_jsx(TooltipWrapper, { tooltipContent: tooltipContent, children: _jsx(CheckboxSquareIcon, { state: iconState, type: "disabled" }) }));
    return (_jsx(TreeItem, { nodeId: id, isSelectable: isSelectable, label: _jsx(HighlightedLabel, { input: inputValue, label: value }), icon: icon, onClick: () => {
            if (isSelectable && categoryId) {
                const newValues = selected
                    ? values.filter((value) => value.id !== id)
                    : [...values, { id, value, categoryId }];
                onChange(newValues);
            }
        } }));
};
const TreeItem = styled(MuiTreeItem, {
    shouldForwardProp: (propName) => propName !== 'isSelectable',
})(({ isSelectable }) => ({
    '& .MuiTreeItem-content:hover': {
        cursor: isSelectable ? 'pointer' : 'not-allowed',
    },
}));
export default Option;
