import { Trans, useTranslation as i18UseTranslation } from 'react-i18next';
import i18n from 'i18next';
import { configure as configureAdamUI } from '@profesia/adamui/configuration';
import { useOptionsContext } from '@profesia/mark-offer-form/services/OptionsContext';
import { getConfiguration } from '@profesia/mark-offer-form/configuration';
export const initializeI18n = (language, translations) => {
    i18n.init({
        interpolation: { escapeValue: false },
        lng: language,
        fallbackLng: 'sk',
        resources: {
            [language]: { translation: translations },
        },
    });
    configureAdamUI({
        i18n: {
            useTranslation,
            useGetFixedT,
            translationComponent: Trans,
        },
    });
};
export function useTranslation() {
    const { t, i18n } = i18UseTranslation();
    return {
        t: (key, options) => t(key, options ?? {}),
        i18n,
    };
}
export function useGetFixedT(keyPrefix) {
    const { i18n } = useTranslation();
    return i18n.getFixedT(null, null, keyPrefix);
}
export function useGetLocale() {
    const { i18n } = useTranslation();
    return i18n.language;
}
export const useTranslationInPageLanguage = (prefix, language) => {
    const t = useGetFixedT(prefix ?? '');
    const { language: pageLanguage } = useOptionsContext();
    return (key, options) => t(key, {
        lng: (language ?? pageLanguage)?.toLowerCase(),
        ...options,
    });
};
export const loadAndChangeLanguage = async (locale) => {
    const { api } = getConfiguration();
    const localeLowerCase = locale.toLowerCase();
    if (!i18n.hasResourceBundle(localeLowerCase, 'translation')) {
        const { default: data } = await api.getTranslations(localeLowerCase);
        i18n.addResourceBundle(localeLowerCase, 'translation', { ...data }, true, true);
    }
    await i18n.changeLanguage(localeLowerCase);
};
export default i18n;
