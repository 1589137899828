import { useQuery } from '@tanstack/react-query';
import { getConfiguration } from '@profesia/mark-offer-form/configuration';
import { GET_CONTACT_PERSONS } from '../../keys';
export const useGetContactPersons = (companyId) => {
    const { api } = getConfiguration();
    return useQuery({
        queryKey: [GET_CONTACT_PERSONS],
        queryFn: async () => api.getContactPersons(companyId),
    });
};
