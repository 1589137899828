import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Stack as StackMui, styled } from '@mui/material';
import { getColor } from '@profesia/adamui/styles/colors';
import { BoldIcon, BulletListIcon, ItalicIcon, NumberedListIcon, RedoIcon, UndoIcon, } from '@profesia/adamui/components/common/icons';
import ToolbarButton from './ToolbarButton';
import AiDropdown from './AiDropdown';
import { useGetFixedT } from '@profesia/adamui/configuration';
const formatButtons = [
    ['format', 'bold', _jsx(BoldIcon, {}), 'toggleBold'],
    ['format', 'italic', _jsx(ItalicIcon, {}), 'toggleItalic'],
    ['format', 'orderedList', _jsx(NumberedListIcon, {}), 'toggleOrderedList'],
    ['format', 'bulletList', _jsx(BulletListIcon, {}), 'toggleBulletList'],
    ['history', 'undo', _jsx(UndoIcon, {}), 'undo'],
    ['history', 'redo', _jsx(RedoIcon, {}), 'redo'],
];
const Toolbar = ({ editor, translateFunction, onToolbarButtonClick, aiDropdownProps, }) => {
    const tFunction = translateFunction || ((prefix) => useGetFixedT(prefix));
    return (_jsxs(Stack, { children: [_jsx(Box, { children: formatButtons.map(([buttonType, titleKey, icon, command]) => {
                    return (_jsx(ToolbarButton, { editor: editor, titleKey: titleKey, icon: icon, command: command, translateFunction: tFunction, onToolbarButtonClick: onToolbarButtonClick, isActive: editor.isActive(titleKey), disabled: buttonType === 'history' &&
                            !(command === 'undo' ? editor.can().undo() : editor.can().redo()) }, titleKey));
                }) }), _jsx(AiDropdown, { value: editor.getHTML(), translateFunction: tFunction, aiDropdownProps: aiDropdownProps })] }));
};
const Stack = styled(StackMui)(({ theme: { palette, spacing } }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 40,
    borderColor: getColor(palette.neutralLight5),
    borderWidth: 1,
    borderBottomStyle: 'solid',
    paddingLeft: spacing(1),
}));
export default Toolbar;
