import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Box, Collapse, FormLabel, styled } from '@mui/material';
import Label from '@profesia/adamui/components/common/Label';
import { Typography } from '@profesia/adamui/components/common/typography';
import { LinkToAction } from '@profesia/adamui/components/common/Link';
const ExtendedLabel = ({ labelPlacementLeft, extendedProps = {}, label, }) => {
    const { keepOpenByDefault = false, currentOption = '', extendedButtonLabel = '', select = null, } = extendedProps;
    const [isOpen, setIsOpen] = useState(keepOpenByDefault);
    useEffect(() => {
        setIsOpen(keepOpenByDefault);
    }, [keepOpenByDefault, currentOption]);
    return (_jsxs(Box, { display: "flex", flexDirection: "column", justifyContent: "center", mb: labelPlacementLeft ? 0 : 0.5, children: [_jsxs(Box, { display: "flex", justifyContent: "space-between", alignItems: "center", height: 20, children: [_jsx(FormLabelContainer, { children: _jsx(FormLabel, { children: _jsxs(Label, { children: [label, _jsxs(Typography, { color: "neutralDark1", variant: "body2", ml: 0.5, component: "span", children: ["- ", currentOption ?? null] })] }) }) }), _jsx(LinkToAction, { onClick: () => setIsOpen(!isOpen), children: _jsx(Typography, { color: "primaryBlue", variant: "body2", fontWeight: "medium", whiteSpace: 'nowrap', children: extendedButtonLabel }) })] }), _jsx(Collapse, { in: isOpen, children: _jsx(Box, { mt: 0.5, children: select }) })] }));
};
const FormLabelContainer = styled(Label)(({ theme }) => ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingRight: theme.spacing(1.5),
}));
export default ExtendedLabel;
