import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Box, Collapse, Stack, styled } from '@mui/material';
import { getColor } from '@profesia/adamui/styles/colors';
import Reactions from '@profesia/adamui/components/common/card/CardJob/Reactions';
import EntityCounter from '@profesia/adamui/components/common/card/EntityCounter';
import { FilterOrJobVariant, } from '@profesia/adamui/components/common/card/CardFooter/types';
import CardFooter from '@profesia/adamui/components/common/card/CardFooter';
import HorizontalDivider from '@profesia/adamui/components/common/HorizontalDivider';
import CardContainer from '@profesia/adamui/components/common/card/CardContainer';
import CardTitleAndDescription from '@profesia/adamui/components/common/card/CardTitleAndDescription';
const CollapsibleContent = styled(Collapse)(({ theme: { palette } }) => ({
    '.MuiBox-root:first-of-type': {
        minHeight: 100,
        backgroundColor: getColor(palette.white),
        borderTopWidth: 1,
        borderTopColor: getColor(palette.neutralLight3),
        borderTopStyle: 'solid',
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
    },
}));
const CardJobContainer = styled(CardContainer)(({ theme: { spacing } }) => ({
    '&.MuiPaper-static': {
        padding: spacing(2, 3, 1.25, 3),
    },
    '&.MuiPaper-collapsible': {
        '& > .MuiBox-root': {
            padding: spacing(1.75, 3, 0.5, 3),
        },
    },
}));
const StaticContentContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
}));
const CardJob = ({ id, variant, title, reactions, candidates, collapsibleContent, footer, }) => {
    const [isExtended, setIsExtended] = React.useState(false);
    return (_jsxs(CardJobContainer, { variant: variant, children: [_jsxs(StaticContentContainer, { children: [_jsxs(Box, { display: "flex", children: [_jsx(CardTitleAndDescription, { ...title }), _jsxs(Stack, { direction: "row", spacing: 4, children: [_jsx(Reactions, { ...(variant === 'collapsible'
                                            ? {
                                                arrowOrientation: isExtended ? 'up' : 'down',
                                                onClick: () => setIsExtended(!isExtended),
                                            }
                                            : {}), ...reactions }), _jsx(EntityCounter, { ...candidates })] })] }), variant === 'static' ? (_jsxs(_Fragment, { children: [_jsx(HorizontalDivider, { mt: 1.125, mb: 1 }), _jsx(CardFooter, { id: id, variant: FilterOrJobVariant.openJob, ...footer })] })) : null] }), variant === 'collapsible' ? (_jsx(CollapsibleContent, { in: isExtended, children: _jsx(Box, { children: collapsibleContent }) })) : null] }));
};
export default CardJob;
