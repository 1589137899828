import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Box, styled } from '@mui/material';
import { TreeItem, TreeView as MuiTreeView } from '@mui/lab';
import { getColor } from '@profesia/adamui/styles/colors';
import { ChevronIcon } from '@profesia/adamui/components/common/icons';
import { TypographySpan } from '../typography';
import TreeListControlSymbol from './ControlSymbol';
const TreeGroup = ({ children, group, expanded = [], label, ...treeListControlSymbolProps }) => {
    const [toggledGroups, setToggledGroups] = useState([]);
    return (_jsx(StyledTreeView, { defaultExpandIcon: _jsx(ChevronIcon, {}), defaultCollapseIcon: _jsx(ChevronIcon, { variant: "down" }), expanded: toggledGroups.length > 0 ? toggledGroups : expanded, onNodeToggle: (_, nodeIds) => {
            setToggledGroups(nodeIds);
        }, children: _jsx(TreeItem, { nodeId: group, label: _jsxs(Box, { display: "flex", gap: 1, children: [_jsx(TreeListControlSymbol, { group: group, ...treeListControlSymbolProps }), _jsx(TypographySpan, { fontWeight: "bold", children: label })] }), children: children }) }));
};
export const StyledTreeView = styled(MuiTreeView)(({ theme: { spacing, palette } }) => ({
    flexGrow: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    '& [aria-expanded="true"], .MuiTreeItem-content.Mui-expanded.Mui-selected.Mui-focused': {
        backgroundColor: getColor(palette.neutralLight1),
    },
    '& [aria-expanded="false"]:hover': {
        backgroundColor: getColor(palette.neutralLight1),
    },
    '.MuiTreeItem-content.Mui-selected, .MuiTreeItem-content.Mui-selected.Mui-focused': {
        backgroundColor: 'transparent',
    },
    '& > .MuiTreeItem-root > .MuiTreeItem-content': {
        padding: spacing(1),
    },
    '& .MuiCollapse-wrapperInner .MuiTreeItem-content': {
        padding: spacing(1, 0, 1, 7.25),
    },
    '& .MuiTreeItem-content': {
        alignItems: 'flex-start',
        '& .MuiTreeItem-iconContainer': {
            marginTop: spacing(0.125),
        },
    },
    '& .MuiTreeItem-content .MuiTreeItem-iconContainer': {
        '& .close': {
            opacity: 0.3,
        },
        width: 'unset',
        marginRight: spacing(1),
    },
    '& .MuiTreeItem-group': {
        paddingLeft: 0,
        marginLeft: 0,
        position: 'relative',
        '&::before': {
            borderLeftWidth: 2,
            borderLeftStyle: 'dotted',
            borderLeftColor: getColor(palette.black4),
            top: '-0.6rem',
            bottom: '0.5rem',
            content: '""',
            left: 'calc(2.5rem)',
            position: 'absolute',
        },
    },
    '& .MuiTreeItem-content .MuiTreeItem-label': {
        paddingLeft: 0,
    },
}));
export default TreeGroup;
