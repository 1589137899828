import { jsx as _jsx } from "react/jsx-runtime";
import { Stack, styled } from '@mui/material';
import Toast from '@profesia/adamui/components/common/Toast';
const generateToastKey = (id) => `toast-${id}`;
const ToastWrapper = ({ oneToastAtATime = false, autoHideDuration = 5000, toasts, removeToast, }) => {
    if (!toasts.length) {
        return null;
    }
    if (oneToastAtATime) {
        const { id, toast } = toasts[0];
        return (_jsx(Toast, { autoHideDuration: autoHideDuration, onClose: () => removeToast(id), ...toast }, generateToastKey(id)));
    }
    return (_jsx(ToastStack, { children: toasts.map(({ toast, id }) => (_jsx(Toast, { isUsedInStack: true, autoHideDuration: autoHideDuration, onClose: () => removeToast(id), ...toast }, generateToastKey(id)))) }));
};
const ToastStack = styled(Stack)(({ theme: { spacing } }) => ({
    flexDirection: 'column-reverse',
    position: 'fixed',
    bottom: 0,
    right: 0,
    gap: spacing(2),
    margin: spacing(2, 2, 2, 0),
    zIndex: 1500,
}));
export default ToastWrapper;
