import { Box as MuiBox, styled } from '@mui/material';
import { getColor } from '@profesia/adamui/styles/colors';
const propsToFilter = ['clickable'];
const TagWrapper = styled(MuiBox, {
    shouldForwardProp: (prop) => !propsToFilter.includes(prop),
})(({ maxWidth = 'max-content', theme: { spacing, typography, palette, customComponents: { tag: { borderWidth }, }, }, clickable, }) => {
    return {
        boxSizing: 'border-box',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: getColor(palette.neutralLight1),
        borderWidth,
        borderStyle: 'solid',
        borderColor: getColor(palette.neutralLight4),
        borderRadius: 4,
        maxWidth,
        cursor: clickable ? 'pointer' : 'default',
        '.MuiSvgIcon-root': {
            color: getColor(palette.neutralDark2),
            cursor: 'pointer',
        },
        '.MuiTypography-root': {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        },
        '.Mui-focused &': {
            maxWidth: '100%',
        },
        '&.Tag-extra-small': {
            padding: spacing(0, 0.5),
            height: 20,
            '.MuiTypography-root': {
                fontSize: typography.small.fontSize,
            },
        },
        '&.Tag-small, &.Tag-small-editable': {
            height: 24,
        },
        '&.Tag-small': {
            padding: spacing(0, 1),
        },
        '&.Tag-small-editable': {
            padding: spacing(0, 0.5, 0, 1),
            gap: spacing(0.5),
        },
        '&.Tag-medium, &.Tag-medium-editable': {
            height: 32,
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: getColor(palette.neutralLight4),
                borderColor: getColor(palette.neutralLight5),
            },
            '&:focus': {
                backgroundColor: getColor(palette.primaryBlue),
                borderColor: getColor(palette.primaryBlue),
                '.MuiTypography-root': {
                    color: getColor(palette.white),
                },
                '.MuiSvgIcon-root': {
                    fill: getColor(palette.white),
                },
            },
        },
        '&.Tag-medium': {
            padding: spacing(0, 1),
            backgroundColor: getColor(palette.white),
        },
        '&.Tag-medium-editable': {
            padding: spacing(0, 1),
            gap: spacing(1),
        },
        '&.Tag-large-editable': {
            height: 36,
            padding: spacing(0, 1, 0, 1.5),
            gap: spacing(1),
        },
    };
});
export default TagWrapper;
