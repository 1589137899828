import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, styled } from '@mui/material';
import Tag from '@profesia/adamui/components/common/Tag';
import { MORE_TAGS } from '@profesia/adamui/components/common/input/selectors';
import { getTagSize } from './helpers';
const MoreTags = ({ more, size }) => {
    return (_jsxs(MoreTagsBox, { className: MORE_TAGS, children: [_jsx("span", { children: "..." }), _jsx(Tag, { editable: true, size: getTagSize(size), children: more })] }));
};
const MoreTagsBox = styled(Box, {
    shouldForwardProp: (propName) => propName !== 'hideCloseIcon',
})(({ hideCloseIcon = true, theme: { spacing } }) => ({
    cursor: 'pointer',
    display: 'flex',
    gap: spacing(0.5),
    '> span': {
        lineHeight: 1,
        marginTop: 'auto',
    },
    ...(hideCloseIcon
        ? {
            '.MuiSvgIcon-Close': {
                display: 'none',
            },
        }
        : {}),
}));
export default MoreTags;
