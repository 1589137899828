import { jsx as _jsx } from "react/jsx-runtime";
import useTagFieldContext from '@profesia/adamui/components/common/input/textField/tagField/hooks/useTagFieldContext';
import TreeGroup from '../TreeGroup';
import { MINIMAL_INPUT_LENGTH } from './index';
const getAllPositions = (startIndex, options) => {
    const category = options[startIndex].categoryId;
    const categoryPositions = [options[startIndex]];
    let index = startIndex + 1;
    while (options[index].categoryId === category) {
        categoryPositions.push(options[index]);
        index += 1;
    }
    return categoryPositions;
};
const getGroupId = (group) => `g-${group}`;
const Group = ({ children, group, groupData, options, tooltipContent }) => {
    const { input, values, onChange, groupCount, selectedGroup } = useTagFieldContext();
    const { name = group, startIndex } = groupData ?? {};
    const groupInternal = getGroupId(group);
    return (_jsx(TreeGroup, { expanded: input.length >= MINIMAL_INPUT_LENGTH ? [groupInternal] : undefined, group: groupInternal, label: name, hasSelectedOptions: (groupCount?.get(group) ?? 0) > 0, selectedGroup: selectedGroup ? getGroupId(selectedGroup) : null, onGroupSelect: (action) => {
            onChange(action === 'removeAll'
                ? values.filter(({ categoryId }) => categoryId !== group)
                : [...values, ...getAllPositions(startIndex, options)]);
        }, tooltipContent: tooltipContent, children: children }, groupInternal));
};
export default Group;
