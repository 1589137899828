import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createContext, useCallback, useReducer } from 'react';
import { ActionType } from './types';
import ToastWrapper from './ToastWrapper';
const initialState = {
    toasts: [],
    latestId: -1,
};
export const ToastContext = createContext(() => { });
const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ActionType.ADD_TOAST: {
            const id = state.latestId + 1;
            return {
                ...state,
                toasts: [...state.toasts, { toast: payload, id }],
                latestId: id,
            };
        }
        case ActionType.REMOVE_TOAST: {
            return {
                ...state,
                toasts: state.toasts.filter(({ id }) => id !== payload),
            };
        }
        default:
            throw new Error();
    }
};
const ToastProvider = ({ children, ...toastWrapperProps }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const addToast = useCallback((toast) => dispatch({ type: ActionType.ADD_TOAST, payload: toast }), [dispatch]);
    return (_jsxs(ToastContext.Provider, { value: addToast, children: [children, _jsx(ToastWrapper, { removeToast: (id) => dispatch({ type: ActionType.REMOVE_TOAST, payload: id }), ...state, ...toastWrapperProps })] }));
};
export default ToastProvider;
