import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, styled, Box } from '@mui/material';
import { Radio } from '@profesia/adamui/components/common/input/radioButtons';
import { getColor } from '@profesia/adamui/styles/colors';
import { Typography } from '@profesia/adamui/components/common/typography';
const CardItem = ({ title, description, isSelected, onClick }) => {
    return (_jsxs(StackStyled, { isSelected: isSelected, onClick: onClick, children: [_jsx(Box, { children: _jsx(Radio, { size: "medium", checked: isSelected }) }), _jsx(Box, { children: _jsxs(Stack, { children: [_jsx(TypographyStyled, { weight: "bold", children: title }), _jsx(TypographyStyled, { children: description })] }) })] }));
};
const StackStyled = styled(Box, { shouldForwardProp: (prop) => prop !== 'isSelected' })(({ theme: { palette, spacing }, isSelected }) => ({
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: getColor(palette.neutralLight1),
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: getColor(isSelected ? palette.primaryBlue : palette.black5),
    borderRadius: 4,
    padding: spacing(1.5, 1.5, 1),
    cursor: 'pointer',
}));
const TypographyStyled = styled(Typography)(({ theme: { palette } }) => ({
    fontSize: '14px',
    lineHeight: '20px',
    color: getColor(palette.primaryNavy),
}));
export default CardItem;
