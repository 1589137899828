import { jsx as _jsx } from "react/jsx-runtime";
import { SkFlag, EnFlag, CzFlag, DeFlag, HuFlag } from '@profesia/adamui/components/common/icons';
import { useGetFixedT } from '@profesia/mark-offer-form/i18n';
export var OfferLanguage;
(function (OfferLanguage) {
    OfferLanguage["En"] = "en";
    OfferLanguage["Cs"] = "cs";
    OfferLanguage["Sk"] = "sk";
    OfferLanguage["De"] = "de";
    OfferLanguage["Hu"] = "hu";
})(OfferLanguage || (OfferLanguage = {}));
const offerLanguageIds = Object.values(OfferLanguage);
export const useGetOfferLanguageOptions = () => {
    const t = useGetFixedT('common.codebooks.offerLanguage');
    const getIcon = (id) => {
        switch (id) {
            case OfferLanguage.En:
                return _jsx(EnFlag, {});
            case OfferLanguage.Cs:
                return _jsx(CzFlag, {});
            case OfferLanguage.Sk:
                return _jsx(SkFlag, {});
            case OfferLanguage.De:
                return _jsx(DeFlag, {});
            case OfferLanguage.Hu:
                return _jsx(HuFlag, {});
            default:
                return null;
        }
    };
    return offerLanguageIds.map((id) => ({ id, label: t(id), startIcon: getIcon(id) }));
};
