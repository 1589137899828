import { Stack, styled } from '@mui/material';
import { getColor } from '@profesia/adamui/styles/colors';
const TitleContainer = styled(Stack, {
    shouldForwardProp: (prop) => prop !== 'clickable',
})(({ onClick, theme }) => ({
    cursor: onClick ? 'pointer' : 'default',
    flex: '1 1 auto',
    flexDirection: 'column',
    '&:hover': onClick
        ? {
            backgroundColor: getColor(theme.palette.neutralLight2),
        }
        : {},
    justifyContent: 'center',
    '& > *': {
        margin: '0 auto',
    },
    '& .MuiSvgIcon-root': {
        ...theme.typography.icons.modalAction,
    },
}));
export default TitleContainer;
