import { GetCodebooksResponse } from '@profesia/mark-offer-form/services';
import { Languages } from '@profesia/mark-offer-form/i18n';
import { remapOptionItem, remapPositions } from './helpers';
import apiRequest from './apiRequest';
import { CodebookApiResponse } from './types';

const getCodebooks = async (locale: Languages): Promise<GetCodebooksResponse> => {
    const jsonResponse = await apiRequest<CodebookApiResponse>(`codebook?locale=${locale}`, {
        method: 'GET',
    });

    return {
        languages: {
            favourites: remapOptionItem(jsonResponse.languages.favourites),
            others: remapOptionItem(jsonResponse.languages.others),
        },
        regions: remapOptionItem(jsonResponse.regions),
        jobPositions: remapPositions(jsonResponse.positions),
    };
};

export default getCodebooks;
