import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Stack, styled } from '@mui/material';
import { getColor } from '@profesia/adamui/styles/colors';
import { tabletDown } from '@profesia/adamui/styles/theme';
import { useCodebooksContext } from '@profesia/mark-offer-form/services/CodebooksContext';
import { useOptionsContext } from '@profesia/mark-offer-form/services/OptionsContext';
import { useUpdateCodebookTranslations } from '@profesia/mark-offer-form/services/updateCodebookTranslations/useUpdateCodebookTranslations';
import useGetResolver from '../resolver';
import getSetValue from '../getSetValue';
import useScrollToFirstError from '../useScrollToFirstError';
import Skeleton from './Skeleton';
import Content from './Content';
const Form = forwardRef(({ isLoading, data }, ref) => {
    const { isLoading: isLoadingCodebooks } = useCodebooksContext();
    const { isEditMode } = useOptionsContext();
    const resolver = useGetResolver();
    const methods = useForm({
        resolver,
        defaultValues: data,
    });
    const { setValue: setFormValue, getValues, formState, clearErrors } = methods;
    const { submitCount, errors } = formState;
    const setValue = getSetValue(setFormValue, clearErrors, errors);
    useUpdateCodebookTranslations(setValue, getValues);
    useScrollToFirstError(errors, submitCount);
    return (_jsx(FormProvider, { ...methods, setValue: setValue, children: _jsx(ItemsStack, { isEditMode: isEditMode, children: isLoading || isLoadingCodebooks ? _jsx(Skeleton, {}) : _jsx(Content, { ref: ref }) }) }));
});
const ItemsStack = styled(Stack, { shouldForwardProp: (prop) => prop !== 'isEditMode' })(({ theme: { spacing, palette, breakpoints }, isEditMode }) => ({
    ...(!isEditMode
        ? {
            backgroundColor: getColor(palette.neutralLight0),
        }
        : {}),
    padding: !isEditMode ? spacing(0, 36.25, 0) : spacing(4),
    alignItems: 'center',
    [breakpoints.down('lg')]: {
        alignItems: 'start',
        paddingLeft: spacing(3),
    },
    [tabletDown]: {
        padding: 0,
        backgroundColor: 'white',
    },
}));
export default Form;
