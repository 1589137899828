/*
import { CodebookItem, CodebookItemWithTranslation } from '@profesia/adamui/codebook/types';
import { useTranslation } from '@profesia/adamui/i18n';
import { InputValue } from '@profesia/adamui/components/common/input/types';
import { AutocompleteItem } from '@profesia/adamui/types/entities/graphQL';

export const useCodebookValuesForInput = (
    items: (CodebookItemWithTranslation | CodebookItem | AutocompleteItem)[],
    translationKeyPrefix?: string
): InputValue[] => {
    const { t } = useTranslation();

    return items.map((item) => ({
        id: item.id,
        label:
            (item as CodebookItem)?.label ??
            t(`${translationKeyPrefix}.${(item as CodebookItemWithTranslation)?.stringKey}`),
    }));
};

const mapValuesForInput = (items: AutocompleteItem[]): InputValue[] =>
    items.map(({ id, value }) => ({
        id: id,
        label: value,
    }));
*/
export const generateKey = (index, value) => `${index}-${value}`;
//export { generateKey, useCodebookValuesForInput, mapValuesForInput };
