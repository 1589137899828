import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Box, styled } from '@mui/material';
import { useGetFixedT } from '@profesia/adamui/configuration';
import Tag from '@profesia/adamui/components/common/Tag';
import useTagFieldContext from '@profesia/adamui/components/common/input/textField/tagField/hooks/useTagFieldContext';
import { filterFavouriteItems, filterOptions } from '../../helpers';
import OptionLabel from '../../OptionLabel';
import Title from '../Title';
const FavouriteItems = ({ favouriteItems, onFavouriteItemClick }) => {
    const t = useGetFixedT('common.comboTagger');
    const { input, onChange, values, hasReachedLimit } = useTagFieldContext();
    const filteredItemsByValue = useMemo(() => filterFavouriteItems(values, favouriteItems), [values.length]);
    const filteredItemsByInput = useMemo(() => filterOptions(filteredItemsByValue, input), [filteredItemsByValue.length]);
    if (!filteredItemsByValue || !filteredItemsByValue.length) {
        return null;
    }
    const [onBoxMouseDown, onTagClick] = [
        (e) => e.preventDefault(),
        (favouriteItem) => {
            onFavouriteItemClick?.(favouriteItem);
            if (!hasReachedLimit) {
                onChange([...values, favouriteItem]);
            }
        },
    ];
    return (_jsxs(Box, { pt: 1.5, px: 2, children: [_jsx(Title, { title: t('favouriteItems') }), _jsx(StyledBox, { onMouseDown: onBoxMouseDown, children: filteredItemsByInput.map((item) => {
                    const { value, id } = item;
                    return (_jsx(Tag, { onClick: onTagClick.bind(null, item), children: _jsx(OptionLabel, { label: value }) }, id));
                }) })] }));
};
const StyledBox = styled(Box)(({ theme: { spacing } }) => {
    return {
        display: 'flex',
        gap: spacing(0.5),
        marginTop: spacing(1),
        flexWrap: 'wrap',
    };
});
export default FavouriteItems;
