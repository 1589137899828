export const LOCATION_AI_WIZARD = 'AI Wizard';
export const LOCATION_JOB_AD = 'Job ad';
export const NAME_JOB_TITLE = 'Job title';
export const NAME_START_DATE = 'Start date';
export const NAME_DRIVING_LICENCE = 'Driving licence';
export const NAME_YEARS_OF_EXPERIENCE = 'Years of experience';
export const NAME_EXPERIENCE_IN_POSITION = 'Experience in the position';
export const NAME_DESCRIPTION = 'Description';
export const NAME_BENEFITS = 'Benefits';
export const NAME_SALARY_ADDITIONAL_INFO = 'Salary additional info';
export const NAME_HEADER = 'Header';
export const NAME_REQUIREMENTS = 'Requirements';
export const NAME_FETCH_FROM_PAYLAB = 'Fetch from Paylab';
export const NAME_LANGUAGE_SKILLS = 'Language skills';
export const NAME_NUMBER_OF_OPENINGS = 'Number of job openings';
export const NAME_APPLICABLE_TILL = 'Applicable Till';
export const NAME_REFERENCE_NUMBER = 'Reference number';
export const ACTION_CLOSE = 'Close';
export const ACTION_ORIGINAL_SUGGESTION = 'Original suggestion';
export const ACTION_SUGGESTION = 'Suggestion';
export const ACTION_RECOMMENDED_TAG = 'Recommended tag';
export const FEATURE_USE_AI = 'Use AI';
export const FEATURE_REWRITE_AI = 'Rewrite AI';
export const FEATURE_CANCEL_AI = 'Cancel AI';
