import { jsx as _jsx } from "react/jsx-runtime";
import { alpha, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { getColor } from '@profesia/adamui/styles/colors';
import ButtonUniversal from './ButtonUniversal';
const Button = styled(ButtonUniversal)(({ theme: { palette, spacing } }) => ({
    minWidth: 254,
    minHeight: 80,
    padding: spacing(2, 3, 2, 2),
    backgroundColor: getColor(palette.neutralLight1),
    color: getColor(palette.black0),
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: alpha(getColor(palette.primaryNavy), 0.15),
    display: 'flex',
    '&:hover': {
        backgroundColor: getColor(palette.neutralLight2),
    },
    '&:focus': {
        backgroundColor: getColor(palette.neutralLight3),
    },
    '.MuiButton-startIcon': {
        margin: spacing(0, 1.5, 0, 0),
    },
}));
const ButtonChildrenStack = styled(Stack)(({ theme: { spacing, typography } }) => ({
    textAlign: 'left',
    padding: spacing(1, 0, 0.75, 0),
    '.MuiTypography-root': {
        '&:nth-of-type(1)': {
            fontSize: typography.body2.fontSize,
            fontWeight: typography.fontWeightMedium,
            lineHeight: 1.43,
        },
        '&:nth-of-type(2)': {
            fontSize: typography.body1.fontSize,
            fontWeight: typography.fontWeightRegular,
            lineHeight: 1.25,
        },
    },
}));
const ButtonExtended = ({ startIcon, children }) => {
    return (_jsx(Button, { startIcon: startIcon, children: _jsx(ButtonChildrenStack, { children: children }) }));
};
export default ButtonExtended;
