import { jsx as _jsx } from "react/jsx-runtime";
import { AvatarFemaleIcon, AvatarHiddenIcon, AvatarMaleIcon, } from '@profesia/adamui/components/common/icons';
import AvatarContainer from '@profesia/adamui/components/common/Avatar/AvatarContainer';
import { Gender } from '@profesia/adamui/components/common/types';
const genderIcons = {
    [Gender.Male]: {
        width: {
            small: 16,
            standard: 26,
        },
        Icon: (props) => _jsx(AvatarMaleIcon, { ...props }),
    },
    [Gender.Female]: {
        width: {
            small: 16,
            standard: 26,
        },
        Icon: (props) => _jsx(AvatarFemaleIcon, { ...props }),
    },
    [Gender.Hidden]: {
        width: {
            small: 14,
            standard: 20.73,
        },
        Icon: (props) => _jsx(AvatarHiddenIcon, { ...props }),
    },
};
const AvatarGender = ({ gender, size = 'standard', ...otherProps }) => {
    const { Icon, width } = genderIcons[gender];
    return (_jsx(AvatarContainer, { size: size, ...otherProps, children: _jsx(Icon, { width: width[size] }) }));
};
export default AvatarGender;
