import { GetContactPersonsResponse } from '@profesia/mark-offer-form/services';
import { ContactPersonsApiResponse } from './types';

export const mapContactPersons = (data: ContactPersonsApiResponse): GetContactPersonsResponse => {
    let contactPersons: GetContactPersonsResponse = [];

    contactPersons = data.users.map((user) => ({
        academicDegree: user.titleName,
        firstName: user.contactFirstname,
        lastName: user.contactSurname,
        phone: user.phone,
        email: user.email,
        id: user.userId,
    }));

    return contactPersons;
};
