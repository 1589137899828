import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import { useGetFixedT } from '@profesia/mark-offer-form/i18n';
import { JOB_AD_SETTINGS_SECTION } from '@profesia/mark-offer-form/codebooks/dataTestId';
import BoxedSectionWrapper from '@profesia/mark-offer-form/components/content/form/components/BoxedSectionWrapper';
import ApplicationWithoutCv from './ApplicationWithoutCv';
import CvFromCvdb from './CvFromCvdb';
import ReferenceNumber from './ReferenceNumber';
const JobSettings = () => {
    const t = useGetFixedT('jobSettings');
    return (_jsxs(BoxedSectionWrapper, { title: t('title'), testId: JOB_AD_SETTINGS_SECTION, children: [_jsx(Box, { "data-testid": "application-without-cv-wrapper", children: _jsx(ApplicationWithoutCv, {}) }), _jsx(Box, { mt: 1, "data-testid": "cv-from-cvdb-wrapper", children: _jsx(CvFromCvdb, {}) }), _jsx(Box, { mt: 1, "data-testid": "reference-number-wrapper", children: _jsx(ReferenceNumber, {}) })] }));
};
export default JobSettings;
