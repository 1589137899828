export var AutocompleteErrors;
(function (AutocompleteErrors) {
    AutocompleteErrors["AUTOCOMPLETE_SERVICE_NOT_AVAILABLE"] = "Autocomplete service is not available";
    AutocompleteErrors["AUTOCOMPLETE_PREDICTIONS_ERROR"] = "Error while getting autocomplete predictions";
})(AutocompleteErrors || (AutocompleteErrors = {}));
export const getAutocompletePredictions = async (autocompleteService, placesOptions, inputValue) => {
    if (!autocompleteService) {
        throw new Error(AutocompleteErrors.AUTOCOMPLETE_SERVICE_NOT_AVAILABLE);
    }
    try {
        const response = await autocompleteService.getPlacePredictions({
            ...placesOptions,
            input: inputValue,
        });
        return response.predictions.map((prediction) => ({
            id: prediction.place_id,
            value: prediction.description,
        }));
    }
    catch (error) {
        throw new Error(AutocompleteErrors.AUTOCOMPLETE_PREDICTIONS_ERROR);
    }
};
