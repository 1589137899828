import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { SimpleLink } from '@profesia/adamui/components/common/Link';
import { useMobile } from '@profesia/adamui/utils/responsive';
import { SectionWrapperColorVariant } from '@profesia/adamui/components/common/types';
import { CONTACT_DETAILS_SECTION } from '@profesia/mark-offer-form/codebooks/dataTestId';
import BoxedSectionWrapper from '@profesia/mark-offer-form/components/content/form/components/BoxedSectionWrapper';
import { useGetFixedT } from '@profesia/mark-offer-form/i18n';
import ContactModal from '../Modal';
import ContactCard from '../Card';
const Content = ({ contactPersons }) => {
    const t = useGetFixedT('contactDetails');
    const isMobile = useMobile('sm');
    const editLink = t(isMobile ? 'links.editLinkShort' : 'links.editLinkFull');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOpenModal = () => {
        setIsModalOpen(true);
    };
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    return (_jsxs(_Fragment, { children: [_jsx(BoxedSectionWrapper, { title: t('title'), testId: CONTACT_DETAILS_SECTION, endComponent: _jsx(SimpleLink, { variantType: "primary", variant: "body2", onClick: handleOpenModal, children: editLink }), variant: SectionWrapperColorVariant.Dark, children: _jsx(ContactCard, { contactPersons: contactPersons }) }), _jsx(ContactModal, { onClose: handleCloseModal, isOpen: isModalOpen })] }));
};
export default Content;
