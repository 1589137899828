import NullTranslation from './i18n/trans/NullTranslation';
const configuration = {
    router: {
        linkComponent: 'a',
        useGetPath: (_routeName) => '/',
    },
    i18n: {
        translationComponent: NullTranslation,
        useTranslation: () => ({
            t: (key, _options) => key,
        }),
        useGetFixedT: (_keyPrefix) => {
            return (key, _options) => key;
        },
    },
};
export const configure = (options) => {
    if (options.router) {
        configuration.router = {
            ...configuration.router,
            ...options.router,
        };
    }
    if (options.i18n) {
        configuration.i18n = {
            ...configuration.i18n,
            ...options.i18n,
        };
    }
    return configuration;
};
export const getConfiguration = () => configuration;
export const useGetPath = (routeName, options) => {
    return configuration.router.useGetPath(routeName, options);
};
export const useTranslation = () => {
    return configuration.i18n.useTranslation();
};
export const useGetFixedT = (keyPrefix) => {
    return configuration.i18n.useGetFixedT(keyPrefix);
};
