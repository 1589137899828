import { jsx as _jsx } from "react/jsx-runtime";
import LazyLoad from 'react-lazy-load';
import { Box, styled } from '@mui/material';
import Option from '@profesia/adamui/components/common/input/textField/ComboTagger/Option';
import { getColor } from '@profesia/adamui/styles/colors';
import Group from './Group';
import OptionListPaper from './OptionListPaper';
const getOptionsProps = (groups) => ({
    optionsProps: {
        PaperComponent: OptionListPaper,
        groupBy: ({ categoryId }) => categoryId,
        renderOption: (props, option) => (_jsx(LazyLoad, { height: 33, children: _jsx(BoxStyled, { children: _jsx(Option, { option: option, liProps: props }) }, option.value) })),
        renderGroup: ({ group, children, key }) => (_jsx(Group, { title: groups[group]?.name.toUpperCase(), children: children }, group)),
        getOptionLabel: (option) => option?.value ?? `${option}`,
    },
});
const BoxStyled = styled(Box)(({ theme: { palette } }) => ({
    '.MuiTypography-root': {
        color: getColor(palette.primaryNavy),
    },
}));
export default getOptionsProps;
