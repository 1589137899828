import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, styled } from '@mui/material';
import { REMOVE_BOOLEAN_FIELD_INPUT_BUTTON, RemoveInputButton, } from '@profesia/adamui/components/common/input';
const InputWrapper = ({ onRemove, canBeRemoved, children }) => (_jsxs(InputWrapperContainer, { children: [children, canBeRemoved ? (_jsx(RemoveInputButton, { "data-testid": REMOVE_BOOLEAN_FIELD_INPUT_BUTTON, onClick: onRemove })) : null] }));
const InputWrapperContainer = styled(Box)(({ theme: { spacing } }) => ({
    display: 'flex',
    gap: spacing(0.5),
    '.MuiButton-bookmark': {
        padding: 0,
        minWidth: 32,
    },
}));
export default InputWrapper;
