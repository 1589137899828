import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { TagField } from '@profesia/adamui/components/common/input';
import { useCodebooksContext } from '@profesia/mark-offer-form/services/CodebooksContext';
import { FORM_JOB_POSITIONS } from '@profesia/mark-offer-form/components/content/form/valuePaths';
import { useGetFixedT, useTranslationInPageLanguage } from '@profesia/mark-offer-form/i18n';
import { getError } from '@profesia/mark-offer-form/components/content/form';
import useTagsLimitLabel from '@profesia/mark-offer-form/components/content/common/useTagsLimitLabel';
import getOptionsProps from './getOptionsProps';
const jobPositionsLimit = 5;
const JobPositions = ({ setIsHintActive }) => {
    const { data } = useCodebooksContext();
    const positionTPrefix = 'positionDescription.jobPositions';
    const t = useGetFixedT(positionTPrefix);
    const pageT = useTranslationInPageLanguage(positionTPrefix);
    const { setValue, control, formState: { errors }, } = useFormContext();
    const values = useWatch({ name: FORM_JOB_POSITIONS, control });
    const bottomLabel = useTagsLimitLabel(jobPositionsLimit, values.length, pageT);
    const optionsProps = useMemo(() => getOptionsProps(data?.jobPositions.groups ?? {}), [data]);
    const error = getError(errors.jobPositions);
    return (_jsx(TagField, { onMouseOver: () => setIsHintActive(true), onMouseLeave: () => setIsHintActive(false), filterSelectedOptions: true, shouldCloseOnLimitReached: true, options: data?.jobPositions.positions ?? [], groupProps: { groups: data?.jobPositions.groups ?? {} }, label: t('label'), placeholder: { empty: t('placeholder'), filled: '' }, limit: jobPositionsLimit, inputFieldLabelProps: {
            required: true,
            hasError: Boolean(error),
        }, size: "large", value: values, onChange: (newValues) => {
            if (!error || newValues.length > 0) {
                setValue(FORM_JOB_POSITIONS, newValues);
            }
        }, displayUnwrapped: true, errorMessage: error, bottomLabel: bottomLabel, ...optionsProps }));
};
export default JobPositions;
