import { jsx as _jsx } from "react/jsx-runtime";
import { alpha, styled } from '@mui/material';
import { getColor } from '@profesia/adamui/styles/colors';
import ButtonUniversal from '../ButtonUniversal';
const Button = styled(ButtonUniversal, {
    shouldForwardProp: (prop) => prop !== 'highlighted',
})(({ highlighted, theme: { palette: { white, black2, neutralDark2, neutralLight3 }, }, }) => {
    return {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        minWidth: 'auto',
        '&.MuiButton-navigation': {
            height: 40,
            width: 40,
            color: getColor(white),
            '&:hover': {
                backgroundColor: alpha(getColor(black2), 0.5),
            },
        },
        '&.MuiButton-bookmark': {
            height: 32,
            width: 32,
            color: getColor(neutralDark2),
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: highlighted ? getColor(neutralLight3) : 'transparent',
            '&:hover': {
                borderColor: getColor(neutralLight3),
                backgroundColor: getColor(white),
            },
            '&:focus': {
                backgroundColor: getColor(neutralLight3),
            },
        },
    };
});
const ButtonCircle = ({ variant = 'navigation', highlighted, icon, ...otherProps }) => {
    return (_jsx(Button, { variant: variant, highlighted: highlighted, ...otherProps, children: icon }));
};
export default ButtonCircle;
