import { jsx as _jsx } from "react/jsx-runtime";
import { Paper as MuiPaper, styled } from '@mui/material';
import { LoadingCircle } from '@profesia/adamui/components/common/loadings';
import useTagFieldContext from '@profesia/adamui/components/common/input/textField/tagField/hooks/useTagFieldContext';
const StyledPaper = styled(MuiPaper, {
    shouldForwardProp: (propName) => propName !== 'isLoading',
})(({ isLoading = false, theme: { spacing } }) => ({
    display: 'flex',
    flexDirection: 'column',
    '.MuiAutocomplete-listbox': {
        padding: spacing(1),
    },
    '.MuiAutocomplete-noOptions': {
        padding: spacing(2),
    },
    minHeight: isLoading ? 64 : undefined,
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.16)',
}));
const Paper = ({ children, ...otherProps }) => {
    const { isLoading } = useTagFieldContext();
    const contentProps = isLoading
        ? {
            children: _jsx(LoadingCircle, {}),
            isLoading,
        }
        : {
            children,
        };
    return _jsx(StyledPaper, { ...otherProps, ...contentProps });
};
export default Paper;
