import { jsx as _jsx } from "react/jsx-runtime";
import { IconButton, styled } from '@mui/material';
import { CloseIcon } from '@profesia/adamui/components/common/icons';
const RemoveInputButton = (props) => {
    return (_jsx(IconButtonStyled, { ...props, children: _jsx(CloseIcon, {}) }));
};
const IconButtonStyled = styled(IconButton)(() => ({
    width: 24,
    height: 24,
    margin: 'auto',
}));
export default RemoveInputButton;
