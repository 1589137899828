import { jsx as _jsx } from "react/jsx-runtime";
import { useFormContext, useWatch } from 'react-hook-form';
import { RadioButtons } from '@profesia/adamui/components/common/input';
import { useGetFixedT } from '@profesia/mark-offer-form/i18n';
import { FORM_CV_FROM_CVDB } from '@profesia/mark-offer-form/components/content/form/valuePaths';
export var CvFromCvdbOption;
(function (CvFromCvdbOption) {
    CvFromCvdbOption[CvFromCvdbOption["SendAllAgents"] = 0] = "SendAllAgents";
    CvFromCvdbOption[CvFromCvdbOption["StoreProfile"] = 2] = "StoreProfile";
})(CvFromCvdbOption || (CvFromCvdbOption = {}));
const CvFromCvdb = () => {
    const { setValue, control } = useFormContext();
    const value = useWatch({ name: FORM_CV_FROM_CVDB, control });
    const t = useGetFixedT('jobSettings.cvFromCvdb');
    const options = [
        {
            id: CvFromCvdbOption.SendAllAgents.toString(),
            label: t('value.sendAllAgents'),
        },
        {
            id: CvFromCvdbOption.StoreProfile.toString(),
            label: t('value.storeProfile'),
        },
    ];
    return (_jsx(RadioButtons, { label: t('label'), options: options, value: value, onChange: (_, requiredOptionId) => {
            setValue(FORM_CV_FROM_CVDB, Number(requiredOptionId));
        } }));
};
export default CvFromCvdb;
