import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import { useGetFixedT } from '@profesia/mark-offer-form/i18n';
import { SELECTION_PROCESS_INFO_SECTION } from '@profesia/mark-offer-form/codebooks/dataTestId';
import BoxedSectionWrapper from '@profesia/mark-offer-form/components/content/form/components/BoxedSectionWrapper';
import NoteForCandidate from './NoteForCandidate';
import NumberOfOpenings from './NumberOfOpenings';
import ApplicableTill from './ApplicableTill';
const SelectionProcessInfo = () => {
    const t = useGetFixedT('selectionProcessInfo');
    return (_jsx(_Fragment, { children: _jsxs(BoxedSectionWrapper, { title: t('title'), testId: SELECTION_PROCESS_INFO_SECTION, children: [_jsx(NoteForCandidate, {}), _jsx(Box, { mt: 2, "data-testid": "number-of-openings-wrapper", children: _jsx(NumberOfOpenings, {}) }), _jsx(Box, { mt: 1, "data-testid": "applicable-till-wrapper", children: _jsx(ApplicableTill, {}) })] }) }));
};
export default SelectionProcessInfo;
