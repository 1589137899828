import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import createSvgIcon from '@profesia/adamui/components/common/icons/createSvgIcon';
import { createMappedIcons } from '@profesia/adamui/utils/icons';
const smallPath = (_jsxs(_Fragment, { children: [_jsx("path", { d: "M15.565 15.22C14.1183 14.9255 12.6443 14.7826 11.1673 14.7936C8.75254 14.7281 6.35048 15.16 4.11389 16.0616C3.78145 16.2081 3.49922 16.4468 3.30148 16.7487C3.10374 17.0506 2.99901 17.4027 3.00001 17.7623V21.0005H11.7955", fill: "white" }), _jsx("path", { d: "M15.565 15.22C14.1183 14.9255 12.6443 14.7826 11.1673 14.7936C8.75254 14.7281 6.35048 15.16 4.11389 16.0616C3.78145 16.2081 3.49922 16.4468 3.30148 16.7487C3.10374 17.0506 2.99901 17.4027 3.00001 17.7623V21.0005H11.7955", stroke: "#2774F0", strokeWidth: "2", strokeMiterlimit: "10", strokeLinecap: "square" }), _jsx("path", { d: "M7 6.66667C7 5.42899 7.47411 4.242 8.31802 3.36683C9.16193 2.49167 10.3065 2 11.5 2C12.6935 2 13.8381 2.49167 14.682 3.36683C15.5259 4.242 16 5.42899 16 6.66667C16 9.244 13.9853 12 11.5 12C9.01471 12 7 9.244 7 6.66667Z", fill: "white", stroke: "#2774F0", strokeWidth: "2", strokeMiterlimit: "10", strokeLinecap: "square" }), _jsx("path", { d: "M15 19.2857L16.8 21L21 17", stroke: "#2774F0", strokeWidth: "2", strokeMiterlimit: "10", strokeLinecap: "square" })] }));
const mappedIconTypes = createMappedIcons(smallPath, smallPath, _jsxs(_Fragment, { children: [_jsx("path", { d: "M20.7533 20.4911C18.8244 20.0937 16.859 19.9008 14.8897 19.9156C11.6701 19.8273 8.46731 20.4101 5.48519 21.627C5.04194 21.8246 4.66562 22.1467 4.40197 22.5542C4.13832 22.9616 3.99867 23.4369 4.00001 23.9222V28.2923H15.7273", fill: "white" }), _jsx("path", { d: "M20.7533 20.4911C18.8244 20.0937 16.859 19.9008 14.8897 19.9156C11.6701 19.8273 8.46731 20.4101 5.48519 21.627C5.04194 21.8246 4.66562 22.1467 4.40197 22.5542C4.13832 22.9616 3.99867 23.4369 4.00001 23.9222V28.2923H15.7273", stroke: "#2774F0", strokeWidth: "2", strokeMiterlimit: "10", strokeLinecap: "square" }), _jsx("path", { d: "M9.02588 9.86366C9.02588 8.30852 9.64366 6.81708 10.7433 5.71743C11.843 4.61778 13.3344 4 14.8895 4C16.4447 4 17.9361 4.61778 19.0358 5.71743C20.1354 6.81708 20.7532 8.30852 20.7532 9.86366C20.7532 13.1021 18.128 16.565 14.8895 16.565C11.6511 16.565 9.02588 13.1021 9.02588 9.86366Z", fill: "white", stroke: "#2774F0", strokeWidth: "2", strokeMiterlimit: "10", strokeLinecap: "square" }), _jsx("path", { d: "M19.6233 25.7793L22.1363 28.2923L28 22.4286", stroke: "#2774F0", strokeWidth: "2", strokeMiterlimit: "10", strokeLinecap: "square" })] }));
// @TODO fixnut tuto ikonu
const UserWithCheckmark = ({ type = 'large', ...svgProps }) => {
    const { path, size } = mappedIconTypes[type];
    const UserIcon = createSvgIcon(path, 'UserWithCheckmark', {
        width: size,
        viewBox: `0 0 ${size} ${size}`,
        fill: 'none',
    });
    return _jsx(UserIcon, { ...svgProps });
};
export default UserWithCheckmark;
