import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, styled } from '@mui/material';
import { CandidateVariant } from '@profesia/adamui/components/common/card/types';
import { Typography, TypographySpan } from '@profesia/adamui/components/common/typography';
import { useMobile, useFlexDirectionByBreakpoint } from '@profesia/adamui/utils/responsive';
const SoughtJobs = ({ positions, variant, title }) => {
    if (!positions?.length) {
        return null;
    }
    const isAvailable = variant === CandidateVariant.availableCandidate;
    const direction = useFlexDirectionByBreakpoint();
    const isMobile = useMobile();
    return (_jsxs(Stack, { spacing: isMobile ? 0 : 0.5, direction: direction, children: [title ? (_jsx(TypographySpan, { color: isAvailable ? 'neutralDark2' : 'neutralLight5', weight: "medium", flexShrink: 0, children: title })) : null, _jsx(StyledTypography, { color: isAvailable ? 'primaryNavy' : 'neutralLight5', isMobile: isMobile, children: positions.join(', ') })] }));
};
const StyledTypography = styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'isMobile',
})(({ isMobile }) => isMobile
    ? {}
    : {
        display: '-webkit-box',
        '-webkit-line-clamp': '1',
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
    });
export default SoughtJobs;
