import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Box, styled } from '@mui/material';
import Tag from '@profesia/adamui/components/common/Tag';
import { getColor } from '@profesia/adamui/styles/colors';
const WysiwygTags = ({ tags, onTagClick }) => {
    const [tagFields, setTagFields] = useState(tags);
    useEffect(() => {
        setTagFields(tags);
    }, [tags]);
    const handleClick = (text) => {
        onTagClick(text);
        setTagFields(tagFields.filter((tag) => tag !== text));
    };
    return (_jsx(BoxTagsStyled, { children: tagFields.map((tag) => (_jsxs(Tag, { onMouseDown: (e) => {
                //Button 0 is the left mouse button
                if (e.button === 0) {
                    handleClick(tag);
                }
            }, children: ["+ ", tag] }, tag))) }));
};
const BoxTagsStyled = styled(Box)(({ theme: { spacing, palette } }) => ({
    paddingTop: spacing(1.25),
    '.Tag-small: not(:last-child)': {
        marginRight: '4px',
        color: getColor(palette.primaryNavy),
    },
}));
export default WysiwygTags;
