import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext } from 'react';
const OptionsContext = createContext({
    isEditMode: false,
    isExtendedFormVisible: true,
    language: 'sk',
});
const OptionsProvider = ({ children, isEditMode, isExtendedFormVisible, language, }) => (_jsx(OptionsContext.Provider, { value: { isEditMode, isExtendedFormVisible, language }, children: children }));
export const useOptionsContext = () => useContext(OptionsContext);
export default OptionsProvider;
