import { GetBusinessAreasResponse } from '@profesia/mark-offer-form/services';
import { Languages } from '@profesia/mark-offer-form/i18n';
import apiRequest from './apiRequest';

const getBusinessAreas = async (locale: Languages): Promise<GetBusinessAreasResponse> => {
    return await apiRequest<GetBusinessAreasResponse>(`codebook/business-area?locale=${locale}`, {
        method: 'GET',
    });
};

export default getBusinessAreas;
