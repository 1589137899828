import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, styled } from '@mui/material';
import { Typography } from '@profesia/adamui/components/common/typography';
import { ArrowDownIcon } from '@profesia/adamui/components/common/icons';
import { getColor } from '@profesia/adamui/styles/colors';
const CollapsibleIcon = styled(ArrowDownIcon)(({ orientation }) => ({
    transition: 'all 0.3s ease-out',
    transform: orientation === 'up' ? `rotate(180deg)` : 'rotate(0deg)',
}));
const ReactionsContainer = styled(Stack)(({ onClick, theme }) => {
    const color = getColor(theme.palette.secondaryGreen);
    return {
        textAlign: 'center',
        cursor: onClick ? 'pointer' : 'default',
        '& .MuiSvgIcon-root': {
            color,
            margin: theme.spacing(0.375, 'auto', 'auto'),
        },
        '& .MuiTypography-root': {
            color,
            '&.MuiTypography-h6': {
                borderBottom: '1px dashed',
                borderColor: color,
            },
        },
    };
});
const Reactions = ({ number, label, arrowOrientation, onClick }) => {
    return (_jsxs(ReactionsContainer, { onClick: onClick, children: [_jsx(Typography, { variant: "h2", children: number }), _jsx(Typography, { variant: "h6", children: label }), arrowOrientation ? _jsx(CollapsibleIcon, { orientation: arrowOrientation }) : null] }));
};
export default Reactions;
