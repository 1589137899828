import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Stack, styled } from '@mui/material';
import { TypographySpan, Typography } from '@profesia/adamui/components/common/typography';
import { useGetFixedT } from '@profesia/mark-offer-form/i18n';
import { MoneyIcon } from '@profesia/mark-offer-form/components/icons';
import useGetLabel from '../useGetLabel';
const Preview = (props) => {
    const labels = useGetLabel(props);
    const t = useGetFixedT('salaryType.preview');
    return (_jsxs(Stack, { mb: 1, children: [_jsx(Typography, { color: "black2", variant: "small", children: t(props.isDefault ? 'defaultLabel' : 'label') }), _jsx(Stack, { direction: "row", gap: 1, mt: 0.5, children: labels.map((label, index) => (_jsxs(React.Fragment, { children: [index === 1 ? (_jsx(TypographySpan, { alignContent: "center", color: "black2", children: t('or') })) : null, _jsxs(SalaryWrapper, { children: [_jsx(MoneyIcon, {}), label] })] }, label))) })] }));
};
const SalaryWrapper = styled(TypographySpan)(({ theme: { spacing } }) => ({
    borderColor: '#1B8121',
    borderWidth: 1,
    borderStyle: 'solid',
    width: 'fit-content',
    padding: spacing(0.5, 1, 0.25),
    borderRadius: 5,
    display: 'flex',
    fontSize: 13,
    gap: spacing(0.875),
    alignItems: 'center',
}));
export default Preview;
