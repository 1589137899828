import { jsx as _jsx } from "react/jsx-runtime";
import { Stack as StackUI, styled } from '@mui/material';
import { Modal as ModalUI } from '@profesia/adamui/components/common/modal';
import { ContainerSize } from '@profesia/mark-offer-form/types';
const StyledModal = ({ content, ...otherProps }) => {
    return _jsx(Modal, { content: _jsx(Stack, { children: content }), ...otherProps });
};
const Modal = styled(ModalUI)(() => ({
    '& > div:nth-of-type(3)': {
        height: '100%',
        top: '0px',
    },
}));
const Stack = styled(StackUI)(() => ({
    width: ContainerSize.modal,
    '#form-footer': {
        position: 'absolute',
        bottom: 0,
        width: ['-webkit-fill-available', '-moz-available'],
    },
    [`@media (max-width: ${ContainerSize.modal})`]: {
        width: '100vw',
    },
}));
export default StyledModal;
