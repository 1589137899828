import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import createSvgIcon from '@profesia/adamui/components/common/icons/createSvgIcon';
const tipPaths = {
    singleColor: (_jsxs(_Fragment, { children: [_jsx("path", { d: "M5.5 4L6.25 5.5L5.5 7L7 6.25L8.5 7L7.75 5.5L8.5 4L7 4.75L5.5 4Z", fill: "#2774F0" }), _jsx("path", { d: "M13 4L13.75 5.5L13 7L14.5 6.25L16 7L15.25 5.5L16 4L14.5 4.75L13 4Z", fill: "#2774F0" }), _jsx("path", { d: "M13 11.5L13.75 13L13 14.5L14.5 13.75L16 14.5L15.25 13L16 11.5L14.5 12.25L13 11.5Z", fill: "#2774F0" }), _jsx("path", { d: "M10.7048 7.70443L8.71973 9.68945L10.3107 11.2804L12.2957 9.29542L10.7048 7.70443Z", fill: "#2774F0" }), _jsx("path", { d: "M8.19058 10.2191L4.00098 14.4087L5.59197 15.9997L9.78157 11.8101L8.19058 10.2191Z", fill: "#2774F0" })] })),
    multiColor: (_jsxs(_Fragment, { children: [_jsx("path", { d: "M5.5 4L6.25 5.5L5.5 7L7 6.25L8.5 7L7.75 5.5L8.5 4L7 4.75L5.5 4Z", fill: "#FFB100" }), _jsx("path", { d: "M13 4L13.75 5.5L13 7L14.5 6.25L16 7L15.25 5.5L16 4L14.5 4.75L13 4Z", fill: "#FFB100" }), _jsx("path", { d: "M13 11.5L13.75 13L13 14.5L14.5 13.75L16 14.5L15.25 13L16 11.5L14.5 12.25L13 11.5Z", fill: "#FFB100" }), _jsx("path", { d: "M10.7048 7.70443L8.71973 9.68945L10.3107 11.2804L12.2957 9.29542L10.7048 7.70443Z", fill: "#7F7F7F" }), _jsx("path", { d: "M8.19058 10.2191L4.00098 14.4087L5.59197 15.9997L9.78157 11.8101L8.19058 10.2191Z", fill: "#555555" })] })),
    multiColorLarge: (_jsxs(_Fragment, { children: [_jsx("path", { d: "M4.59961 2.80005L5.49961 4.60005L4.59961 6.40005L6.39961 5.50005L8.19961 6.40005L7.29961 4.60005L8.19961 2.80005L6.39961 3.70005L4.59961 2.80005Z", fill: "#FFB100" }), _jsx("path", { d: "M13.5996 2.80005L14.4996 4.60005L13.5996 6.40005L15.3996 5.50005L17.1996 6.40005L16.2996 4.60005L17.1996 2.80005L15.3996 3.70005L13.5996 2.80005Z", fill: "#FFB100" }), _jsx("path", { d: "M13.5996 11.8L14.4996 13.6L13.5996 15.4L15.3996 14.5L17.1996 15.4L16.2996 13.6L17.1996 11.8L15.3996 12.7L13.5996 11.8Z", fill: "#FFB100" }), _jsx("path", { d: "M10.8459 7.24565L8.46387 9.62769L10.3731 11.5369L12.7551 9.15484L10.8459 7.24565Z", fill: "#7F7F7F" }), _jsx("path", { d: "M7.82831 10.2632L2.80078 15.2908L4.70997 17.2L9.7375 12.1724L7.82831 10.2632Z", fill: "#555555" })] })),
    disabled: (_jsxs(_Fragment, { children: [_jsx("path", { d: "M5.5 4L6.25 5.5L5.5 7L7 6.25L8.5 7L7.75 5.5L8.5 4L7 4.75L5.5 4Z", fill: "#7F7F7F" }), _jsx("path", { d: "M13 4L13.75 5.5L13 7L14.5 6.25L16 7L15.25 5.5L16 4L14.5 4.75L13 4Z", fill: "#7F7F7F" }), _jsx("path", { d: "M13 11.5L13.75 13L13 14.5L14.5 13.75L16 14.5L15.25 13L16 11.5L14.5 12.25L13 11.5Z", fill: "#7F7F7F" }), _jsx("path", { d: "M10.7048 7.70443L8.71973 9.68945L10.3107 11.2804L12.2957 9.29542L10.7048 7.70443Z", fill: "#7F7F7F" }), _jsx("path", { d: "M8.19058 10.2191L4.00098 14.4087L5.59197 15.9997L9.78157 11.8101L8.19058 10.2191Z", fill: "#7F7F7F" })] })),
};
const Tip = ({ type, ...otherProps }) => {
    const Icon = createSvgIcon(tipPaths[type], 'Tip', {
        width: 20,
        height: 20,
        viewBox: '0 0 20 20',
        fill: 'none',
    });
    return _jsx(Icon, { ...otherProps });
};
export default Tip;
