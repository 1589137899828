export const coordinates = {
    sk: [48.669, 19.699],
    cs: [49.8175, 15.473],
    hu: [47.1625, 19.5033],
};
export const getCountryCoordinates = (countryCode) => {
    const coords = coordinates[countryCode];
    if (!coords) {
        return null;
    }
    return { lat: coords[0], lng: coords[1] };
};
