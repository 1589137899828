import { jsx as _jsx } from "react/jsx-runtime";
import { useFormContext, useWatch } from 'react-hook-form';
import { SelectDefault } from '@profesia/adamui/components/common/dropdown';
import { useGetFixedT } from '@profesia/mark-offer-form/i18n';
import { FORM_EDUCATION_LEVEL } from '@profesia/mark-offer-form/components/content/form/valuePaths';
import { useGetEducationOptions } from '@profesia/mark-offer-form/codebooks';
const EducationLevel = ({ errorMessage }) => {
    const t = useGetFixedT('skillsAndRequirements.education');
    const educations = useGetEducationOptions();
    const { setValue, control } = useFormContext();
    const value = useWatch({ name: FORM_EDUCATION_LEVEL, control });
    return (_jsx(SelectDefault, { label: t('label'), placeholder: t('placeholder'), size: "large", options: educations, value: value ?? '', onChange: (value) => setValue(FORM_EDUCATION_LEVEL, value), hasError: Boolean(errorMessage), required: true, errorMessage: errorMessage }));
};
export default EducationLevel;
