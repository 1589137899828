import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, InputLabel as MuiInputLabel, Stack, styled } from '@mui/material';
import { getColor } from '@profesia/adamui/styles/colors';
import { useGetFixedT } from '@profesia/mark-offer-form/i18n';
import { POSITION_LOCATION_SECTION } from '@profesia/mark-offer-form/codebooks/dataTestId';
import BoxedSectionWrapper from '@profesia/mark-offer-form/components/content/form/components/BoxedSectionWrapper';
import Options from './Options';
import useHandleError from './useHandleError';
import StartDate from './StartDate';
const PositionPlace = () => {
    const t = useGetFixedT('positionPlace');
    const errorClass = useHandleError();
    return (_jsx(BoxedSectionWrapper, { className: errorClass, testId: POSITION_LOCATION_SECTION, children: _jsxs(Stack, { children: [_jsx(Box, { mb: 0.5, children: _jsx(InputLabel, { required: true, children: t('label') }) }), _jsx(Options, {}), _jsx(Box, { width: 300, pt: 2, children: _jsx(StartDate, {}) })] }) }));
};
const InputLabel = styled(MuiInputLabel)(({ theme }) => ({
    '& .MuiFormLabel-asterisk': {
        color: getColor(theme.palette.secondaryRed),
    },
}));
export default PositionPlace;
