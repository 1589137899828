import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, styled } from '@mui/material';
import Label from '@profesia/adamui/components/common/Label';
import ExtendedLabel from '@profesia/adamui/components/common/input/InputLabel/ExtendedLabel';
//@TODO možno to prerobiť do Label komponenty a zmeniť štrukturu na /common/Label
const InputLabel = ({ extendedProps, optionalLabel, children, label, labelPlacementLeft, required, hasError, }) => {
    return (_jsx(_Fragment, { children: label ? (_jsxs(InputFieldWrapper, { labelPlacementLeft: labelPlacementLeft, children: [extendedProps?.extendedButtonLabel ? (_jsx(ExtendedLabel, { label: label, extendedProps: extendedProps, labelPlacementLeft: labelPlacementLeft })) : (_jsx(Box, { ...(labelPlacementLeft ? { mb: 0, mr: 3.625 } : { mb: 0.5, mr: 0 }), children: _jsx(Label, { error: hasError, optionalLabel: optionalLabel, required: required, children: label }) })), children] })) : (children) }));
};
const InputFieldWrapper = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'labelPlacementLeft',
})(({ labelPlacementLeft }) => ({
    display: 'flex',
    alignItems: labelPlacementLeft ? 'center' : 'space-between',
    flexDirection: labelPlacementLeft ? 'row' : 'column',
}));
export default InputLabel;
