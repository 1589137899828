import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useEffect } from 'react';
import { useGetCodebooks } from '@profesia/mark-offer-form/services';
import useToastWithTranslation from '@profesia/mark-offer-form/services/useToastWithTranslation';
const CodebooksContext = createContext({
    data: undefined,
    isLoading: false,
});
const CodebooksProvider = ({ children }) => {
    const { data, isLoading, isError } = useGetCodebooks();
    const addToast = useToastWithTranslation();
    useEffect(() => {
        if (isError) {
            addToast({ message: 'common.responseError.codebooks', variant: 'error' });
        }
    }, [isError]);
    return (_jsx(CodebooksContext.Provider, { value: { data, isLoading }, children: children }));
};
export const useCodebooksContext = () => useContext(CodebooksContext);
export default CodebooksProvider;
